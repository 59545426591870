@use "sass:math";

@mixin smartPhoneSideMargin() {
  $sm-side-margin: 2;
  padding-left: margin(math.div($sm-side-margin, 2));
  padding-right: margin(math.div($sm-side-margin, 2));
}

.ss-container-style-fixed-width {
  @media (min-width: $screen-tb) {
    display: block;
    max-width: $max-fixed-width !important;
    float: unset !important;
    margin: 0 auto;
    clear: both !important;
  }
}

.ss-container-style-fixed-s-width {
  @media (min-width: $screen-tb) {
    display: block;
    max-width: $max-fixed-s-width !important;
    float: unset !important;
    margin: 0 auto;
    clear: both !important;
  }
}

.ss-container-style-side-margin {
  @include sm {
    @include smartPhoneSideMargin();
  }
}

// $col-gap: margin(4);
// $col-margin: margin(2.5);
@mixin containerStyle(
  $width,
  $height,
  $width-auto: true,
  $col-gap: margin(4),
  $col-margin: margin(2.5)
) {
  .cmp-container {
    .aem-Grid {
      display: flex;
      flex-wrap: nowrap;
      @include not_sm {
        flex-direction: row;
        justify-content: center;
        gap: $col-gap;
        margin: $col-margin auto;
        .aem-GridColumn {
          display: inline-flex;
          align-items: center;
          flex-basis: $width;
          @if $height != none {
            height: $height;
          }

          .cmp-image {
            display: inline-flex;
            align-items: center;
            img {
              @if $height != none {
                height: $height;
              }
              object-fit: cover;
            }
          }
        }
      }
      @include sm {
        flex-direction: column;
        align-items: center;
        .aem-GridColumn {
          @if $width-auto == true {
            width: auto;
          }
          .cmp-image {
            object-fit: cover;
          }
          &:not(:last-of-type) {
            margin-bottom: $col-margin;
          }
        }
      }
    }
  }
}

.ss-container-style-2-cols {
  $col-basis: rem(488);
  $col-height: none;
  @include containerStyle($col-basis, $col-height, false);
}

.ss-container-style-3-cols {
  $col-basis: rem(384);
  $col-height: none;
  @include containerStyle($col-basis, $col-height);
}

.ss-container-style-4-cols {
  $col-basis: rem(280);
  $col-height: none;
  @include containerStyle($col-basis, $col-height);
}
