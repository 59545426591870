@use "../global"as *;

.bl_ui_module {

    /*===============================================
    device display
    ===============================================*/
    .lg_only {
        display: block;

        @include mq(lg) {
            display: none;
        }
    }

    .md_only {
        display: none;

        @include mq(md) {
            display: block;
        }
    }

    .sm_only {
        display: none;

        @include mq(md) {
            display: block;
        }
    }

    /*===============================================
    text align
    ===============================================*/
    .hp_tac {
        text-align: center;
    }

    .hp_tal {
        text-align: left;
    }

    .hp_tar {
        text-align: right;
    }

    /*===============================================
    asterisk
    ===============================================*/
    .hp_asterisk {
        position: relative;

        &:after {
            color: red;
            content: "*";
            line-height: 1;
            @include absolute(0, auto, auto, -10px);
        }
    }

    /*===============================================
    太さ
    ===============================================*/
    .hp_bold {
        font: {
            weight: 700;
        }
    }

    /*===============================================
    アンダーライン
    ===============================================*/
    .hp_underline {
        text-decoration: underline;
        text-underline-offset: 3px;
    }

    /*===============================================
    色
    ===============================================*/
    .hp_colorKey {
        color: $color-key;
    }
    .hp_colorBg {
        color: $color-bg;
    }
    .hp_twitterColor {
        color: $color-twitter;
    }
    .hp_facebookColor {
        color: $color-facebook;
    }
    .hp_lineColor {
        color: $color-line;
    }

    /*===============================================
    背景色
    ===============================================*/
    .hp_bgColorGrey {
        background: {
            color: $color-grey;
        }
    }
    .hp_bgColorKey {
        background: {
            color: $color-key;
        }
    }
    .hp_bgColorWht {
        background: {
            color: $color-bg;
        }
    }

    /*===============================================
    iframe
    ===============================================*/
    .hp_iframeWrapper {
        width: 100%;
        position: relative;

        iframe {
            @include absolute(0, 0, 0, 0);
            width: 100%;
            height: 100%;
        }
    }

    .hp_iframeWrapper.ra_16-9 {
        padding-top: $r-16-9;
    }

    .hp_iframeWrapper.ra_square {
        padding-top: $r-square;
    }

    /*===============================================
    flex
    ===============================================*/
    .hp_flex {
        display: flex;
    }

    .hp_flex_vert__center {
        align-items: center;
    }

    .hp_flex_horiz__center {
        justify-content: center;
    }

    .hp_flex__wrap {
        flex-wrap: wrap;
    }

    /*===============================================
    外部リンク
    ===============================================*/
    .hp_external_link {

        &:before {
            content: '';
            background: {
                image: url(./resources/images/element/icon/external-link.svg);
                size: cover;
            }
            @include absolute(0, 0, auto, auto);
            width: 14px;
            height: 14px;
            display: inline-block;
        }

        &__wh {

            &:before {
                background: {
                    image: url(./resources/images/element/icon/external-link-wh.svg);
                }
            }
        }
    }
}