@use "../../global"as *;


//placeholderの色
$placeholderColor: #75777D;

.bl_ui_module {

    /*===============================================
    link contact
    ===============================================*/
    .el_link_contact {

        &_icon {
            width: 20px;
            height: 16px;
            display: inline-block;
            vertical-align: middle;
            flex-shrink: 0;

            img {
                display: block;
            }
        }

        &_txt {
            margin-left: 8px;

            @include mq(md){
                font: {
                    size: 13px;
                }
            }
        }
    }

    /*===============================================
    search
    ===============================================*/
    .el_search {
        border: 1px solid #DEDFE0;
        border-radius: $spacing-3;
        position: relative;
        width: 140px;
        height: $spacing-3;
        line-height: 30px;
        padding: 0 $spacing-1;
        overflow: hidden;

        &_input {
            width: 100%;
            @include placeholder($placeholderColor);
        }

        &_btn {
            @include absolute(calc(50% - 2px), $spacing-mini, auto, auto);
            transform: translate(0, -50%);
            width: 14.603px;
        }
    }

    /*===============================================
    lang switch
    ===============================================*/
    .el_lang_switch {
        color: #75777D;
        font: {
            size: 14px;
        }
        position: relative;
        padding-left: 10px;
        margin-left: $spacing-mini;

        @include mq(md){
            font: {
                size: 13px;
            }
            padding-left: 8px;
            margin-left: 8px;
        }

        @include hover {

            .el_lang_switch_dropdown {
                top: $spacing-4half;
                opacity: 1;
                visibility: visible;
            }
        }

        &:before {
            content: '';
            background: {
                color: rgba(#272A33, .3);
            }
            @include absolute(50%, auto, auto, 0);
            transform: translate(0, -50%);
            width: 1px;
            height: $spacing-1;
        }

        &:first-child {
            color: $color-base;
            padding: 0;
            margin: 0;

            &:before {
                content: none;
            }
        }

        &Wrapper {

            &.md_only {
                display: none;

                @include mq(lg){
                    @include absolute(50%, 76px, auto, auto);
                    transform: translate(0, -50%);
                    display: flex;
                }
            }
        }

        &_icon {

            &__globe {
                width: 21px;
                margin-right: 8px;

                @include mq(lg){
                    margin-right: 6px;
                }
            }

            &__chevron {
                width: 12.482px;
                margin-left: 6px;

                @include mq(lg){
                    width: 10.699px;
                    margin-left: 4px;
                }
            }
        }

        &_dropdown {
            position: absolute;
            top: $spacing-6;
            left: 50%;
            min-width: 168px;
            line-height: 1.4;
            letter-spacing: 0;
            padding: $spacing-mini $spacing-mini $spacing-1;
            z-index: 1000;
            opacity: 0;
            visibility: hidden;
            border-radius: 12px;
            box-shadow: 3px 4px 312px rgba(#000, .16);
            box-sizing: border-box;
            transform: translate(-50%, 0);
            @include transition;

            &:before {
                content: '';
                @include absolute(-14px, auto, auto, 50%);
                transform: translate(-50%, 0);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 8px 14px 8px;
                border-color: transparent transparent $color-bg transparent;
            }

            &_ttl {
                font: {
                    size: 14px;
                }
                padding: 0 $spacing-mini;
            }

            &_list {
                font: {
                    size: 14px;
                }
                margin-bottom: 4px;

                &Wrapper {
                    margin-top: 8px;
                    width: 190px;
                }

                &.is_current {

                    a {
                        color: $color-bg;
                        background: {
                            color: $color-key;
                        }

                        &:before {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    color: #75777D;
                    position: relative;
                    padding: 6px 10px;
                    border-radius: 8px;

                    @include hover {
                        color: $color-bg;
                        background: {
                            color: $color-key;
                        }
                    }

                    &:before {
                        content: '';
                        background: {
                            image: url(./resources/images/element/icon/external-link.svg);
                            size: cover;
                        }
                        @include absolute(50%, $spacing-mini, auto, auto);
                        transform: translate(0, -50%);
                        width: 12.4px;
                        height: 9.398px;
                        display: inline-block;
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }

            &_icon {
                width: 18px;
            }

            &_region {
                margin-left: 8px;
                flex: 1 0 0;
            }
        }
    }
}