@use "../global"as *;

/*===============================================
scrollBar
===============================================*/
@keyframes scrollBar {
    0% {
        transform: translate(-50%, -280px);
    }
    50%, 100% {
        transform: translate(-50%, 24px);
    }
  }