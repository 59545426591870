@use "sass:math";

@mixin textIconStyle($iconSize, $iconMargin) {
  $icon_content: $iconSize;
  width: $icon_content;
  height: $icon_content;
  @if $iconMargin != null {
      margin-left: rem($iconMargin);
  }
  border-radius: math.div($icon_content, 2);
}
@mixin paragraphXLargeText(){
  @include sm {
    font: normal normal bold 28px/38px Fieldwork;
  }
  @include tb {
    font: normal normal bold 36px/48px Fieldwork;
  }
  @include lg {
    font: normal normal bold 36px/48px Fieldwork;
  }
  @include xl {
    font: normal normal bold 36px/48px Fieldwork;
  }
}
@mixin paragraphLargeText(){
  @include sm {
    font: normal normal bold 20px/32px Fieldwork;
  }
  @include tb {
    font: normal normal bold 24px/36px Fieldwork;
  }
  @include lg {
    font: normal normal bold 24px/36px Fieldwork;
  }
  @include xl {
    font: normal normal bold 24px/36px Fieldwork;
  }
}
@mixin paragraphNormalText(){
  @include sm {
    font: normal normal 500 13px/24px Fieldwork;
  }
  @include tb {
    font: normal normal 500 13px/24px Fieldwork;
  }
  @include lg {
    font: normal normal 500 14px/24px Fieldwork;
  }
  @include xl {
    font: normal normal 500 14px/24px Fieldwork;
  }
}
@mixin paragraphListText(){
  @include sm {
    font: normal normal 600 14px/14px Fieldwork;
  }
  @include tb {
    font: normal normal 600 14px/14px Fieldwork;
  }
  @include lg {
    font: normal normal 600 15px/15px Fieldwork;
  }
  @include xl {
    font: normal normal 600 15px/15px Fieldwork;
  }
}

@mixin paragraphAnchorLinkOfBrand(){
  @include sm {
    font: normal normal 600 12px/12px Fieldwork;
  }
  @include tb {
    font: normal normal 600 12px/12px Fieldwork;
  }
  @include lg {
    font: normal normal 600 15px/15px Fieldwork;
  }
  @include xl {
    font: normal normal 600 15px/15px Fieldwork;
  }
}
@mixin paragraphAnchorLinkOfProduct(){
  @include sm {
    font: normal normal 600 16px/16px Fieldwork;
  }
  @include tb {
    font: normal normal 600 16px/16px Fieldwork;
  }
  @include lg {
    font: normal normal 600 18px/18px Fieldwork;
  }
  @include xl {
    font: normal normal 600 18px/18px Fieldwork;
  }
}

@mixin paragraphFaqAndAccordionText(){
  @include sm {
    font: normal normal 600 14px/24px Fieldwork;
  }
  @include tb {
    font: normal normal 600 14px/24px Fieldwork;
  }
  @include lg {
    font: normal normal 600 16px/22px Fieldwork;
  }
  @include xl {
    font: normal normal 600 16px/22px Fieldwork;
  }
}


@mixin selectDefault(){
  @include sm {
    font: normal normal 500 16px/24px Fieldwork;
  }
  @include tb {
    font: normal normal 500 16px/24px Fieldwork;
  }
  @include lg {
    font: normal normal 500 16px/22px Fieldwork;
  }
  @include xl {
    font: normal normal 500 16px/22px Fieldwork;
  }
}

@mixin selectDefaultDisabled(){
  @include sm {
    font: normal normal 600 16px/24px Fieldwork;
  }
  @include tb {
    font: normal normal 600 16px/24px Fieldwork;
  }
  @include lg {
    font: normal normal 600 16px/22px Fieldwork;
  }
  @include xl {
    font: normal normal 600 16px/22px Fieldwork;
  }
}


@mixin dealersNormalText(){
  @include sm {
    font: normal normal 500 13px/24px Fieldwork;
  }
  @include tb {
    font: normal normal 500 13px/24px Fieldwork;
  }
  @include lg {
    font: normal normal 500 16px/24px Fieldwork;
  }
  @include xl {
    font: normal normal 500 16px/24px Fieldwork;
  }
}
