// Product
.ss-teaser--style-product {
  .cmp-teaser {
    position: relative;
    font: $font-teaser;

    width: rem(280);
    height: rem(370);

    @include sm {
      width: rem(324);
      height: rem(363);
    }
  }

  .cmp-teaser__image {
    position: relative;
    top: rem(20);
    width: rem(240);
    height: rem(180);
    margin-bottom: rem(15);
    margin-left: rem(20);
    margin-right: rem(20);

    @include sm {
      top: rem(20);
      width: rem(259);
      height: rem(194);
      margin-bottom: rem(7);
      margin-left: rem(33);
      margin-right: rem(32);
    }

    & .cmp-image {
    }
    & img {
      width: rem(240);
      height: rem(180);

      @include sm {
        width: rem(259);
        height: rem(194);
      }
    }
  }

  .cmp-teaser__content {
    position: absolute;
    top: rem(215);

    @include sm {
      top: rem(221);
    }
  }

  .cmp-teaser__pretitle {
    display: none;
  }

  .cmp-teaser__title {
    position: absolute;
    top: rem(21);
    height: rem(31);
    width: rem(240);
    margin-top: rem(0);
    margin-bottom: rem(23);
    margin-left: rem(20);
    margin-right: rem(20);
    font: $font-teaser-product-title;
    text-align: left;

    @include sm {
      top: rem(18);
      height: rem(27);
      width: rem(259);
      margin-top: rem(0);
      margin-bottom: rem(17);
      margin-left: rem(33);
      margin-right: rem(51);
      font: $font-teaser-sm-product-title;
    }
  }

  .cmp-teaser__title-link {
    pointer-events: none;
    color: $color-teaser-product-title;
  }

  .cmp-teaser__description {
    display: none;
  }

  .cmp-teaser__action-container {
    position: absolute;
    top: rem(75);
    width: rem(240);
    height: rem(48);
    margin-top: rem(0);
    margin-left: rem(20);
    margin-right: rem(20);

    @include sm {
      top: rem(62);
      width: rem(259);
      margin-top: rem(0);
      margin-left: rem(33);
      margin-right: rem(32);
    }
  }

  .cmp-teaser__action-link {
    width: rem(240);
    height: rem(40);
    padding-top: rem(17);
    padding-bottom: rem(17);
    padding-left: rem(86);
    padding-right: rem(84);
    background: transparent
      linear-gradient(
        270deg,
        $color-button-blue 0%,
        $color-button-blue-linear 100%
      )
      0% 0% no-repeat padding-box;
    font: $font-teaser-button-title;
    color: $color-teaser-button-title-white;

    &:hover,
    &:focus {
      padding-top: rem(16);
      padding-bottom: rem(16);
      padding-left: rem(83);
      padding-right: rem(83);

      background: $color-button-white 0% 0% no-repeat padding-box;
      box-shadow: rem(3) rem(4) rem(12) $color-button-box-shadow;
      border: rem(1) solid $color-button-blue;
      color: $color-button-blue;
    }

    @include sm {
      width: rem(259);
      padding-left: rem(96);
      padding-right: rem(93);

      &:hover,
      &:focus {
        padding-top: rem(16);
        padding-bottom: rem(16);
        padding-left: rem(95);
        padding-right: rem(92);
      }
    }

    .cmp-teaser__action-link-after {
      content: url(./resources/images/element/icon/arrow-r-wh.svg);
      position: absolute;
      top: initial;
      left: rem(200);
      width: rem(20);
      height: rem(20);
      text-align: center;

      @include sm {
        left: rem(219);
      }
    }

    &:hover .cmp-teaser__action-link-after,
    &:focus .cmp-teaser__action-link-after {
      content: url(./resources/images/element/icon/arrow-r-blue.svg);
      width: rem(20);
      height: rem(20);

      @include sm {
        left: rem(219);
      }
    }
  }

  .cmp-teaser__tag-container {
    .cmp-common__tag-tpa__brand {
      &--topcon,
      &--sokkia,
      &--clearedge3d,
      &--xyeez {
        position: absolute;
        top: rem(0);
        height: rem(18);
        margin-left: rem(20);
        font: $font-teaser-product-brand-tag;

        @include sm {
          top: rem(0);
          height: rem(16);
          margin-left: rem(33);
          font: $font-teaser-sm-product-brand-tag;
        }
      }
    }
    .cmp-common__tag-tpa__industry {
      &--survey,
      &--construction,
      &--bim,
      &--agriculture {
        display: none;
      }
    }

    .cmp-common__tag-tpa__product-category {
      &--total-stations,
      &--gnss,
      &--machine-control,
      &--field-controllers,
      &--software,
      &--lasers,
      &--3d-scanners,
      &--levels-theodolites,
      &--agriculture-equipments,
      &--raku,
      &--monitoring,
      &--survey-equipments,
      &--related-products,
      &--infrastructure-maintenance {
        display: none;
      }
    }

    .cmp-common__tag-tpa__news {
      &--product-info,
      &--campaign,
      &--information,
      &--atwork,
      &--news-release,
      &--column {
        display: none;
      }
    }

    .cmp-common__tag-tpa__event {
      &--online,
      &--physical,
      &--free,
      &--paid {
        display: none;
      }
    }
  }
}
