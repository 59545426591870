@use "../../global"as *;

/*===============================================
industryList
===============================================*/

.bl_ui_module {

    .el_industry_list {
        font: {
            size: $fontsize-2;
        }
        margin-bottom: $spacing-half;

        @include mq(lg){
            width: calc(50% - $spacing-half);
            margin-bottom: 0;
        }

        @include mq(md){
            font: {
                size: 15px;
            }
            width: 100%;
            margin-bottom: $spacing-half;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &__header {
            font: {
                size: 16px;
            }
            width: calc(50% - 6px);

            &Wrapper {
                padding: 20px;
                gap: $spacing-mini;
            }

            a {
                gap: $spacing-3;

                @include hover {

                    .el_arrow_icon {
                        transform: translateX(6px);
                    }
                }
            }

            &_img {

                &Wrapper {
                    width: 108px;
                    flex-shrink: 0;
                }
            }
        }

        &Wrapper {

            @include mq(lg){
                display: flex;
                flex-wrap: wrap;
                gap: $spacing-half;
            }

            @include mq(md){
                display: block;
            }
        }

        a {
            position: relative;
            padding: 36px $spacing-3;

            @include mq(md){
                padding: $spacing-2;
            }

            @include hover {

                .el_arrow_icon {
                    @include absolute(50%, 12px, auto, auto);
                }
            }
        }

        .el_arrow_icon {
            @include absolute(50%, 20px, auto, auto);
            transform: translate(0, -50%);
        }
    }
}