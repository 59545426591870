.ss-text--style-table-responsive {
  .cmp-text {
    table {
      table-layout: fixed;
      width: 100%;
      border-top: rem(4) solid $color-table-border-top;
      border-collapse: collapse;
    }

    th,
    td {
      text-align: left;
      padding: rem(10);
      border: 1px solid $color-shade-2;
      min-height: 54px;
    }

    // Desktop view - normal table display
    @include not_sm {
      th {
        width: 30%;
        padding-left: rem(15.5);
        padding-right: rem(15.5);
        background-color: $color-table-th;
      }

      td {
        width: 35%;
        padding-left: rem(18);
        padding-right: rem(18);
        background-color: $color-background-lighter;
      }
    }

    // Mobile view - responsive behavior
    @include sm {
      thead th[scope="col"] {
        display: none;
      }

      // Ensure row headers (th[scope="row"]) are visible in mobile
      th[scope="row"] {
        display: flex;
        background-color: $color-table-th;
        color: white;
        font-weight: bold;
        padding: rem(10);
        width: 100%;
        box-sizing: border-box;
        justify-content: center;
      }

      tr {
        display: block;
        border-bottom: rem(2) solid $color-shade-2;
      }

      td {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        padding: 0;
        text-align: left;
        box-sizing: border-box;
        background-color: $color-background-lighter;
      }

      // Make the :before pseudo-element behave like a cell next to the data
      td:before {
        content: attr(data-label);
        flex-shrink: 0;
        background-color: $color-table-th;
        color: white;
        padding: 1rem;
        margin-right: rem(10);
        font-weight: bold;
        text-align: center;
        width: rem(80);
        box-sizing: border-box;
      }

      // First cell (originally the first column) behaves like a header
      td:first-child {
        background-color: $color-table-th;
        color: white;
        font-weight: bold;
        padding: rem(10);
        border: rem(2) solid $color-shade-2;
      }
    }
  }
}

.ss-text--style-table {
  .cmp-text {
    table {
      table-layout: fixed;
      width: 100%;
      border-top: rem(4) solid $color-table-border-top;
    }

    /* Added to match consistent padding with responsive table */
    th,
    td {
      text-align: left;
      padding: rem(10);
      border: 1px solid $color-shade-2;
      min-height: 54px;
    }

    @include not_sm {
      th {
        width: 30%;
        padding-left: rem(15.5);
        padding-right: rem(15.5);
      }

      td {
        width: 35%;
        padding-left: rem(18);
        padding-right: rem(18);
      }
    }

    th {
      @include sm {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
      @include not_sm {
        width: 30%;
        text-align: left;
        vertical-align: middle;
        padding-left: rem(15.5);
        padding-right: rem(15.5);
      }
      min-height: 54px;
      // color: $color-background-lighter;
      background-color: $color-table-th;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    td {
      @include sm {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
      @include not_sm {
        width: 35%;
        text-align: left;
        vertical-align: middle;
        padding-left: rem(18);
        padding-right: rem(18);
      }
      color: $color-text-black !important;
      min-height: 54px;
      border: 1px solid $color-shade-2;
      background-color: $color-background-lighter;
    }
  }
}
