@use "../global"as *;

/*===============================================
footer
===============================================*/

.bl_ui_module {
        
    &.ly_footer {
        background: {
            color: $color-grey;
        }
        width: 100%;
        padding: $spacing-8 0 0;
        letter-spacing: 0;

        @include mq(md){
            padding-top: $spacing-5;
        }

        .ly_inner {

            &.hp_flex {
                justify-content: space-between;

                @include mq(md){
                    display: block;
                }
            }
        }

        .ly_footerInfo {
            justify-content: space-between;

            @include mq(md){
                display: block;
            }
        }

        .ly_footerNav {
            margin-top: $spacing-8;

            @include mq(md){
                margin-top: $spacing-3;
            }
        }

        .ly_footerCopy {
            color: $color-bg;
            background: {
                color: #114B90;
            }
            padding: 24px 0;
            margin-top: $spacing-10;

            @include mq(md){
                padding: $spacing-3 0 20px;
                margin-top: $spacing-6;
            }
        }

        /*===============================================
        footer page top
        ===============================================*/
        .bl_footerPageTop {
            position: fixed;
            bottom: 20px;
            right: 20px;
            z-index: 9998;

            a {

                .el_arrow_icon {
                    margin: 0;
                }

                @include hover{
                    transform: translateY(-12px);
                }
            }
        }

        /*===============================================
        footer logo
        ===============================================*/
        .bl_footerLogo {
            max-width: 224px;

            @include mq(md){
                max-width: 180px;
            }
        }

        /*===============================================
        footer sns
        ===============================================*/
        .bl_footerSns {
            gap: 8px;

            @include mq(md){
                margin-top: 20px;
            }

            li {

                a {
                    background: {
                        color: $color-bg;
                    }
                    position: relative;
                    width: 48px;
                    height: 48px;
                    display: block;
                    border-radius: 50%;

                    @include hover {
                        opacity: .5;
                    }
                }

                img {
                    @include absolute(50%, auto, auto, 50%);
                    transform: translate(-50%, -50%);
                    max-width: 26.66px;
                    width: auto;
                }
            }
        }

        /*===============================================
        footer menu
        ===============================================*/
        .bl_footerNav {

            &_list {
                max-width: 300px;
                width: 100%;

                @include mq(lg){
                    max-width: inherit;
                    width: calc(50% - $spacing-mini);
                }

                @include mq(md){
                    width: 100%;
                }

                &Wrapper {
                    gap: 40px;

                    @include mq(lg){
                        gap: 24px;
                        flex-wrap: wrap;
                    }

                    @include mq(md){
                        display: block;
                    }
                }
            }
        }

        /*===============================================
        footer copy
        ===============================================*/
        .bl_footerCopy {

            &_list {
                font: {
                    size: 12px;
                }
                position: relative;
                padding-right: 20px;
                margin-right: 20px;
                display: inline-block;

                @include mq(md){
                    margin-bottom: 20px;
                }

                &:last-child {
                    padding: 0;
                }

                &:before {
                    opacity: .7;
                }

                &Wrapper {

                }

                a {
                    color: rgba($color-bg, .7);
                    display: inline-block;
                    text-decoration: underline;

                    @include hover {
                        opacity: .7;
                    }
                }
            }

            &right {
                font: {
                    size: 12px;
                    weight: 400;
                }

                @include mq(md){
                    margin-top: $spacing-mini;
                    display: block;
                }
            }
        }
    }
}