// Topics Category
.cmp-topicsindex__categories {
  @include not_sm {
    display: flex;
    flex-wrap:wrap;
  }
  @include sm {
    flex-wrap: nowrap;
    // 横スクロール用
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
  gap: margin(1) margin(1);

  .cmp-topicsindex__category-item {
    @include sm {
      // 横スクロール用
      display: inline-block;
    }

    font: $font-index-category;
    padding: rem(12) rem(24);
    letter-spacing: 0px;
    text-align: left;
    border-radius: 100px;

    &[data-cmp-selected="true"] {
      color: $color-index-category-button-selected-text;
      background: $color-selected 0% 0% no-repeat padding-box;
    }

    &[data-cmp-selected="false"] {
      color: $color-index-category-button-text;
      background: $color-index-category-button 0% 0% no-repeat padding-box;
      border: rem(1) solid $color-index-category-button-border;

      &:hover,
      &:focus {
        color: $color-index-category-button-hover;
        text-decoration: underline;
        text-decoration-color: $color-index-category-button-hover;
        border-color: $color-index-category-button-hover;
      }
    }
  }
}

// Topics Category Separator
.cmp-topicsindex__categories-separator {
  height: rem(25);
}

// Topics Years
.cmp-topicsindex__years {
  margin-bottom: margin(8);
  @include sm {
    // 横スクロール用
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  .cmp-topicsindex__year-item {
    @include sm {
      // 横スクロール用
      display: inline-block;
    }

    width: rem(80);
    height: rem(30);
    text-align: center;
    vertical-align: top;
    font: $font-index-year;
    letter-spacing: rem(0);
    padding-bottom: rem(15);

    &[data-cmp-selected="true"] {
      color: $color-index-year-button-selected-text;
      background-color: inherit;
      border-bottom: rem(2) solid $color-index-year-button-selected-border;
    }

    &[data-cmp-selected="false"] {
      color: $color-index-year-button-text;
      border-bottom: rem(2) solid $color-index-year-button-border;

      &:hover,
      &:focus {
        color: $color-index-year-button-hover;
        border-bottom: rem(2) solid $color-index-year-button-hover;
      }
    }

    &:not(:first-of-type) {
      margin-left: rem(8);
    }
  }
}

// Topics
.cmp-topicsindex {
  
  // Grid Setting
  .cmp-topicsindex__item {
    &:not(.cmp-topicsindex__pagination-hidden) {
      display: grid;
      grid-gap: rem(16);
      grid-template-columns: rem(85) 1fr;
      grid-template-rows: rem(32) 1fr;
      align-items: center;
  
      .cmp-topicsindex__item-date {
        grid-column-start: 1;
        grid-column-end: 2;
        display:inline-flex;
        align-items: center;
      }
      .cmp-topicsindex__item-tags {
        grid-column-start: auto;
        grid-column-end: auto;
        display:inline-flex;
        align-items: center;
      }
      .cmp-topicsindex__item-link {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }

  .ss-topicsindex--style-fixed & {
    // Grid Setting
    .cmp-topicsindex__item {
      margin-bottom: margin(2);
      padding-bottom:rem(24);
      &:not(.cmp-topicsindex__pagination-hidden) {
        grid-row-gap: 0;
        grid-column-gap: rem(16);
        grid-template-rows: 32px 92px;
        align-items: initial;
        
        .cmp-topicsindex__item-link {

          .cmp-topicsindex__item-title {
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
          }
        }
      }
    }
  }

  .cmp-topicsindex__item {
    // Border-bottom
    position:relative;
    margin-bottom: margin(2);
    padding-bottom:rem(20);

    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      border-bottom: rem(1) solid $color-index-topic-border;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: rem(12);
      border-bottom: rem(2) solid $color-topcon;
    }

    &-date {
      color: $color-index-topic-date;
      text-align: left;
      font: $font-topicsindex-date;
      letter-spacing: rem(0.28);
    }

    &-tags{
      .cmp-topicsindex__item-tag {
        padding: rem(5) rem(12);
        border-radius: rem(100);
        text-align: left;
        border: rem(1) solid $color-news-tag-border;
        font: $font-topicsindex-tags;
        letter-spacing: 0px;
        &:not(:first-of-type) {
          margin-left: rem(4);
        }
      }

    }

    &-link {
      text-align: left;
      text-decoration: underline;
      text-decoration-color: $color-index-topic-link;
      font: $font-topicsindex-link;
      letter-spacing: rem(0.28);
      color: $color-index-topic-link;
      &:hover,
      &:focus {
        color: $color-index-topic-link-hover;
        text-decoration-color: $color-index-topic-link-hover;
      }
    }

    &-external-link{
      span {
        &::after {
          @include iconExternalLinkStyle();
        }
      }
    }
  }
}

