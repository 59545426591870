@import "_text_mixins.scss";

.cmp-text {
  text-align: left;
  @include paragraphNormalText();
  letter-spacing:rem(0.28);
  & p > a {
    text-decoration: underline;
  }
}

@import "_text_link.scss";
@import "_text_size.scss";
@import "_text_list.scss";
@import "_text_quote.scss";
@import "_text_table.scss";
@import "_text_table_without_border.scss";
@import "_text_color.scss";
@import "_text_external_link.scss";
