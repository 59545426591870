@use "sass:math";


@function containerWidth($width, $col-repeat, $col-gap){
    $base-width: calc($width * $col-repeat);
    $gap-width: calc($col-gap * ($col-repeat - 1));

    @return calc($base-width + $gap-width);
}

@mixin grid-column($col-size, $col-repeat, $col-gap){
    .aem-Grid {
        display: grid;
        grid-template-columns: repeat($col-repeat, $col-size);
        grid-gap: $col-gap;
        justify-content: center;
        align-items: start;

        .aem-GridColumn {
            box-sizing: border-box;
            width: $col-size;
            max-width: 100%;
            height: auto;
            .cmp-image {
                display: inline-flex;
                align-items: center;
                img {
                    object-fit: cover;
                }
            }
        }
    }
}

@mixin column-media-loop($width, $col-repeat, $col-gap, $col-margin){
    $container-base-size: containerWidth($width, $col-repeat, $col-gap);
    @media screen and (min-width: #{$container-base-size}px) {
        @include grid-column(rem($width), $col-repeat, rem($col-gap));
    }
    $container-size: 0;
    $screen-sm-set: false;

    @for $i from ($col-repeat - 1) through 2 {
        $pre-container-size: $container-size;
        $container-size: containerWidth($width, $i, $col-gap);
        
        @if ($pre-container-size != $container-size) {
            // @debug $i;
            // @debug "container-size" $container-size;
            
            @if ($container-size <= $screen-sm-num) {
                @if ($screen-sm-set == false) {
                    // @debug $screen-sm-num;
                    // @debug "screen-sm-num";
                    $screen-sm-set: true;
                    @media (min-width: $screen-tb) and (max-width: #{$container-base-size}px ){
                        .cmp-container {
                            width: unset;
                            @include grid-column(rem($width), $i, rem($col-gap));
                        }
                    }
                }
    
            } @else  {
                // @debug "screen-size-adust";
                @media (min-width: #{$container-size}px) and (max-width: #{$container-base-size}px ){
                    .cmp-container {
                        width: unset;
                        @include grid-column(rem($width), $i, rem($col-gap));
                    }
                }
                $container-base-size: $container-size;
            } 
        }
    }
    @include sm {
        .cmp-container {
            width: unset;
            .aem-Grid {
                display: flex;
                flex-wrap:nowrap;
                flex-direction: column;
                align-items: center;
                .aem-GridColumn {
                    width:auto;

                    .cmp-image {
                        object-fit: cover;
                    }
                    &:not(:last-of-type) {
                        margin-bottom: $col-margin;
                    }
                }
            }
        }
    }

}

@mixin containerStyleResponsive($width, $height, $col-repeat, $col-gap: margin(4), $col-margin: margin(2.5)) {

    .cmp-container {
        
        width: rem(containerWidth($width, $col-repeat, $col-gap));
        margin: 0 auto;
        .aem-Grid::before,
        .aem-Grid::after {
            display: unset;
            content: unset;
        }
    }
    @include column-media-loop($width, $col-repeat, $col-gap, $col-margin);
    
}


.ss-container-style-rpv-3-cols {
    $col-basis: 280;
    $col-height: none;
    $col-gap: 32;
    $col-repeat: 3;
    $col-margin: margin(4);
    @include containerStyleResponsive($col-basis, $col-height, $col-repeat, $col-gap, $col-margin);
}

.ss-container-style-rpv-4-cols {
    $col-basis: 280;
    $col-height: none;
    $col-gap: 32;
    $col-repeat: 4;
    $col-margin: margin(4);
    @include containerStyleResponsive($col-basis, $col-height, $col-repeat, $col-gap, $col-margin);
}