// At Work Hero
@import "_teaser_hero.scss";

.ss-teaser--style-at-work-hero.ss-teaser--style-title-none {
  .cmp-teaser__title {
    display: none;
  }
}

.ss-teaser--style-at-work-hero {
  @include teaserHero();

  .cmp-teaser__pretitle {
    display: none;
  }
  .cmp-teaser__title {
    font: $font-teaser-at-work-hero-title;

    @include sm {
      font: $font-teaser-sm-at-work-hero-title;
    }
  }

  .cmp-teaser__description {
    display: none;
  }

  .cmp-teaser__action-container {
    display: none;
  }

  .cmp-teaser__tag-container {
    .cmp-common__tag-tpa__brand {
      &--topcon,
      &--sokkia,
      &--clearedge3d,
      &--xyeez {
        font: $font-teaser-at-work-hero-brand-tag;
        @include sm {
          font: $font-teaser-sm-at-work-hero-brand-tag;
        }
      }
    }

    .cmp-common__tag-tpa__industry {
      &--survey,
      &--construction,
      &--bim,
      &--agriculture {
        font: $font-teaser-at-work-hero-industry-tag;

        @include sm {
          font: $font-teaser-at-work-hero-industry-tag;
        }
      }
    }

    .cmp-common__tag-tpa__product-category {
      &--total-stations,
      &--gnss,
      &--machine-control,
      &--field-controllers,
      &--software,
      &--lasers,
      &--3d-scanners,
      &--levels-theodolites,
      &--agriculture-equipments,
      &--raku,
      &--monitoring,
      &--survey-equipments,
      &--related-products,
      &--infrastructure-maintenance {
        font: $font-teaser-at-work-hero-category-tag;

        @include sm {
          font: $font-teaser-at-work-hero-category-tag;
        }
      }
    }

    .cmp-common__tag-tpa__news {
      &--product-info,
      &--campaign,
      &--information,
      &--atwork,
      &--news-release,
      &--column {
        display: none;
      }
    }

    .cmp-common__tag-tpa__event {
      &--online,
      &--physical,
      &--free,
      &--paid {
        display: none;
      }
    }
  }
}
