@use "../../global"as *;

/*===============================================
button
===============================================*/

.bl_ui_module {
        
    .el_arrow {

        &_icon {
            width: 16px;
            height: 13.518px;
            margin-left: 10px;
            display: inline-block;
            vertical-align: middle;
            @include transition;

            img, svg {
                display: block;
            }

            svg {

                .cls-1 {
                    fill: $color-key;
                    @include transition;
                }
            }

            &__l {
                margin-left: 0;
                margin-right: 10px;
            }

            &__circle {
                background: {
                    color: $color-key;
                }
                position: relative;
                width: 28px;
                height: 28px;
                margin-left: 12px;
                border-radius: 50%;
                flex-shrink: 0;

                img {
                    @include absolute(50%, auto, auto, 50%);
                    transform: translate(-50%, -50%);
                    width: 12px;
                    height: 10.139px;
                }

                svg {
                    @include absolute(50%, auto, auto, 50%);
                    transform: translate(-50%, -50%);
                }

                &__l {
                    margin-left: 0;
                    margin-right: 12px;
                }

                &__pageTop {
                    width: 80px;
                    height: 80px;

                    @include mq(md){
                        width: 72px;
                        height: 72px;
                    }

                    img {
                        width: 17.141px;
                        height: 20px;
                    }
                }

                &__lg {
                    width: 40px;
                    height: 40px;

                    img {
                        width: 14px;
                        height: 11.637px;
                    }
                }

                &__md {
                    width: 36px;
                    height: 36px;

                    img {
                        max-width: $spacing-1;
                        width: auto;
                        max-height: $spacing-1;
                        height: auto;
                    }
                }

                &__shd {
                    box-shadow: 3px 4px 12px rgba($color-key, .3);
                }
            }

            &__grad {
                background: $color-grad;

                svg {

                    .cls-1 {
                        fill: $color-bg;
                    }
                }
            }

            &__wh {
                background: {
                    color: $color-bg;
                }
            }

            &__externalLink {

                .cls-1 {
                    fill: $color-bg;
                    stroke: $color-bg;
                    stroke-width: 0.5px;
                }
            }
        }

        &_ttl {
            margin-top: 2px;

            &__underline {
                text-decoration: underline;
            }
        }
    }
}