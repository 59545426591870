@use "../../global"as *;

/*===============================================
categoryList
===============================================*/

.bl_ui_module {

    .el_category_list {
        font: {
            size: 15px;
        }
        width: calc(50% - $spacing-mini);

        @include mq(md){
            font: {
                size: 14px;
            }
            width: 100%;
            margin-bottom: 14px;
        }

        &:last-child {

            @include mq(md){
                margin-bottom: 0;
            }
        }

        &__quarter {
            width: calc(25% - $spacing-mini);
        }

        &__header {
            font: {
                size: 13px;
            }
        }

        &Wrapper {
            // padding: $spacing-3 $spacing-4;
            padding: 35px $spacing-4;
            gap: $spacing-mini;

            @include mq(md){
                padding: 20px 20px 24px;
                display: block;
            }

            &__header {
                padding: $spacing-3 52px;
            }
        }

        a {
            position: relative;
            display: inline-block;

            &:before {
                right: -$spacing-3;
            }

            @include hover {

                .el_arrow_icon {
                    transform: translateX(6px);
                }
            }
        }
    }
}