
//== Defaults

html, body {
  // margin: 0;
  color: $color-text;
  font-size: $font-size;
  // font-family: defaultFont, serif;
  // line-height: $font-height;
  background: $color-background-lighter;
  font-smooth: antialiased;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-font-smoothing: antialiased;
  -moz-text-size-adjust: 100%;
  -ms-font-smoothing: antialiased;
  -ms-text-size-adjust: 100%;
  -o-font-smoothing: antialiased;
  -o-text-size-adjust: 100%;
}

a {
  // color: $color-link;
}

p {
  // margin-block: 0.75rem;
  // line-height: 28px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // margin-block: 0;
}

button,
input,
optgroup,
select,
textarea {
  // font: inherit;
}

//== Grid

$max_col: 12;

// Default breakpoint
.aem-Grid {
  @include generate-grid(default, $max_col);
}

// Tablet breakpoint
@media (max-width: $screen-lg) {
  .aem-Grid {
    @include generate-grid(tablet, $max_col);
  }
}

// Phone breakpoint
@media (max-width: $screen-sm) {
  .aem-Grid {
    @include generate-grid(phone, $max_col);
  }
}
