@use "sass:math";

@mixin marginStyleCalc($type, $margin) {

    @if ($type == top) {
        margin-top: margin($margin) !important;
        @include sm {
            margin-top: margin( math.div($margin, 2) ) !important;
        }
    } @else if ($type == bottom) {
        margin-bottom: margin($margin) !important;
        @include sm {
            margin-bottom: margin( math.div($margin, 2) ) !important;
        }
    } @else if ($type == right) {
        margin-right: margin($margin) !important;
        @include sm {
            margin-right: margin( math.div($margin, 2) ) !important;
        }
    } @else if ($type == left) {
        margin-left: margin($margin) !important;
        @include sm {
            margin-left: margin( math.div($margin, 2) ) !important;
        }
    }
}


.ss-margin--style-margin-top {
    &-small {
        @include marginStyleCalc(top, 2.5);
    }
    &-medium {
        @include marginStyleCalc(top, 5);
    }
    &-large {
        @include marginStyleCalc(top, 10);
    }
    &-extra-large {
        @include marginStyleCalc(top, 12.5);
    }
}

.ss-margin--style-margin-bottom {
    &-small {
        @include marginStyleCalc(bottom, 2.5);
    }
    &-medium {
        @include marginStyleCalc(bottom, 5);
    }
    &-large {
        @include marginStyleCalc(bottom, 10);
    }
    &-extra-large {
        @include marginStyleCalc(bottom, 12.5);
    }
}

.ss-margin--style-margin-left {
    &-small {
        @include marginStyleCalc(left, 2);
    }
    &-medium {
        @include marginStyleCalc(left, 4);
    }
    &-large {
        @include marginStyleCalc(left, 6);
    }
}

.ss-margin--style-margin-right {
    &-small {
        @include marginStyleCalc(right, 2);
    }
    &-medium {
        @include marginStyleCalc(right, 4);
    }
    &-large {
        @include marginStyleCalc(right, 6);
    }
}

 
main.container.ss-margin-main-margin-top {
    
    margin-top: rem($header-floating-height);

    @media screen and (max-width: $screen-lg) {
        margin-top: $header-floating-height-sm;
    }
}