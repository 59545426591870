@use "../../global"as *;

.bl_ui_module {

    /*===============================================
    nab
    ===============================================*/
    .el_nav {

        &_list {
            font: {
                size: 15px;
            }

            @include mq(md){
                margin-bottom: $spacing-1;
            }

            &_item {
                margin-bottom: 28px;

                @include mq(md){
                    margin-bottom: $spacing-1;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &_btn {
            border-bottom: 1px solid #DEDFE0;
            position: relative;
            padding-bottom: $spacing-mini;
            margin-bottom: 20px;

            @include mq(md){
                padding-bottom: 8px;
                margin-bottom: $spacing-mini;
            }

            &:before {
                content: '';
                background: {
                    color: $color-key;
                }
                @include absolute(auto, auto, -1px, 0);
                width: $spacing-mini;
                height: 1px;
            }

            .el_arrow {

                a {

                    @include hover {

                        .el_arrow_icon {
                            transform: translateX(6px);
                        }
                    }
                }
            }
        }

        &_child {
            margin-bottom: 28px;
            overflow: hidden;

            @include mq(md){
                margin-bottom: $spacing-mini;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &_list {
                font: {
                    size: 13px;
                }
                position: relative;
                margin-bottom: $spacing-mini;
                gap: 0 $spacing-1;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    color: #75777D;
                    display: inline-block;

                    &.hp_underline {
                        text-underline-offset: 1px;
                        flex-shrink: 0;
                    }

                    @include hover {
                        color: $color-key;
                    }
                }

                &_ttl {
                    position: relative;
                    margin-bottom: $spacing-mini;
                    pointer-events: auto;

                    @include mq(md){
                        font: {
                            size: 14px;
                        }
                        margin: 8px 0;
                    }

                    &:first-child {
                        margin-top: 0;
                    }

                    a {

                        @include hover {
                            color: $color-key;
                        }
                    }

                    .bl_accordion_icon {
                        @include absolute(50%, auto, auto, 50%);
                        transform: translate(-50%, -50%);
                        width: 10px;
                        height: 10px;

                        &Wrapper {
                            display: none;

                            @include mq(md){
                                background: {
                                    color: $color-bg;
                                }
                                border: 1px solid #DEDFE0;
                                @include absolute(0, 0, auto, auto);
                                width: 24px;
                                height: 24px;
                                display: block;
                                border-radius: 50%;
                            }
                        }

                        span {
                            @include transition;
                            transition-property: transform;

                            background: {
                                color: $color-key;
                            }
                            position: absolute;
                            left: 0;
                            width: 100%;
                            height: 2px;
                            border-radius: 2px;

                            &:nth-of-type(1) {
                                top: 4px;
                                transform: rotate(0deg);
                            }

                            &:nth-of-type(2) {
                                top: 4px;
                                transform: rotate(90deg);
                            }
                        }
                    }

                    &[aria-expanded="true"] {

                        .bl_accordion_icon {

                            span {
                                &:nth-of-type(1) {
                                    display: none;
                                }

                                &:nth-of-type(2) {
                                    top: 4px;
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }
                }

                &_inner {
                    border-left: 1px solid #DEDFE0;
                    // font: {
                    //     weight: 500;
                    // }
                    padding: 4px 0 4px $spacing-mini;
                    margin-top: $spacing-1;

                    @include mq(md){
                        margin: 12px 0 0;
                    }
                }

                &_dropdown {
                    font: {
                        size: 12px;
                    }

                    &Wrapper {
                        gap: 4px 6px;
                    }

                    a {

                        &:before {
                            content: '/';
                            color: $color-key;
                            padding-right: 4px;
                        }
                    }
                }
            }
        }
    }
}