
.ss-text--style-table-without-border{
  .cmp-text {
    table{
      table-layout: fixed;
      width: 100%;
    }
    th {
      width: 30%;
      text-align: left;         
      vertical-align: middle;
      height: rem(56);
      border-bottom: rem(2) solid $color-table-border-bottom;
    }
    td {
      text-align: left;
      vertical-align: middle;
      width: 70%;
      height: rem(56);
      border-bottom: 1px solid $color-shade-2;
    }
    
    /* Mobile View  Table header*/
    th {
      @include sm {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      @include not_sm {
        width: 30%;
        text-align: left;
        vertical-align: middle;
      }
      min-height: 54px;
    }

    /* Mobile View  Table data */
    td {
      @include sm {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      @include not_sm {
        width: 35%;
        text-align: left;
        vertical-align: middle;
        padding-left: rem(18);
        padding-right: rem(18);
      }
    }
  }
}
  