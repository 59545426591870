// h3 Variables
$h3-height: rem(62);
$h3-accent-width: rem(12);
$h3-accent-height: rem(1);

// h4 Variables
$h4-width: rem(864);
$h4-height: rem(59);
$h4-accent-width: rem(8);
$h4-small-accent-height: rem(12);
$h4-horiozntal-padding: rem(24);
$h4-top-padding: rem(12);
$h4-bottom-padding: rem(14);

// h4 SP
$h4-sp-height: rem(53);
$h4-sp-accent-width: rem(6);
$h4-sp-small-accent-height: rem(12);
$h4-sp-horiozntal-padding: rem(16);
$h4-sp-top-padding: rem(12);
$h4-sp-bottom-padding: rem(16);

// h5 Variables
$h5-width: rem(864);
$h5-height: rem(31);
$h5-border-left-width: rem(6);
$h5-border-bottom-height: rem(1);
$h5-padding-left: rem(24);
$h5-padding-top: rem(14);

// h5 SP
$h5-sp-border-left-width: rem(6);
$h5-sp-padding-left: rem(12);
$h5-sp-padding-top: rem(10);


.cmp-title {
  & h2 {
    font: $font-title-h2;
    letter-spacing: rem(0.8);
    
    @include sm {
      font: $font-title-h2-sm;
    }
  }

  & h3 {
    // Box
    line-height: $h3-height;

    // Font
    font: $font-title-h3;
    letter-spacing: rem(0.24);

    @include sm {
      font: $font-title-h3-sm;
    }
  }

  & h4 {
    // Box
    background-color: $color-title-white;
    display: flex;
    flex-wrap: wrap;

    // Font
    font: $font-title-h4;
    letter-spacing: rem(0.48);
    
    // Grey Vertical Bar
    border-left: $h4-accent-width solid $color-title-grey;

    /* top | right | bottom | left */
    padding: $h4-top-padding $h4-horiozntal-padding $h4-bottom-padding $h4-horiozntal-padding;

    // Blue Accent
    &::before {
      content: "";
      display: block;
      width: $h4-accent-width;
      height: $h4-small-accent-height;
      background-color: $color-title-blue;

      // Position
      position: relative;
      top: -$h4-top-padding;
      right: ($h4-horiozntal-padding+$h4-accent-width);

      @include sm {
        width: $h4-sp-accent-width;
        height: $h4-sp-small-accent-height;

        // Position
        top: -$h4-sp-top-padding;
        right: ($h4-sp-horiozntal-padding+$h4-sp-accent-width);
      }
    }

    &::after {
      content: "";
      display: block;
      width: $h4-accent-width;
      height: $h4-small-accent-height;
      background-color: transparent;

      // Position
      position: relative;
      top: -$h4-top-padding;
      right: ($h4-horiozntal-padding+$h4-accent-width);

    }

    @include sm {
      font: $font-title-h4-sm;

      border-left: $h4-sp-accent-width solid $color-title-grey;

      /* top | right | bottom | left */
      padding: $h4-sp-top-padding $h4-sp-horiozntal-padding $h4-sp-bottom-padding $h4-sp-horiozntal-padding;
    }
  }

  & h5 {
    // Box

    // Font
    font: $font-title-h5;
    letter-spacing: rem(0.44);

    // Blue Vertical Bar on the left
    border-left: $h5-border-left-width solid $color-title-blue;
    padding-left: $h5-padding-left;
    padding-right: $h5-padding-left;

    
    @include sm {
      font: $font-title-h5-sm;

      border-left: $h5-sp-border-left-width solid $color-title-blue;
      padding-left: $h5-sp-padding-left;
      padding-right: $h5-sp-padding-left;
    }

  }

  & h6 {
    font: $font-title-h6;
    letter-spacing: rem(0.36);

    @include sm {
      font: $font-title-h6-sm;
    }
  }

  .cmp-title_date {
    // Font
    font: $font-title-date;
    letter-spacing: rem(0.6);
    margin-bottom: rem(24);

    // Color
    color: $color-title-blue;
    opacity: 1;
  }

  .cmp-title__tag-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: rem(16);
    margin-bottom: rem(22);

    p[class^="cmp-common__tag-"] {
      // Font
      font: $font-title-tag;
      letter-spacing: rem(0);

      // Color
      color: $color-title-black;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      opacity: 1;

      // Border
      border: rem(1) solid $color-title-border;
      border-radius: rem(100);

      // Paddings
      padding: rem(6) rem(12);
      margin-right: rem(4);
    }
  }

  // Grid Display
  display: grid;
  grid-template-columns: rem(91) 1fr;
  align-items: center;
  
  .cmp-title_date {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .cmp-title__tag-container {
    grid-column-start: auto;
    grid-column-end: auto;
  }
  .cmp-title__text {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.cmp-title__text {
}
.cmp-title__link {
  color: $color-link;
  text-decoration: underline;
  &:hover,
  &:focus {
    color: rgba($color-link,0.8);
  }
}

// h5 Grey Separator
.cmp-title__heading-type-h5 {
    border-bottom: $h5-border-bottom-height solid $color-title-grey;
    padding-bottom: $h5-padding-top;
    
    @include sm {
      padding-bottom: $h5-sp-padding-top;
    }
}

.ss-title--style-left-align {
  text-align: left;
}
.ss-title--style-center-align {
  text-align: center;
  display: flex;
  justify-content: center;

  & h4 {
    width: $h4-width;
  }
  & h5 {
    width: $h5-width;
  }

}
.ss-title--style-white {
  color: $color-title-white;

  & h3 {
    // Separator
    border-top: $h3-accent-height solid rgba($color-title-white, 0.15);

    &::before {
      background-color: $color-title-white;
    }
  }
  & h5 {
    // Vertical Accent Bar
    border-left: $h5-border-left-width solid $color-title-white;
  }
  // h5 White Separator
  & .cmp-title__heading-type-h5 {
        background-clip: padding-box;
        border-bottom: $h5-border-bottom-height solid rgba(255,255,255,0.15);
        padding-bottom: $h5-padding-top;
  }
}


.ss-title--style-blue {
  color: $color-title-blue;
}

.ss-title--style-headline {

  // Grey Separator
  border-top: $h3-accent-height solid $color-title-grey;

  .cmp-title {
    padding-top: rem(30);

    @include sm {
      padding-top: rem(13);
    }

    & h3 {
      &::before {
        content: "";
        display: block;
        max-width: $h3-accent-width;
        height: $h3-accent-height;
        background-color: $color-title-blue;
  
        // Position
        position: relative;
        left: 50%;
        top: -$h3-accent-height - rem(30) + calc($h3-accent-height / 2);
        transform: translate(-50%, -50%);

        @include sm {
          top: -$h3-accent-height - rem(13) + calc($h3-accent-height / 2);
        }
      }
    }
  }
}