// Product Hero
@import '_teaser_hero.scss';
.ss-teaser--style-product-hero.ss-teaser--style-title-none {
    .cmp-teaser__title {
        display:none;
    }
}

.ss-teaser--style-product-hero {
    @include teaserHero();

    .cmp-teaser__content {

        .cmp-teaser__pretitle {
            display: none;
        }

        .cmp-teaser__title {
            font: $font-teaser-product-hero-title;

            @include sm {
                margin-bottom: rem(2);
                font: $font-teaser-sm-product-hero-title;
            }
        }

        .cmp-teaser__description {
            // height: rem(21);
            margin-top: rem(0);
            margin-bottom: rem(25);
            margin-left: rem(0);

            @include sm {
                top: rem(40);
                margin-top: rem(0);
                margin-bottom: rem(20);
                margin-left: rem(0);
            }
        }

        .cmp-teaser__action-container {
            display: none;
        }

        .cmp-teaser__tag-container {

            .cmp-common__tag-tpa__brand{
                &--topcon,
                &--sokkia,
                &--clearedge3d,
                &--xyeez {
                    font: $font-teaser-product-hero-brand-tag;

                    @include sm {
                        font: $font-teaser-sm-product-hero-brand-tag;
                    }
                }
            }

            .cmp-common__tag-tpa__industry {
                &--survey,
                &--construction,
                &--bim,
                &--agriculture {
                    font: $font-teaser-product-hero-industry-tag;

                    @include sm {
                        font: $font-teaser-product-hero-industry-tag;
                    }
                }
            }

            .cmp-common__tag-tpa__product-category{
                &--total-stations,
                &--gnss,
                &--machine-control,
                &--field-controllers,
                &--software,
                &--lasers,
                &--3d-scanners,
                &--levels-theodolites,
                &--agriculture-equipments,
                &--raku,
                &--monitoring,
                &--survey-equipments,
                &--related-products,
                &--infrastructure-maintenance {
                    font: $font-teaser-product-hero-category-tag;

                    @include sm {
                        font: $font-teaser-product-hero-category-tag;
                    }
                }
            }

            .cmp-common__tag-tpa__news{
                &--product-info,
                &--campaign,
                &--information,
                &--atwork,
                &--column,
                &--news-release {
                    display: none;
                }
            }

            .cmp-common__tag-tpa__event{
                &--online,
                &--physical,
                &--free,
                &--paid {
                    display: none;
                }
            }
        }
    }
}
