.ss-text--style-quote{
  .cmp-text {
    @include paragraphNormalText();
    position: relative;
    max-width: rem(864);
    height:rem(130);
    $padding-tb: rem(49.5);
    $padding-lr: rem(72);
    padding: $padding-tb $padding-lr;
    &::before {
      font: $font-text-quote-mark;
      display: inline-block;
      content: '"';
      position: absolute;
      top: rem(0);
      left: $padding-lr;
      letter-spacing: rem(1.04);
    }
  }
}