// Center Align Text
.ss-teaser--style-center-align-text {
    .cmp-teaser {
        position: relative;
        font: $font-teaser;

        width: rem(280);
        height: rem(315);

        @include sm {
            width: rem(324);
            height: rem(256);
        }
    }

    .cmp-teaser__image {
        position: relative;
        top: rem(32);
        width: rem(104);
        height: rem(104);
        margin-bottom: rem(16);
        margin-left: rem(88);
        margin-right: rem(88);

        @include sm {
            top: rem(20);
            width: rem(95);
            height: rem(95);
            margin-bottom: rem(16);
            margin-left: rem(115);
            margin-right: rem(114);
        }

        & .cmp-image {
        }
        & img {
            width: rem(104);
            height: rem(104);

            @include sm {
                width: rem(95);
                height: rem(95);
            }
        }
    }

    .cmp-teaser__content {
        position: absolute;
        top: rem(152);
    
        @include sm {
          top: rem(131);
        }
    }

    .cmp-teaser__pretitle {
        display: none;
    }

    .cmp-teaser__title {
        margin-top: rem(0);
        margin-bottom: rem(16);
        font: $font-teaser-title;
        color: $color-teaser-title;
        text-align: center;

        @include sm {
            margin-top: rem(0);
            margin-bottom: rem(14);
            font: $font-teaser-sm-title;
        }
    }
  
    .cmp-teaser__title-link {
        pointer-events: none;
        color: $color-teaser-title;
    }

    .cmp-teaser__description {
        width: rem(239);
        margin-top: rem(0);
        margin-left: rem(21);
        margin-right: rem(20);
        font: $font-teaser-description;
        text-align: left;

        @include sm {
          width: rem(283);
          margin-top: rem(0);
          margin-left: rem(21);
          margin-right: rem(20);
        }
    }  

    .cmp-teaser__action-container {
        display: none;
    }

    .cmp-teaser__tag-container {
        display: none;
    }

}
