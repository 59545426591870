@use "../../global"as *;

/*===============================================
heading
===============================================*/

.bl_ui_module {

    .el_headingArrow {
        position: relative;
        margin-bottom: $spacing-3;

        a {

            @include hover {

                .el_arrow_icon {
                    transform: translateX(8px);
                }
            }
        }

        &__blue {

            a {
                color: $color-key;
            }
        }

        &__sm {
            margin-bottom: $spacing-1;

            .el_headingArrow_ttl {
                font: {
                    size: 15px;
                }
            }
        }

        &_icon {
            width: auto;
            height: 30px;
            margin-bottom: $spacing-mini;

            @include mq(md){
                margin-bottom: 8px;
            }

            img {
                width: auto;
                height: auto;
            }
        }

        &_ttl {
            font: {
                size: 18px;
            }

            @include mq(md){
                font: {
                    size: $fontsize-2;
                }
            }
        }

        &__border {

            &:before, &:after {
                content: '';
                @include absolute(auto, auto, -$spacing-mini, 50%);
                transform: translate(-50%, -50%);
                height: 1px;
            }

            &:before {
                background: {
                    color: $color-key
                }
                width: $spacing-mini;
            }

            &:after {
                background: {
                    color: rgba($color-base, .15);
                }
                width: 100%;
            }
        }
    }
}