.caption {
  .cmp-image {
    text-align: center;
  }
  .cmp-image__link {
  }
  .cmp-image__image {
  }
  .cmp-image__title {
    display: block;
    color: $color-image-caption;
    letter-spacing: rem(0.28);

    padding-top: rem(21);
    font: $font-image-caption;

    @include sm {
      padding-top: rem(12);
      font: $font-image-caption-sp;
    }
  }
}
