@use "sass:math";

.breadcrumb {
    font: $font-breadcrumb;
    height: rem(27);
    border-bottom: $border-style;
}
.cmp-breadcrumb {
    
    display:inline-block;
    &__list {
        margin: 0;
        padding: 0;
        border: 0;
    }
    &__item {
        display: inline;
        @include sm {
            display:none;
            &:nth-last-child(2) {
                display: inline;
            }
        }
        vertical-align: baseline;
        margin-top: 0;

        &.cmp-breadcrumb__item--active > span {
            color: $color-link-selected;
        }
        & a,
        > span {
            text-align: left;
            letter-spacing: 0px;
            color: $color-link-normal;
        }
        &:not(:first-of-type) {
            .cmp-breadcrumb__itemprop-before {
                display: inline-flex;
                justify-content: flex-start;
                width: auto;
                // top: rem(-6);
                position: relative;
                margin-left: rem(8);
                margin-right: rem(8);
                content: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%222%22%20viewBox%3D%220%200%2016%202%22%3E%3Cg%20id%3D%22group_3893%22%20data-name%3D%22group_3893%22%20transform%3D%22translate(-271%20-1470.5)%22%3E%3Cline%20id%3D%22line_39%22%20data-name%3D%22line%2039%22%20x2%3D%2216%22%20transform%3D%22translate(271%201471.5)%22%20fill%3D%22none%22%20stroke%3D%22%23dbdcdd%22%20stroke-width%3D%222%22%2F%3E%3Cline%20id%3D%22line_40%22%20data-name%3D%22line%2040%22%20x2%3D%224%22%20transform%3D%22translate(271%201471.5)%22%20fill%3D%22none%22%20stroke%3D%22%231466c6%22%20stroke-width%3D%222%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
                vertical-align: middle;
            }
        }
        &-link {
            &:hover {
                color: $color-link-hover;
            }
            &:focus {
                color: $color-link-focus;
            }
        }
    }
    
}

