@use "sass:math";

@mixin paddingStyleCalc($type, $padding) {

    @if ($type == top) {
        padding-top: margin($padding) !important;
        @include sm {
            padding-top: initial;
        }
    } @else if ($type == bottom) {
        padding-bottom: margin($padding) !important;
        @include sm {
            padding-bottom: initial;
        }
    } @else if ($type == right) {
        padding-right: margin($padding) !important;
        @include sm {
            padding-right: initial;
        }
    } @else if ($type == left) {
        padding-left: margin($padding) !important;
        @include sm {
            padding-left: initial;
        }
    }
}


.ss-padding--style-padding-top {
    &-small {
        @include paddingStyleCalc(top, 2.5);
    }
    &-medium {
        @include paddingStyleCalc(top, 5);
    }
    &-large {
        @include paddingStyleCalc(top, 10);
    }
    &-extra-large {
        @include paddingStyleCalc(top, 12.5);
    }
}

.ss-padding--style-padding-bottom {
    &-small {
        @include paddingStyleCalc(bottom, 2.5);
    }
    &-medium {
        @include paddingStyleCalc(bottom, 5);
    }
    &-large {
        @include paddingStyleCalc(bottom, 10);
    }
    &-extra-large {
        @include paddingStyleCalc(bottom, 12.5);
    }
}

.ss-padding--style-padding-left {
    &-small {
        @include paddingStyleCalc(left, 2);
    }
    &-medium {
        @include paddingStyleCalc(left, 4);
    }
    &-large {
        @include paddingStyleCalc(left, 6);
    }
}

.ss-padding--style-padding-right {
    &-small {
        @include paddingStyleCalc(right, 2);
    }
    &-medium {
        @include paddingStyleCalc(right, 4);
    }
    &-large {
        @include paddingStyleCalc(right, 6);
    }
}