// Atwork List
.ss-list--style-atwork-list {
    .cmp-list__item-date {
        display: none;
      }
    .cmp-list {
        display: grid;
        // 位置固定の場合には、、下記を使用する
        // grid-template-columns: rem(384) rem(384) rem(384);
        grid-template-columns: repeat(auto-fill, minmax(rem(384), 1fr));
        grid-gap: rem(24) rem(32);

        @include sm {
            // grid-template-columns: rem(324);
            // responsive 対応する場合には、下記を使用する
            grid-template-columns: repeat(auto-fill, minmax(rem(324), 1fr));
            grid-gap: rem(24);
        }
    }

    .cmp-list__item {
        display:flex;
        justify-content: center;
        align-items: flex-start;
        .cmp-teaser {
            display: flex;
            flex-direction: column;
            font: $font-teaser;
            padding-left: rem(24);
            padding-right: rem(24);
            padding-top: rem(24);
            padding-bottom: rem(24);
            width: rem(384);
            height: auto;

            @include sm {
                padding-left: rem(20);
                padding-right: rem(20);
                padding-top: rem(20);
                width: rem(324);
                height: auto;
            }
        }

        .cmp-teaser__link {
            pointer-events: auto;
        }

        .cmp-teaser__image {
            width: rem(336);
            height: rem(189);
            margin-bottom: rem(11);

            @include sm {
                width: rem(284);
                height: rem(160);
                margin-bottom: rem(10);
            }

            & .cmp-image {
                pointer-events: none;
            }
            & img {
                pointer-events: none;

                @include sm {
                    width: rem(284);
                    height: rem(160);
                }
            }
        }

        .cmp-teaser__content {
            width: rem(324);
            padding-left: rem(12);
            padding-right: rem(12);

            @include sm {
                top: rem(190);
                width: rem(260);
            }
            .cmp-teaser__categorytag-container{
                @include ListTeaserTagsShowStyle(true);
                .cmp-common__tag-tpa__brand{
                    &--topcon,
                    &--sokkia,
                    &--clearedge3d,
                    &--xyeez {
                        margin-bottom: rem(6);
                    }
                }
            }

            .cmp-teaser__pretitle {
                display: none;
            }

            .cmp-teaser__title {
                height: rem(44);
                margin-bottom: rem(12);
                font: $font-teaser-at-work-title;
                color: $color-teaser-at-work-title;
                text-align: left;
                pointer-events: none;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;

                @include sm {
                    height: rem(43);
                    font: $font-teaser-sm-at-work-title;
                }
            }

            .cmp-teaser__title-link {
                pointer-events: none;
                color: $color-teaser-at-work-title;
            }

            .cmp-teaser__description {
                display: none;
            }

            .cmp-teaser__tag-container {
                flex-wrap: wrap;
                margin-bottom: rem(22);
                display: flex;
                @include ListTeaserTagsShowStyle(false, true, true);
            }

            .cmp-teaser__link {
                pointer-events: auto;

                // Button
                $text-margin-left: 18px;
                .cmp-teaser__detail {
                    display:flex;
                    flex:1;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    .cmp-teaser__detail-text {
                        display: flex;
                        flex:auto;

                        justify-content: center;
                        align-items: center;
                        letter-spacing: 0px;
                        color: $color-text;
                        opacity: 1;

                    }
                    .cmp-teaser__detail-icon {
                        display: flex;

                    }

                }
                @include ListTeaserButtonStyle(squre, 312px, 48px, $font-teaser, 21px, $text-margin-left);
                @include sm {
                    @include ListTeaserButtonStyle(squre, 259px, 48px, $font-teaser, 21px, $text-margin-left);
                }
                @include ListTeaserButtonColor(styleBlue, squre, button);
            }
        }
    }
}



// Column List
.ss-list--style-column-list {
    .cmp-list {
        display: grid;
        // 位置固定の場合には、、下記を使用する
        // grid-template-columns: rem(384) rem(384) rem(384);
        grid-template-columns: repeat(auto-fill, minmax(rem(384), 1fr));
        grid-gap: rem(24) rem(32);

        @include sm {
            // grid-template-columns: rem(324);
            // responsive 対応する場合には、下記を使用する
            grid-template-columns: repeat(auto-fill, minmax(rem(324), 1fr));
            grid-gap: rem(24);
        }
    }

    .cmp-list__item {
        display:flex;
        justify-content: center;
        align-items: flex-start;
        .cmp-teaser {
            display: flex;
            flex-direction: column;
            font: $font-teaser;
            padding-left: rem(24);
            padding-right: rem(24);
            padding-top: rem(24);
            padding-bottom: rem(24);
            width: rem(384);
            height: auto;

            @include sm {
                padding-left: rem(20);
                padding-right: rem(20);
                padding-top: rem(20);
                width: rem(324);
                height: auto;
            }
        }

        .cmp-teaser__link {
            pointer-events: auto;
        }

        .cmp-teaser__image {
            width: rem(336);
            height: rem(189);
            margin-bottom: rem(11);

            @include sm {
                width: rem(284);
                height: rem(160);
                margin-bottom: rem(10);
            }

            & .cmp-image {
                pointer-events: none;
            }
            & img {
                pointer-events: none;

                @include sm {
                    width: rem(284);
                    height: rem(160);
                }
            }
        }

        .cmp-teaser__content {
            width: rem(324);
            padding-left: rem(12);
            padding-right: rem(12);

            @include sm {
                top: rem(190);
                width: rem(260);
            }
            .cmp-teaser__categorytag-container{
                display: flex;
                justify-content: space-between;
                @include ListTeaserTagsShowStyle(false,false,false,false,false,true);
                .cmp-common__tag-tpa__brand{
                    &--topcon,
                    &--sokkia,
                    &--clearedge3d,
                    &--xyeez {
                        margin-bottom: rem(6);
                    }
                }
            }

            .cmp-teaser__pretitle {
                display: none;
            }

            .cmp-teaser__title {
                height: rem(44);
                margin-bottom: rem(12);
                font: $font-teaser-at-work-title;
                color: $color-teaser-at-work-title;
                text-align: left;
                pointer-events: none;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;

                @include sm {
                    height: rem(43);
                    font: $font-teaser-sm-at-work-title;
                }
            }

            .cmp-teaser__title-link {
                pointer-events: none;
                color: $color-teaser-at-work-title;
            }

            .cmp-teaser__description {
                display: none;
            }

            .cmp-teaser__tag-container {
                flex-wrap: wrap;
                margin-bottom: rem(22);
                display: flex;
                @include ListTeaserTagsShowStyle(false,false,false,false,false,false);
            }

            .cmp-teaser__link {
                pointer-events: auto;

                // Button
                $text-margin-left: 18px;
                .cmp-teaser__detail {
                    display:flex;
                    flex:1;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    .cmp-teaser__detail-text {
                        display: flex;
                        flex:auto;

                        justify-content: center;
                        align-items: center;
                        letter-spacing: 0px;
                        color: $color-text;
                        opacity: 1;

                    }
                    .cmp-teaser__detail-icon {
                        display: flex;

                    }

                }
                @include ListTeaserButtonStyle(squre, 312px, 48px, $font-teaser, 21px, $text-margin-left);
                @include sm {
                    @include ListTeaserButtonStyle(squre, 259px, 48px, $font-teaser, 21px, $text-margin-left);
                }
                @include ListTeaserButtonColor(styleBlue, squre, button);
            }
        }
    }
}
