// Page Nation

.paginationjs {
  .paginationjs-pages {
    & ul {
      justify-content: center;

      &>li {
        width: rem(44);
        height: rem(44);
      }
    }

    & a {
      text-decoration: none;
    }

    .paginationjs-page {
      font: $font-pagination;
      letter-spacing: rem(0);
      text-align: center;
      vertical-align: middle;
      border: rem(1) solid $color-pagination-button-border;
      background: $color-pagination-button-background 0% 0% no-repeat padding-box;

      &:hover,
      &:focus {
        background: $color-pagination-button-hover 0% 0% no-repeat padding-box;
      }

      & a {
        color: $color-pagination-button-anchor;
      }

      &.active {
        background: $color-pagination-button-active 0% 0% no-repeat padding-box;

        & a {
          color: $color-pagination-button-active-anchor;
        }
      }
    }

    .paginationjs-ellipsis {
      letter-spacing: 0px;
      text-align: center;
      vertical-align: middle;
      font: $font-pagination-ellipsis;

      & a {
        color: $color-pagination-button-anchor;
      }
    }

    .paginationjs-prev {
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2244%22%20height%3D%2244%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cg%20transform%3D%22rotate(180%20256%20256)%22%3E%3Cpath%20fill%3D%22%231466c6%22%20d%3D%22M464%20256c0-114.87-93.13-208-208-208S48%20141.13%2048%20256s93.13%20208%20208%20208s208-93.13%20208-208Zm-224%2080.09L303.58%20272H154v-32h149.58L240%20175.91l22.71-22.54L364.54%20256L262.7%20358.63Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');

      &:hover,
      &:focus {
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2244%22%20height%3D%2244%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cg%20transform%3D%22rotate(180%20256%20256)%22%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%231466c6%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%2232%22%20d%3D%22M262.62%20336L342%20256l-79.38-80m68.35%2080H170%22%2F%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%231466c6%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%2232%22%20d%3D%22M256%20448c106%200%20192-86%20192-192S362%2064%20256%2064S64%20150%2064%20256s86%20192%20192%20192Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
      }
    }

    .paginationjs-next {
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2244%22%20height%3D%2244%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22%231466c6%22%20d%3D%22M464%20256c0-114.87-93.13-208-208-208S48%20141.13%2048%20256s93.13%20208%20208%20208s208-93.13%20208-208Zm-224%2080.09L303.58%20272H154v-32h149.58L240%20175.91l22.71-22.54L364.54%20256L262.7%20358.63Z%22%2F%3E%3C%2Fsvg%3E');

      &:hover,
      &:focus {
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2244%22%20height%3D%2244%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%231466c6%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%2232%22%20d%3D%22M262.62%20336L342%20256l-79.38-80m68.35%2080H170%22%2F%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%231466c6%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%2232%22%20d%3D%22M256%20448c106%200%20192-86%20192-192S362%2064%20256%2064S64%20150%2064%20256s86%20192%20192%20192Z%22%2F%3E%3C%2Fsvg%3E');
      }
    }

  }

}
