@use '../global' as *;

.bl_ui_module {
  :root {
    /*===============================================
        ①liquid design
        ===============================================*/
    --liquid-lgDesignRatio: calc(#{$lg-design-width} / 10);
    --liquid-mdDesignRatio: calc(#{$md-design-width} / 10);
    --liquid-smDesignRatio: calc(#{$sm-design-width} / 10);
    --liquid-htmlroot: calc(
      calc(var(--vw, 1vw) * 100) / var(--liquid-lgDesignRatio)
    );
    --liquid-bodyroot: 1.6rem;

    @include mq(md) {
      --liquid-htmlroot: calc(
        calc(var(--vw, 1vw) * 100) / var(--liquid-mdDesignRatio)
      );
    }

    @include mq(sm) {
      --liquid-htmlroot: calc(
        calc(var(--vw, 1vw) * 100) / var(--liquid-smDesignRatio)
      );
    }

    /*===============================================
        ②solid design
        ===============================================*/
    --solid-root: 16px;

    /*===============================================
        ③mobilefirst design
        ===============================================*/
    --mobile-DesignWidth: #{$mobile-design-width};
    --mobile-ContentsWidth: #{$mobile-design-width};
    --mobile-ContentsRatio: var(--mobile-ContentsWidth) /
      var(--mobile-DesignWidth);
    --mobile-root: min(
      16px * var(--mobile-ContentsRatio),
      16 * 100 / var(--mobile-DesignWidth) * 1vw
    );
  }

  /*===============================================
    ボタン周り
    ===============================================*/
  a,
  button {
    border: none;
    color: $color-key;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    pointer-events: auto;
    z-index: 100;
    transition: 0s;
    display: block;
    @include transition;

    > * {
      pointer-events: none;
    }

    &:link,
    &:visited {
      color: inherit;
    }

    &:hover,
    &:active {
      color: inherit;
      cursor: pointer;
    }

    /* タブ移動時 */
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }

    /* マウスクリック時 */
    &:focus:not(:fous-visible) {
      outline: none;
    }

    //スマホのとき一瞬うつるタッチハイライトを消す
    @media (hover: none) {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    img {
      border: none;
    }
  }

  //DOMの読み込みでtransitionを有効にする
  .is_DOMloaded {
    a,
    button {
      @include transition;
      transition-property: background-color, opacity, fill, color, box-shadow,
        transform, border-color;
    }
  }

  /*===============================================
    reset
    ===============================================*/

  * {
    //Safari clamp関数対策
    min-height: 0vw;
  }

  html {
    -webkit-tap-highlight-color: transparent;
    overflow-x: hidden;
  }

  body {
    padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px)
      env(safe-area-inset-bottom, 20px) env(safe-area-inset-left, 20px);
  }

  img {
    border: 0;
    height: auto;
    pointer-events: none;
    vertical-align: middle;
    width: 100%;
  }

  picture {
    display: block;
  }

  iframe {
    width: 100%;
  }

  ul,
  ol,
  li {
    font-size: 0;
    list-style: none;
  }

  form {
    label,
    span,
    input {
      //スマホのとき一瞬うつるタッチハイライトを消す
      @media (hover: none) {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
    }
  }

  /*===============================================
    reCAPTCHAのバッジを非表示にする
    ===============================================*/
  .grecaptcha-badge {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

body {
  background: {
    color: $color-bg;
  }

  color: $color-base;

  // font: {
  //     family: $fontfamily-base;
  //     style: normal;
  //     weight: 600;
  // }

  font: {
    family: $fontfamily-base;
    size: 16px;
    style: normal;
    weight: 600;
  }

  // letter-spacing: $letter-spacing-base;
  // line-height: 1.4;

  margin: 0;

  font-smoothing: antialiased;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-font-smoothing: antialiased;
  -moz-text-size-adjust: 100%;
  -ms-font-smoothing: antialiased;
  -ms-text-size-adjust: 100%;
  -o-font-smoothing: antialiased;
  -o-text-size-adjust: 100%;

  &.active {
    &:before {
      opacity: 1;
      visibility: visible;
    }
  }

  &:before {
    content: '';
    background: {
      color: rgba($color-base, 0.8);
    }
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    @include transition;
  }
}

html {
  //liquid
  @if $designRoot==1 {
    font-size: var(--liquid-htmlroot);

    body {
      font-size: var(--liquid-bodyroot);
    }
  }

  //solid
  @else if $designRoot==2 {
    font-size: var(--solid-root);
  }

  //mobile
  @else if $designRoot==3 {
    font-size: var(--mobile-root);
  }
}

:root {
  /*===============================================
    headerHeight
    ===============================================*/
  --headerHeight: #{100px};

  @include mq(lg) {
    --headerHeight: #{$spacing-5};
  }
}
