@use "../../global"as *;

/*===============================================
button
===============================================*/

.bl_ui_module {
        
    .el_btn {
        color: $color-bg;
        // background: $color-grad;
        font: {
            size: 16px;
            weight: 700;
        }
        position: relative;
        max-width: 300px;
        width: 100%;
        height: 52px;
        // line-height: 52px;
        padding: 16px 24px 16px 0;
        border-radius: 52px;
        overflow: hidden;
        
        &:before {
            content: '';
            background: $color-grad;
            @include absolute(0, auto, auto, 0);
            width: 100%;
            height: 100%;
            @include transition;
        }

        @include hover {
            color: $color-key;
            box-shadow: 3px 4px 12px rgba($color-key, .3);

            &:before {
                background: $color-bg;
            }

            .el_arrow_icon__wh {
                background: {
                    color: $color-key;
                }
            }

            .el_arrow_icon {

                svg {

                    .cls-1 {
                        fill: $color-bg;
                    }
                }
            }

            .el_btn_txt {
                color: $color-key;
            }
        }

        @include mq(md){
            font: {
                size: 15px;
            }
            max-width: 280px;
            margin-top: 28px;
        }

        &:link,
        &:visited,
        &:hover,
        &:active {
            color: $color-bg;
        }

        &Wrapper {
            margin-top: $spacing-4;

            @include mq(md){
                margin-top: 28px;
            }
        }

        &__wh {
            color: $color-key;

            @include hover {
                color: $color-bg;

                &:before {
                    background: $color-grad;
                }

                .el_arrow_icon {
                    background: $color-bg;
                }

                .el_btn_txt {
                    color: $color-bg;
                }

                .el_arrow_icon__externalLink {

                    .cls-1 {
                        fill: $color-key;
                        stroke: $color-key;
                    }
                }
            }

            &:before {
                background: $color-bg;
            }

            .el_btn_txt {
                color: $color-key;
            }
        }

        &_txt {
            color: $color-bg;
            position: relative;
            z-index: 1;
        }

        .el_arrow_icon {
            @include absolute(50%, 8px, auto, auto);
            transform: translate(0, -50%);
            margin: 0;
        }
    }
}