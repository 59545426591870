@use "../../global"as *;

.bl_ui_module {

    /*===============================================
    logo
    ===============================================*/
    .el_logo {
        width: 100%;
        flex-shrink: 0;
        z-index: 9998;
        
        a {

            @include hover {
                opacity: .7;
            }
        }
    }
}