
.ss-embed--style-alignment {
    &-center {
        .cmp-embed {
            display:flex;
            justify-content: center;
        }
    }
    &-right {
        .cmp-embed {
            display:flex;
            justify-content: flex-end;
        }
    }
    &-left {
        .cmp-embed {
            display:flex;
            justify-content: flex-start;
        }
    }
}

.ss-embed--style-pc-only {
    display: block;

    @include sm {
        display: none;
    }
}

.ss-embed--style-sp-only {
    display: none;

    @include sm {
        display: block;
    }
}