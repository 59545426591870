@use '../global' as *;

/*===============================================
header
===============================================*/
.ly_header {
  background-color: $color-bg;
  position: fixed;
  width: 100%;
  letter-spacing: 0;
  z-index: 9999;

  @include mq(lg) {
    height: $spacing-5;
  }

  &Info_inner {
    gap: 24px;
    justify-content: flex-end;
  }

  &Nav_inner {
    align-items: center;
    // height: var(--headerHeight);
    display: flex;
    justify-content: space-between;

    @include mq(lg) {
      height: 100%;
    }
  }

  &Nav_wrapper {
    align-items: center;
    display: flex;

    @include mq(lg) {
      display: none;
    }
  }

  .bl_headerNav_sm,
  .bl_headerNav_sm_bg,
  .bl_hamburgerBtn {
    display: none;

    @include mq(lg) {
      display: block;
    }
  }

  .ly_headerInfo {
    font: {
      size: 12px;
    }
    height: $spacing-4half;
    line-height: $spacing-4half;
  }

  .ly_headerNav {
    @include mq(lg) {
      height: 100%;
    }
  }

  /*===============================================
    header logo
    ===============================================*/
  .bl_headerLogo {
    max-width: 180px;
    z-index: 9999;

    @include mq(md) {
      max-width: 120px;
    }
  }

  /*===============================================
    header nav
    ===============================================*/
  .bl_headerNav {
    &_listWrapper {
    }

    &_list {
      font: {
        size: 14px;
      }
      // position: relative;
      margin-left: 36px;
      display: inline-block;

      &:first-child {
        margin: 0;
      }

      @include hover {
        .bl_pcNav_dropdown {
          opacity: 1;
          visibility: visible;
        }
      }

      .bl_headerNav_btn {
        cursor: pointer;
        height: $spacing-8;
        line-height: $spacing-8;
        // position: relative;
        // height: var(--headerHeight);
        // padding: 0 $spacing-1;
        // color: $color-base;
        // font-weight: bold;
        // font-size: $fontsize-1;
        // line-height: var(--headerHeight);
        // letter-spacing: 0;

        // &:after {
        //     @include transition;
        //     @include cubic-bezier01;
        //     @include absolute(auto, auto, -13px, 0);

        //     width: 0;
        //     height: 4px;
        //     background-color: $color-key;
        //     content: "";
        //     transition-property: width;
        // }

        // @include hover {
        //     color: $color-key;

        //     &:after {
        //         width: 100%;
        //     }
        // }

        &_icon {
          width: 12.48px;
          height: 7px;
          margin-left: 8px;
          flex-shrink: 0;
        }
      }

      .bl_pcNav_dropdown {
        // position: fixed;
        position: absolute;
        // top: var(--headerHeight);
        // left: 50%;
        left: 0;
        width: 100%;
        // height: calc(100vh - 140px);
        height: auto;
        padding: $spacing-3 0;
        z-index: 1000;
        opacity: 0;
        visibility: hidden;
        overflow-x: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        // transform: translate(-50%, 0);
        @include transition;

        @media screen and (max-height: 840px) {
          max-height: calc(100vh - 224px);
        }

        &_heading {
          border-bottom: 1px solid #dedfe0;
          position: relative;
          padding-bottom: 28px;
          margin-bottom: $spacing-2;

          &:before {
            content: '';
            background: {
              color: $color-key;
            }
            @include absolute(auto, auto, -1px, 0);
            width: $spacing-mini;
            height: 1px;
          }

          .el_headingArrow {
            margin-bottom: 0;
          }
        }

        &_nav {
          gap: $spacing-2 20px;
          align-items: flex-start;

          &_item {
            width: 100%;

            &__half {
              width: calc(50% - 10px);
            }
          }
        }
      }
    }
  }

  /*===============================================
    ハンバーガーメニュー
    ===============================================*/
  .bl_headerNav_sm {
    position: fixed;
    left: 0;
    z-index: 1;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;
    overflow-y: auto;
    // pointer-events: none;
    //閉じてる状態でfocusが当たらないようにする
    visibility: hidden;

    //スクロールバーを削除
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &_inner {
      border-top: 1px solid #dedfe0;
      width: 100%;
      padding: 0 0 $spacing-6;
      margin-top: $spacing-5;
    }

    &_listWrapper {
      width: 100%;
      padding: $spacing-2 20px 0;
      margin: auto;
      text-align: left;

      .bl_headerNav_sm_list {
        margin-left: 0;
        display: block;
        line-height: 1;

        &:not(:first-child) {
          margin-top: $spacing-1;
        }

        .bl_headerNav_sm_btn {
          display: inline-block;
          font-weight: bold;
          font-size: $fontsize-6;

          @include mq(sm) {
            font-size: $fontsize-3;
          }

          @include hover {
            color: blue;
          }
        }
      }
    }

    &_top {
      padding: $spacing-2 20px;

      .el_search {
        width: 240px;
        height: $spacing-4;
        line-height: 38px;
        margin-top: $spacing-1;
      }
    }

    &_bottom {
      width: 100%;
      padding: 0 20px;
      margin-top: $spacing-3;

      &_SNS {
        display: flex;
        justify-content: center;
        align-items: center;

        li {
          margin: 0 $spacing-1;

          a {
            display: block;
            position: relative;
            width: 48px;
            height: 48px;
            border: 2px solid #fff;
            border-radius: 50%;

            svg {
              @include absolute(0, 0, 0, 0);

              margin: auto;

              path {
                @include transition;
              }
            }
          }
        }
      }

      &_logo {
        display: block;
        width: 160px;
        margin: 7.8vh auto 4.9vh;
      }

      &_Copyright {
        display: block;
        color: rgba(255, 255, 255, 0.6);
        font-size: $fontsize-1;
        letter-spacing: 0;
        text-align: center;
      }

      &_menu {
        font: {
          size: 12px;
        }
        margin-bottom: $spacing-mini;

        &:last-child {
          margin-bottom: 0;
        }

        &Wrapper {
          padding-bottom: $spacing-6;
        }

        a {
          color: rgba($color-base, 0.7);
          display: inline;
        }
      }
    }

    //ハンバーガーメニューの背景
    &_bg {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: calc(100vh + 8px);
      min-height: calc(100vh + 8px);
      pointer-events: none;
    }
  }

  /*===============================================
    ハンバーガーメニュー アニメーション初期値
    ===============================================*/
  .bl_headerNav_sm {
    top: -100%;

    &_listWrapper {
      .bl_headerNav_sm_list {
        opacity: 0;
        transform: translateX(16px);

        .bl_headerNav_sm_btn {
          color: $color-base;
        }
      }
    }

    &_top,
    &_bottom {
      opacity: 0;
      transform: translateY(8px);
    }

    //ハンバーガーメニューの背景
    &_bg {
      opacity: 1;
      transform: translateY(-100%);
      background: {
        color: $color-grey;
      }
    }
  }

  /*===============================================
    header hamburger
    ===============================================*/
  .bl_hamburgerBtn {
    @include absolute(0, 0, 0, auto);

    background: {
      color: #1a5192;
    }
    z-index: 9999;
    width: 60px;
    height: $spacing-5;
    line-height: 1;
    margin: auto;
    cursor: pointer;

    pointer-events: auto;

    .bl_hamburgerBtn_trigger {
      @include absolute(50%, auto, auto, 50%);
      transform: translate(-50%, -50%);
      width: $spacing-3;
      height: $spacing-2;

      pointer-events: auto;

      .bl_hamburgerBtn_bar {
        position: relative;
        width: 100%;
        height: 10px;

        span {
          --duration: 0.6s;
          transition: background-color var(--duration),
            transform var(--duration), opacity var(--duration);
          transform: translate3d(0, 0, 0);
          position: absolute;
          right: 0;
          height: 2px;
          margin: auto;
          background: {
            color: $color-bg;
          }
          width: 100%;

          &:nth-of-type(1) {
            top: 0;
          }

          &:nth-of-type(2) {
            bottom: 0;
          }
        }
      }

      .bl_hamburgerBtn_txt {
        color: $color-bg;
        font: {
          size: 12px;
        }
        letter-spacing: 0.05em;
      }
    }

    &.is_smMenu_open {
      .bl_hamburgerBtn_bar {
        span {
          &:nth-of-type(1) {
            transform: translate3d(0, 4px, 0) rotate(-24deg);
          }

          &:nth-of-type(2) {
            transform: translate3d(0, -4px, 0) rotate(24deg);
          }
        }
      }
    }
  }
}

.ly_top_header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10000;

  .ly_headerInfo {
    font-size: 12px;
    height: 48px;
    line-height: 48px;
  }
}

.ly_header {
  top: 48px;

  &.ly_header_mobile {
    display: none;
  }

  @include mq(lg) {
    top: 0;

    &.ly_header_mobile {
      display: block;

      .el_lang_switch {
        &:hover {
          .el_lang_switch_dropdown {
            opacity: 1;
            top: 48px;
            visibility: visible;
          }
        }
      }
    }
  }
}
