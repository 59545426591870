@use "../global"as *;

/*===============================================
common
===============================================*/
//コンテンツの高さが画面に満たない場合フッターを画面下部に固定する

.ly_wrapper {
    flex-direction: column;
    display: flex;
    min-height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;

    .ly_main {
        flex: 1;
    }
}

// .ly_main {
//     padding-top: var(--headerHeight);

//     @include mq(lg){
//         padding-top: var(--headerHeight);
//     }
// }

.bl_ui_module {

    .ly_inner {
        max-width: 1216px;
        width: 100%;
        height: 100%;
        padding: 0 24px;
        margin: 0 auto;
        // box-sizing: content-box;

        &__header {
            padding: 0 $spacing-6;

            @include mq(lg){
                padding: 0 $spacing-2;
            }
        }

        &__dropdown {
            max-width: 1280px;
        }

        &__mv {
            position: relative;
            max-width: 1120px;
            // box-sizing: border-box;
        }

        &__footer {
            max-width: 1320px;
        }
    }
}