@use "../../global"as *;

/*===============================================
brandList
===============================================*/

.bl_ui_module {
    .el_brand_list {
        font: {
            size: 13px;
        }
        margin-bottom: 8px;

        @include mq(lg){
            width: calc(50% - $spacing-half);
            margin-bottom: 0;
        }

        @include mq(md){
            font: {
                size: 13px;
            }
            width: 100%;
            margin-bottom: 8px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &__header {
            width: calc(24.333% - 16px);
            margin: 0;

            a {
                padding: 24px $spacing-1 !important;

                @include mq(md){
                    padding: 20px 20px;
                }

                @include hover {
                    opacity: .5;
                }
            }
        }

        &Wrapper {

            &__header {
                gap: $spacing-3;


            }

            @include mq(lg){
                display: flex;
                flex-wrap: wrap;
                gap: $spacing-half;
            }

            @include mq(md){
                display: block;
            }
        }

        a {
            padding: 10px $spacing-1;

            @include mq(md){
                padding: 10px 20px;
            }

            @include hover {
                opacity: .5;
            }
        }

        &_img {

            &Wrapper {
                max-width: 164px;
                height: $spacing-3;
                margin: 0 auto;

                img {
                    width: auto;
                    height: auto;
                }
            }

        }

        &_ttl {
            margin-top: $spacing-2;
            display: block;
        }

    }
}
