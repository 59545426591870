.l-contents {
  max-width: 83.3333333333%;
  margin: 0 auto;
  padding: 60px 0 100px;
}
@media screen and (max-width: 750px) {
  .l-contents {
    max-width: 89.3333333333%;
    padding-top: 90px;
    padding-bottom: 50px;
  }
}
.l-contents__title {
  margin-bottom: 40px;
  text-align: center;
}
.l-contents__title__main {
  margin-bottom: 20px;
}
@media screen and (max-width: 750px) {
  .l-contents__title__main {
    margin-bottom: 8px;
  }
}
.l-contents__wrap {
  margin-bottom: 75px;
}
.l-contents__wrap:last-child {
  margin-bottom: 0;
}
.l-contents__map {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 45%;
  flex: 0 0 45%;
}
@media screen and (max-width: 750px) {
  .l-contents__map {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
}
.l-contents__data {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 51.6666666667%;
  flex: 0 0 51.6666666667%;
}
@media screen and (max-width: 750px) {
  .l-contents__data {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
}

.c-link {
  @include dealersNormalText();
  word-wrap: break-word;
  text-decoration: underline;
}

.c-text {
  @include dealersNormalText();
  word-wrap: break-word;
}

.c-title {
  font-size: 4rem;
  font-weight: 700;
}
@media screen and (max-width: 750px) {
  .c-title {
    font-size: 2.5rem;
  }
}

.cmp-text.p {
  font-size: 0.9rem;
}
.c-select {
  position: relative;
}
.c-select__box {
  width: 100%;
  height: 40px;
  padding-right: 48px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  color: #000;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}
@media screen and (max-width: 750px) {
  .c-select__box {
    height: 36px;
  }
}

.c-select__box:disabled {
  display: flex;
  align-items: center;
  cursor: text;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.disabled-box {
  @include selectDefaultDisabled();
  color: rgb(109, 109, 109);
  // font-size: 1.2rem;
}
.c-select__box--dis {
  color: rgba(0, 0, 0, 0);
}
.c-select__arrow {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  position: absolute;
  right: 10px;
  bottom: 10px;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media screen and (max-width: 750px) {
  .c-select__arrow {
    width: 25px;
    height: 25px;
    bottom: 12px;
  }
}
.c-select__arrow.is-close {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.c-select-default {
  @include selectDefault();
  width: 100%;
  height: 100%;
  -webkit-transition: color 0.35s ease;
  transition: color 0.35s ease;
  color: #000;
  cursor: pointer;
  position: absolute;
  top: 5px;
  left: 0;
}

@media screen and (min-width: 751px) {
  .c-select-default:hover {
    color: $color-topcon;
  }
}

.c-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.c-flex--nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

// fix for search filters spacing
.c-flex--cg {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  column-gap: 20px;
}

.c-flex--jc-sb {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.c-flex--jc-ctr {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.c-flex--jc-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.c-flex--ai-st {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.c-flex--ai-ctr {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.c-flex--ai-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
@media screen and (max-width: 1024px) {
  .c-flex--colDrop-tab {
    display: block;
  }
}
.c-flex__col {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

@media screen and (max-width: 1024px) {
  .p-map {
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 750px) {
  .p-map {
    margin-bottom: 40px;
  }
}
.p-map__image {
  text-align: center;
  margin-bottom: 40px;
  padding-top: 118.519%;
  position: relative;
}
@media screen and (max-width: 750px) {
  .p-map__image {
    margin-bottom: 30px;
    padding-top: 118.519%;
  }
}

.p-map__button {
  max-width: 220px;
  margin: 0 auto;
  margin-bottom: 30px;
}
@media screen and (max-width: 750px) {
  .p-map__button {
    max-width: 140px;
  }
}
.p-map__arrow {
  width: 29px;
  height: auto;
  display: block;
  position: absolute;
  top: 50.8620689655%;
  left: 21.1206896552%;
  z-index: 2;
}
@media screen and (max-width: 750px) {
  .p-map__arrow {
    width: 18px;
  }
}

.p-selector {
  margin-bottom: 58px;
}
@media screen and (max-width: 750px) {
  .p-selector {
    margin-bottom: 30px;
  }
  .p-selector.c-flex {
    display: block;
  }
}
.p-selector__box {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc((100% - 60px) / 4);
  flex: 0 0 calc((100% - 60px) / 4);
}
@media screen and (max-width: 750px) {
  .p-selector__box {
    padding-top: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .p-selector__box .c-select {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
}
.p-selector__label {
  font: $font-title-h6;
  margin-bottom: 5px;
  display: block;
  color: #000;
  line-height: normal;
}
@media screen and (max-width: 750px) {
  .p-selector__label {
    width: 80px;
    height: 36px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80px;
    flex: 0 0 80px;
    margin-bottom: 0;
    padding-top: 4px;
    font: $font-title-h6-sm;
    border-bottom: 1px solid #ccc;
  }
}
.p-selector__panel {
  width: 100%;
}
@media screen and (max-width: 750px) {
  .p-selector__panel {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 80px);
    flex: 0 0 calc(100% - 80px);
    margin-left: auto;
  }
}
.p-selector__panel__item {
  @include selectDefault();
  width: 100%;
  padding-top: 16px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  -webkit-transition: background-color 0.35s ease, color 0.35s ease;
  transition: background-color 0.35s ease, color 0.35s ease;
  color: #000;
  cursor: pointer;
}
.p-selector__panel__item:hover {
  background: $color-topcon;
  color: #fff;
}

@media screen and (max-width: 750px) {
  .p-card-box.c-flex {
    display: block;
  }
}
.p-card-box__item {
  width: 100%;
  margin-right: 10px;
  margin-bottom: 30px;
  padding: 20px 0 40px;
  border-bottom: 1px solid $color-border-grey;
}

.p-card-box__cont {
  margin-bottom: 25px;

  padding: 1.5rem 2rem;
  background-color: $color-background-lighter;
  border: 1px solid $color-border-grey;
}
@media screen and (max-width: 750px) {
  .p-card-box__item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    padding-top: 18px;
    padding-right: 20px;
    padding-bottom: 30px;
  }
  .p-card-box__item:last-child {
    margin-bottom: 0;
  }
}
.p-card-box__item:nth-of-type(3n) {
  margin-right: 0;
}

.p-card-box__label {
  margin: 1rem 1.25rem 2.6rem 1.25rem;
  margin-bottom: 26px;
  font: $font-dealers-label;
  // font-size: 20px;
  position: relative;
}

@media screen and (max-width: 750px) {
  .p-card-box__label {
    margin-bottom: 18px;
    font: $font-title-h5-sm;
  }
}
.p-card-box__label::after,
.p-card-box__label::before {
  height: 1px;
  display: block;
  content: "";
}
.p-card-box__label::after {
  max-width: 340px;
  width: 100%;
  margin-top: 26px;
  background: #ccc;
}
@media screen and (max-width: 750px) {
  .p-card-box__label::after {
    margin-top: 10px;
  }
}
.p-card-box__label::before {
  width: 20px;
  background: $color-topcon;
  position: absolute;
  left: 0;
  bottom: 0;
}
.p-card-box__title {
  margin-bottom: 1rem;
  font: $font-title-h6;
}
@media screen and (max-width: 750px) {
  .p-card-box__title {
    font: $font-title-h6-sm;
  }
}

#map {
  position: relative !important;
  width: inherit;
}

.c-button {
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 25px;
  border: 1px solid $color-topcon;
  background: #fff;
  color: $color-topcon;
  font: $font-button-large;
  -webkit-transition: color 0.35s ease, background 0.35s ease;
  transition: color 0.35s ease, background 0.35s ease;
}
@media screen and (max-width: 750px) {
  .c-button {
    height: 35px;
  }
}
.c-button:link,
.c-button:visited {
  color: $color-topcon;
}
@media screen and (min-width: 751px) {
  .c-button:hover {
    background: $color-topcon;
    color: #fff;
  }
}

.category-list-ul {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  // gap: 0.2rem;
  // margin-top: 0.5rem;
  li {
    border: none;
    background: $color-dealers-category-tag;
    color: $color-background-lighter;
    @include ListTeaserTagStyle();
  }
}

.industry-list-ul {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  li {
    border: none;
    background: $color-dealers-industry-tag;
    color: $color-background-lighter;
    @include ListTeaserTagStyle();
  }
}

.brand-list-ul {
  display: flex;

  li {
    font-family: "Fieldwork";
    font-weight: 500;
    margin: 0.4rem 0.4rem 0.4rem 0;
  }
}
.brand-topcon {
  color: $color-dealers-category-tag;
}

.brand-sokkia {
  color: $color-sokkia-brand;
}

.brand-xyeez {
  color: $color-xyeez-brand;
}


.ss-dealers-sales-company {
  .cmp-accordion__panel {
    padding: 2.5rem 0 2.5rem 0;
  }

  .cmp-container {
    .cmp-container {
      background-color: $color-background-lighter;
      border: 1px solid $color-border-grey;
      min-height: 170px;
      padding: 1.5rem 2rem;
      margin-bottom: 25px;
    }
  }

  #dealers-sales-company--container {
    > .aem-Grid {
      margin: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 25px;

      &::before {
        content: none;
      }
    }
  }
}
#dealer-list--res-in {
  .dealers-list-li {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 25px;
  }

  .p-card-box__label {
    margin: 0 1.5rem 1.5rem 0;
  }
}

@media screen and (max-width: 1024px) {
  #dealers-sales-company--container {
    > .aem-Grid {
      grid-template-columns: 1fr !important;
    }
  }

  #dealer-list--res-in {
    .dealers-list-li {
      grid-template-columns: 1fr;
    }
  }
}
