// Interviewee
.ss-teaser--style-interviewee {
    .cmp-teaser {
        position: relative;
        font: $font-teaser;
        display: flex;
        flex-direction: column-reverse;

        width: rem(384);

        @include sm {
            width: rem(324);
        }
    }

    .cmp-teaser__image {
        position: relative;
        top: rem(0);
        margin-bottom: rem(20);
        margin-left: rem(0);
        margin-right: rem(0);

        @include sm {
            top: rem(0);
            margin-bottom: rem(16);
            margin-left: rem(0);
            margin-right: rem(0);
        }

        & .cmp-image {
            width: 100%;
        }
        & img {

            @include sm {
            }
        }
    }

    .cmp-teaser__content {
        border-style: none none none solid;
        border-color: $color-topcon;
        border-width: rem(6);
    
        @include sm {
        }
    }

    .cmp-teaser__pretitle {
        height: rem(13);
        margin-top: rem(0);
        margin-bottom: rem(8);
        margin-left: rem(20);
        font: $font-teaser-interviewee-pretitle;
        color: $color-teaser-interviewee-pretitle;
        text-align: left;

        @include sm {
            height: rem(12);
            margin-top: rem(0);
            margin-bottom: rem(4);
            margin-left: rem(12);
            font: $font-teaser-sm-interviewee-pretitle;
        }
    }

    .cmp-teaser__title {
        height: rem(31);
        margin-top: rem(0);
        margin-left: rem(20);
        font: $font-teaser-interviewee-title;
        color: $color-teaser-interviewee-title;
        text-align: left;

        @include sm {
            height: rem(25);
            margin-top: rem(0);
            margin-left: rem(12);
            font: $font-teaser-sm-interviewee-title;
        }
    }
  
    .cmp-teaser__title-link {
        pointer-events: none;
        color: $color-teaser-interviewee-title;
    }

    .cmp-teaser__description {
        display: none;
    }  

    .cmp-teaser__action-container {
        display: none;
    }

    .cmp-teaser__tag-container {
        display: none;
    }

}
