// At Work
.ss-teaser--style-at-work {
  .cmp-teaser {
    position: relative;
    font: $font-teaser;

    width: rem(384);
    height: rem(421);

    @include sm {
      width: rem(324);
      height: rem(388);
    }
  }

  .cmp-teaser__image {
    position: relative;
    top: rem(24);
    width: rem(336);
    height: rem(189);
    margin-bottom: rem(11);
    margin-left: rem(24);
    margin-right: rem(24);

    @include sm {
      top: rem(20);
      width: rem(284);
      height: rem(160);
      margin-bottom: rem(10);
      margin-left: rem(20);
      margin-right: rem(20);
    }

    & .cmp-image {
    }
    & img {
      width: rem(336);
      height: rem(189);

      @include sm {
        width: rem(284);
        height: rem(160);
      }
    }
  }

  .cmp-teaser__content {
    position: absolute;
    top: rem(224);
    width: rem(324);

    @include sm {
      top: rem(190);
      width: rem(260);
    }
  }

  .cmp-teaser__pretitle {
    display: none;
  }

  .cmp-teaser__title {
    position: absolute;
    top: rem(24);
    height: rem(44);
    width: rem(312);
    margin-top: rem(0);
    margin-bottom: rem(12);
    margin-left: rem(36);
    margin-right: rem(36);
    font: $font-teaser-at-work-title;
    text-align: left;

    @include sm {
      top: rem(24);
      height: rem(43);
      width: rem(260);
      margin-top: rem(0);
      margin-bottom: rem(12);
      margin-left: rem(32);
      margin-right: rem(32);
      font: $font-teaser-sm-at-work-title;
    }
  }

  .cmp-teaser__title-link {
    pointer-events: none;
    color: $color-teaser-at-work-title;
  }

  .cmp-teaser__description {
    display: none;
  }

  .cmp-teaser__action-container {
    position: absolute;
    top: rem(125);
    width: rem(312);
    height: rem(48);
    margin-top: rem(0);
    margin-left: rem(36);
    margin-right: rem(36);

    @include sm {
      top: rem(126);
      width: rem(260);
      margin-top: rem(0);
      margin-left: rem(32);
      margin-right: rem(32);
    }
  }

  .cmp-teaser__action-link {
    width: rem(312);
    height: rem(48);
    padding-top: rem(17);
    padding-bottom: rem(17);
    padding-left: rem(121);
    padding-right: rem(121);
    background: transparent
      linear-gradient(
        270deg,
        $color-button-blue 0%,
        $color-button-blue-linear 100%
      )
      0% 0% no-repeat padding-box;
    font: $font-teaser-button-title;
    color: $color-teaser-button-title-white;

    &:hover,
    &:focus {
      padding-top: rem(16);
      padding-bottom: rem(16);
      padding-left: rem(120);
      padding-right: rem(120);

      background: $color-button-white 0% 0% no-repeat padding-box;
      box-shadow: rem(3) rem(4) rem(12) $color-button-box-shadow;
      border: rem(1) solid $color-button-blue;
      color: $color-button-blue;
    }

    @include sm {
      width: rem(260);
      padding-left: rem(95);
      padding-right: rem(95);

      &:hover,
      &:focus {
        padding-top: rem(16);
        padding-bottom: rem(16);
        padding-left: rem(94);
        padding-right: rem(94);
      }
    }

    .cmp-teaser__action-link-after {
      content: url(./resources/images/element/icon/arrow-r-wh.svg);
      position: absolute;
      top: initial;
      left: rem(272);
      width: rem(20);
      height: rem(20);
      text-align: center;

      @include sm {
        left: rem(220);
      }
    }

    &:hover .cmp-teaser__action-link-after,
    &:focus .cmp-teaser__action-link-after {
      content: url(./resources/images/element/icon/arrow-r-blue.svg);
      width: rem(20);
      height: rem(20);

      @include sm {
        left: rem(220);
      }
    }
  }

  .cmp-teaser__tag-container {
    position: absolute;
    top: rem(74);
    width: rem(312);
    margin-left: rem(36);

    @include sm {
      top: rem(73);
      width: rem(260);
      margin-left: rem(32);
    }
    display: flex;

    .cmp-common__tag-tpa__brand {
      &--topcon,
      &--sokkia,
      &--clearedge3d,
      &--xyeez {
        position: absolute;
        top: rem(-74);
        height: rem(18);
        font: $font-teaser-at-work-brand-tag;

        @include sm {
          top: rem(-73);
          height: rem(18);
          font: $font-teaser-at-work-brand-tag;
        }
      }
    }
    .cmp-common__tag-tpa__industry {
      &--survey,
      &--construction,
      &--bim,
      &--agriculture {
        height: rem(23);
        margin-right: rem(4);
        padding-left: rem(12);
        padding-right: rem(12);
        font: $font-teaser-at-work-industry-tag;
        border-radius: rem(100);
        text-align: center;
        display: inline-flex;
        align-items: center;

        @include sm {
          height: rem(23);
          font: $font-teaser-at-work-industry-tag;
        }
      }
    }

    .cmp-common__tag-tpa__product-category {
      &--total-stations,
      &--gnss,
      &--machine-control,
      &--field-controllers,
      &--software,
      &--lasers,
      &--3d-scanners,
      &--levels-theodolites,
      &--agriculture-equipments,
      &--raku,
      &--monitoring,
      &--survey-equipments,
      &--related-products,
      &--infrastructure-maintenance {
        height: rem(23);
        margin-right: rem(4);
        padding-left: rem(12);
        padding-right: rem(12);
        font: $font-teaser-at-work-category-tag;
        border-radius: rem(100);
        text-align: center;
        display: inline-flex;
        align-items: center;

        @include sm {
          height: rem(23);
          font: $font-teaser-at-work-category-tag;
        }
      }
    }

    .cmp-common__tag-tpa__news {
      &--product-info,
      &--campaign,
      &--information,
      &--atwork,
      &--news-release,
      &--column {
        display: none;
      }
    }

    .cmp-common__tag-tpa__event {
      &--online,
      &--physical,
      &--free,
      &--paid {
        display: none;
      }
    }
  }
}
