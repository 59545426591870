.ss-text-shadow-black {
  .cmp-teaser__title,
  .cmp-teaser__pretitle,
  .cmp-teaser__description {
    text-shadow: 3px 3px 8px #272a33;
  }
}

.ss-text-shadow-white {
  .cmp-teaser__title,
  .cmp-teaser__pretitle,
  .cmp-teaser__description {
    text-shadow: 0px 0px 3px #ffffff, 0px 0px 3px #ffffff, 0px 0px 3px #ffffff,
      0px 0px 3px #ffffff;
  }
}
