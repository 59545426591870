.ss-list--style-product-list {
    .cmp-list__item-date {
        display: none;
      }
    .cmp-list {
        display: grid;
        // 位置固定の場合には、、下記を使用する
        // grid-template-columns: rem(280) rem(280) rem(280) rem(280);
        grid-template-columns: repeat(auto-fill, minmax(rem(280), 1fr));
        grid-gap: rem(24) rem(32);

        @include sm {
            // grid-template-columns: rem(324);
            // responsive 対応する場合には、下記を使用する
            grid-template-columns: repeat(auto-fill, minmax(rem(324), 1fr));
            grid-gap: rem(24);
        }
    }

    .cmp-list__item {
        display:flex;
        justify-content: center;
        align-items: flex-start;
        .cmp-teaser {
            display: flex;
            flex-direction: column;
            font: $font-teaser;
            padding-left: rem(20);
            padding-right: rem(20);
            padding-top: rem(20);
            padding-bottom: rem(32);
            width: rem(280);
            height: auto;

            @include sm {
                width: rem(324);
                height: auto;
                padding-left: rem(33);
                padding-right: rem(32);
            }
        }

        .cmp-teaser__image {
            width: rem(240);
            height: rem(180);
            margin-bottom: rem(15);

            @include sm {
                width: rem(259);
                height: rem(194);
                margin-bottom: rem(7);
            }

            & .cmp-image {
                pointer-events: none;
            }
            & img {
                pointer-events: none;
            }
        }

        .cmp-teaser__content {
            .cmp-teaser__categorytag-container{
                @include ListTeaserTagsShowStyle(true);
            }
            .cmp-teaser__pretitle {
                display: none;
            }
            .second-line-header {
                font: $font-new-product-list-teaser-series-title !important;
              }

            .cmp-teaser__title {
                margin-bottom: rem(24);
                font: $font-teaser-product-title;
                color: $color-teaser-product-title;
                text-align: left;
                // @include limitOneLine(); // this is for ellipsis

                @include sm {
                    margin-bottom: rem(17);
                    font: $font-new-product-list-teaser-series-title;

                }
            }
            .cmp-teaser__title-link {
                pointer-events: none;
                color: $color-teaser-product-title;
            }

            .cmp-teaser__description {
                display: none;
            }


            .cmp-teaser__tag-container {
                flex-wrap: wrap;
                margin-bottom: rem(22);
                display: flex;
                @include ListTeaserTagsShowStyle(false, true, true);
            }
        }

        .cmp-teaser__link {
            pointer-events: auto;

            // Button
            $text-margin-left: 18px;
            .cmp-teaser__detail {
                display:flex;
                flex:1;
                justify-content: center;
                align-items: center;
                position: relative;
                .cmp-teaser__detail-text {
                    display: flex;
                    flex:auto;

                    justify-content: center;
                    align-items: center;
                    letter-spacing: 0px;
                    color: $color-text;
                    opacity: 1;

                }
                .cmp-teaser__detail-icon {
                    display: flex;

                }

            }
            @include ListTeaserButtonStyle(squre, 240px, 48px, $font-teaser, 21px, $text-margin-left);
            @include sm {
                @include ListTeaserButtonStyle(squre, 259px, 48px, $font-teaser, 21px, $text-margin-left);
            }
            @include ListTeaserButtonColor(styleBlue, squre, button);
        }
    }

}
