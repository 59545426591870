// todo: refactor because repetitive

.ss--teaser {
  &-text-top-left {
    .cmp-teaser {
      position: relative;
    }

    .cmp-teaser__image {
    }
    .cmp-teaser__content {
      @media (max-width: $screen-tb) {
        padding: 2rem;
      }
      padding: 5.5%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: absolute;
      height: 100%;
      width: 100%;

      .cmp-teaser__title {
        order: 1;
        margin-bottom: 1rem;
        font-size: rem(34);

        @media (max-width: $screen-lg) {
          font-size: rem(20);
          margin-bottom: 0.5rem;
        }

        @media (max-width: $screen-tb) {
          font-size: rem(18);
        }
        @media (max-width: $screen-sm) {
          font-size: rem(16);
        }
      }

      .cmp-teaser__pretitle {
        order: 2;
      }
      .cmp-teaser__description {
        padding: 0.5rem;
        order: 3;

        @include limitLine(12);
        @media (max-width: $screen-lg) {
          -webkit-line-clamp: 10;
        }
        @media (max-width: $screen-tb) {
          -webkit-line-clamp: 6;
        }

        @media (max-width: $screen-sm) {
          -webkit-line-clamp: 2;
        }

        p {
          text-overflow: ellipsis;
          font-size: rem(18);

          @media (max-width: $screen-lg) {
            font-size: rem(14);
          }

          @media (max-width: $screen-sm) {
            font-size: rem(12);
            display: inline;
          }
        }
      }

      .cmp-teaser__action-container {
        order: 4;
        margin-top: 1rem;

        .cmp-teaser__action-link {
          padding-bottom: 0.1rem;
          border-bottom: 2px solid $color-background-lighter;
        }
      }
    }
  }

  &-text-top-center {
    .cmp-teaser {
      position: relative;
    }

    .cmp-teaser__image {
    }
    .cmp-teaser__content {
      @media (max-width: $screen-tb) {
        padding: 2rem;
      }
      padding: 5.5%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      width: 100%;

      height: 100%;

      .cmp-teaser__title {
        order: 1;
        margin-bottom: 1rem;
        font-size: rem(34);

        @media (max-width: $screen-lg) {
          font-size: rem(20);
          margin-bottom: 0.5rem;
        }

        @media (max-width: $screen-tb) {
          font-size: rem(18);
        }
        @media (max-width: $screen-sm) {
          font-size: rem(16);
        }
      }

      .cmp-teaser__pretitle {
        order: 2;
      }
      .cmp-teaser__description {
        padding: 0.5rem;
        order: 3;

        @include limitLine(12);

        @media (max-width: $screen-lg) {
          -webkit-line-clamp: 10;
        }
        @media (max-width: $screen-tb) {
          -webkit-line-clamp: 6;
        }

        @media (max-width: $screen-sm) {
          -webkit-line-clamp: 2;
        }

        p {
          text-overflow: ellipsis;
          font-size: rem(18);

          @media (max-width: $screen-lg) {
            font-size: rem(14);
          }

          @media (max-width: $screen-sm) {
            font-size: rem(12);
            display: inline;
          }
        }
      }
      .cmp-teaser__action-container {
        order: 4;
        margin-top: 1rem;

        .cmp-teaser__action-link {
          padding-bottom: 0.1rem;
          border-bottom: 2px solid $color-background-lighter;
        }
      }
    }
  }

  &-text-top-right {
    .cmp-teaser {
      position: relative;
    }

    .cmp-teaser__image {
    }
    .cmp-teaser__content {
      @media (max-width: $screen-tb) {
        padding: 2rem;
      }
      padding: 5.5%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: absolute;
      height: 100%;
      width: 100%;

      .cmp-teaser__title {
        order: 1;
        margin-bottom: 1rem;
        font-size: rem(34);

        @media (max-width: $screen-lg) {
          font-size: rem(20);
          margin-bottom: 0.5rem;
        }

        @media (max-width: $screen-tb) {
          font-size: rem(18);
        }
        @media (max-width: $screen-sm) {
          font-size: rem(16);
        }
      }
      .cmp-teaser__pretitle {
        order: 2;
      }
      .cmp-teaser__description {
        padding: 0.5rem;
        order: 3;

        @include limitLine(12);

        @media (max-width: $screen-lg) {
          -webkit-line-clamp: 10;
        }
        @media (max-width: $screen-tb) {
          -webkit-line-clamp: 6;
        }

        @media (max-width: $screen-sm) {
          -webkit-line-clamp: 2;
        }

        p {
          text-overflow: ellipsis;
          font-size: rem(18);

          @media (max-width: $screen-lg) {
            font-size: rem(14);
          }

          @media (max-width: $screen-sm) {
            font-size: rem(12);
            display: inline;
          }
        }
      }

      .cmp-teaser__action-container {
        order: 4;
        margin-top: 1rem;

        .cmp-teaser__action-link {
          padding-bottom: 0.1rem;
          border-bottom: 2px solid $color-background-lighter;
        }
      }
    }
  }
  &-text-bottom-center {
    .cmp-teaser {
      position: relative;
    }

    .cmp-teaser__image {
    }
    .cmp-teaser__content {
      @media (max-width: $screen-tb) {
        padding: 2rem;
      }
      padding: 5.5%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      height: 100%;
      width: 100%;
      .cmp-teaser__title {
        order: 1;
        margin-bottom: 1rem;
        font-size: rem(34);

        @media (max-width: $screen-lg) {
          font-size: rem(20);
          margin-bottom: 0.5rem;
        }

        @media (max-width: $screen-tb) {
          font-size: rem(18);
        }
        @media (max-width: $screen-sm) {
          font-size: rem(16);
        }
      }

      .cmp-teaser__pretitle {
        order: 2;
      }
      .cmp-teaser__description {
        padding: 0.5rem;
        order: 3;

        @include limitLine(12);

        @media (max-width: $screen-lg) {
          -webkit-line-clamp: 10;
        }
        @media (max-width: $screen-tb) {
          -webkit-line-clamp: 6;
        }

        @media (max-width: $screen-sm) {
          -webkit-line-clamp: 2;
        }

        p {
          text-overflow: ellipsis;
          font-size: rem(18);

          @media (max-width: $screen-lg) {
            font-size: rem(14);
          }

          @media (max-width: $screen-sm) {
            font-size: rem(12);
            display: inline;
          }
        }
      }

      .cmp-teaser__action-container {
        order: 4;
        margin-top: 1rem;

        .cmp-teaser__action-link {
          padding-bottom: 0.1rem;
          border-bottom: 2px solid $color-background-lighter;
        }
      }
    }
  }

  &-text-bottom-left {
    .cmp-teaser {
      position: relative;
    }

    .cmp-teaser__image {
    }
    .cmp-teaser__content {
      @media (max-width: $screen-tb) {
        padding: 2rem;
      }
      padding: 5.5%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: flex-end;
      position: absolute;
      height: 100%;
      width: 100%;

      .cmp-teaser__title {
        order: 1;
        margin-bottom: 1rem;
        font-size: rem(34);

        @media (max-width: $screen-lg) {
          font-size: rem(20);
          margin-bottom: 0.5rem;
        }

        @media (max-width: $screen-tb) {
          font-size: rem(18);
        }
        @media (max-width: $screen-sm) {
          font-size: rem(16);
        }
      }
      .cmp-teaser__pretitle {
        order: 2;
      }
      .cmp-teaser__description {
        padding: 0.5rem;
        order: 3;
        @include limitLine(12);

        @media (max-width: $screen-lg) {
          -webkit-line-clamp: 10;
        }
        @media (max-width: $screen-tb) {
          -webkit-line-clamp: 6;
        }

        @media (max-width: $screen-sm) {
          -webkit-line-clamp: 2;
        }

        p {
          text-overflow: ellipsis;
          font-size: rem(18);

          @media (max-width: $screen-lg) {
            font-size: rem(14);
          }

          @media (max-width: $screen-sm) {
            font-size: rem(12);
            display: inline;
          }
        }
      }
      .cmp-teaser__action-container {
        order: 4;
        margin-top: 1rem;

        .cmp-teaser__action-link {
          padding-bottom: 0.1rem;
          border-bottom: 2px solid $color-background-lighter;
        }
      }
    }
  }

  &-text-bottom-right {
    .cmp-teaser {
      position: relative;
    }

    .cmp-teaser__image {
    }
    .cmp-teaser__content {
      @media (max-width: $screen-tb) {
        padding: 2rem 1rem;
      }
      padding: 5.5%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: end;
      justify-content: flex-end;
      position: absolute;
      height: 100%;
      width: 100%;
      .cmp-teaser__title {
        order: 1;
        margin-bottom: 1rem;
        font-size: rem(34);

        @media (max-width: $screen-lg) {
          font-size: rem(20);
          margin-bottom: 0.5rem;
        }

        @media (max-width: $screen-tb) {
          font-size: rem(18);
        }
        @media (max-width: $screen-sm) {
          font-size: rem(16);
        }
      }
      .cmp-teaser__pretitle {
        order: 2;
      }
      .cmp-teaser__description {
        padding: 0.5rem;
        order: 3;
        @include limitLine(12);

        @media (max-width: $screen-lg) {
          -webkit-line-clamp: 10;
        }
        @media (max-width: $screen-tb) {
          -webkit-line-clamp: 6;
        }

        @media (max-width: $screen-sm) {
          -webkit-line-clamp: 2;
        }

        p {
          text-overflow: ellipsis;
          font-size: rem(18);

          @media (max-width: $screen-lg) {
            font-size: rem(14);
          }

          @media (max-width: $screen-sm) {
            font-size: rem(12);
            display: inline;
          }
        }
      }

      .cmp-teaser__action-container {
        order: 4;
        margin-top: 1rem;

        .cmp-teaser__action-link {
          padding-bottom: 0.1rem;
          border-bottom: 2px solid $color-background-lighter;
        }
      }
    }
  }

  &-text-left-center {
    .cmp-teaser {
      position: relative;
    }

    .cmp-teaser__image {
    }
    .cmp-teaser__content {
      @media (max-width: $screen-tb) {
        padding: 2rem 1rem;
      }
      padding: 5.5%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      position: absolute;
      height: 100%;
      width: 100%;
      .cmp-teaser__title {
        order: 1;
        margin-bottom: 1rem;
        font-size: rem(34);

        @media (max-width: $screen-lg) {
          font-size: rem(20);
          margin-bottom: 0.5rem;
        }

        @media (max-width: $screen-tb) {
          font-size: rem(18);
        }
        @media (max-width: $screen-sm) {
          font-size: rem(16);
        }
      }
      .cmp-teaser__pretitle {
        order: 2;
      }
      .cmp-teaser__description {
        padding: 0.5rem;
        order: 3;
        @include limitLine(12);

        @media (max-width: $screen-lg) {
          -webkit-line-clamp: 10;
        }
        @media (max-width: $screen-tb) {
          -webkit-line-clamp: 6;
        }

        @media (max-width: $screen-sm) {
          -webkit-line-clamp: 2;
        }

        p {
          text-overflow: ellipsis;
          font-size: rem(18);

          @media (max-width: $screen-lg) {
            font-size: rem(14);
          }

          @media (max-width: $screen-sm) {
            font-size: rem(12);
            display: inline;
          }
        }
      }

      .cmp-teaser__action-container {
        order: 4;
        margin-top: 1rem;

        .cmp-teaser__action-link {
          padding-bottom: 0.1rem;
          border-bottom: 2px solid $color-background-lighter;
        }
      }
    }
  }

  &-text-center-center {
    .cmp-teaser {
      position: relative;
    }

    .cmp-teaser__image {
    }
    .cmp-teaser__content {
      @media (max-width: $screen-tb) {
        padding: 2rem 1rem;
      }
      padding: 5.5%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      height: 100%;
      width: 100%;
      .cmp-teaser__title {
        order: 1;
        margin-bottom: 1rem;
        font-size: rem(34);

        @media (max-width: $screen-lg) {
          font-size: rem(20);
          margin-bottom: 0.5rem;
        }

        @media (max-width: $screen-tb) {
          font-size: rem(18);
        }
        @media (max-width: $screen-sm) {
          font-size: rem(16);
        }
      }
      .cmp-teaser__pretitle {
        order: 2;
      }
      .cmp-teaser__description {
        padding: 0.5rem;
        order: 3;
        @include limitLine(12);

        @media (max-width: $screen-lg) {
          -webkit-line-clamp: 10;
        }
        @media (max-width: $screen-tb) {
          -webkit-line-clamp: 6;
        }

        @media (max-width: $screen-sm) {
          -webkit-line-clamp: 2;
        }

        p {
          text-overflow: ellipsis;
          font-size: rem(18);

          @media (max-width: $screen-lg) {
            font-size: rem(14);
          }

          @media (max-width: $screen-sm) {
            font-size: rem(12);
            display: inline;
          }
        }
      }

      .cmp-teaser__action-container {
        order: 4;
        margin-top: 1rem;

        .cmp-teaser__action-link {
          padding-bottom: 0.1rem;
          border-bottom: 2px solid $color-background-lighter;
        }
      }
    }
  }

  &-text-center-right {
    .cmp-teaser {
      position: relative;
    }

    .cmp-teaser__image {
    }
    .cmp-teaser__content {
      @media (max-width: $screen-tb) {
        padding: 2rem 1rem;
      }
      padding: 5.5%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: end;
      justify-content: center;
      position: absolute;

      height: 100%;
      width: 100%;
      .cmp-teaser__title {
        order: 1;
        margin-bottom: 1rem;
        font-size: rem(34);

        @media (max-width: $screen-lg) {
          font-size: rem(20);
          margin-bottom: 0.5rem;
        }

        @media (max-width: $screen-tb) {
          font-size: rem(18);
        }
        @media (max-width: $screen-sm) {
          font-size: rem(16);
        }
      }
      .cmp-teaser__pretitle {
        order: 2;
      }
      .cmp-teaser__description {
        padding: 0.5rem;
        order: 3;
        @include limitLine(12);

        @media (max-width: $screen-lg) {
          -webkit-line-clamp: 10;
        }
        @media (max-width: $screen-tb) {
          -webkit-line-clamp: 6;
        }

        @media (max-width: $screen-sm) {
          -webkit-line-clamp: 2;
        }

        p {
          text-overflow: ellipsis;
          font-size: rem(18);

          @media (max-width: $screen-lg) {
            font-size: rem(14);
          }

          @media (max-width: $screen-sm) {
            font-size: rem(12);
            display: inline;
          }
        }
      }

      .cmp-teaser__action-container {
        order: 4;
        margin-top: 1rem;

        .cmp-teaser__action-link {
          padding-bottom: 0.1rem;
          border-bottom: 2px solid $color-background-lighter;
        }
      }
    }
  }
}
