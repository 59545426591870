.cmp-separator {
}
.cmp-separator__horizontal-rule {
}

.separator {
    .cmp-separator__horizontal-rule {
        border-bottom: rem(1) solid $color-border;
        position: relative;
        margin: margin(5);
        display: block;
    

        &:before {
            content: '';
            position: absolute;
            bottom: rem(-1);
            left: 50%;
            width: rem(12);
            height: rem(1);
            transform: translate(-50%, 0);
        }
    }

    &.ss-separator--style-grey-with-blue-accent {
        .cmp-separator__horizontal-rule {
            &:before {
                background: {
                    color: $color-topcon;
                }
            }
        }
    }

    &.ss-separator--style-white {
        .cmp-separator__horizontal-rule {
            border-bottom: rem(1) solid rgba($color-background-lighter, .15);
        }
    }

    &.ss-separator--style-white-with-white-accent {
        .cmp-separator__horizontal-rule {
            border-bottom: rem(1) solid rgba($color-background-lighter, .15);

            &:before {
                background: {
                    color: $color-background-lighter;
                }
            }
        }
    }

}
