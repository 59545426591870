// Brand Tag
// TOPCON
// SOKKIA
// CLEAR EDGE 3D
// XYEEZ
.cmp-common__tag-tpa__brand {
  &--topcon {
    color: $color-topcon;
  }
  &--sokkia {
    color: $color-sokkia;
  }
  &--clearedge3d {
    color: $color-clearegde3d;
  }

  &--xyeez {
    color: $color-xyeez-brand;
  }
}
// Industry Tag
// 測量
// 土木
// 建築
// 農業
.cmp-common__tag-tpa__industry {
  &--survey,
  &--construction,
  &--bim,
  &--agriculture {
    color: $color-industry-tag-text;
    background: $color-industry-tag-background 0% 0% no-repeat padding-box;
  }
}

// Product Category Tag
// トータルステーション
// GNSS
// マシンコントロール
// フィールドコントローラー／データコレクタ
// ソフトウェア／アプリケーション
// レーザー
// ３Dスキャナー
// セオドライト・レベル
// 精密農業
// 建築向け高精度位置出しシステム
// モニタリングシステム
// 測定機器
// 関連製品
.cmp-common__tag-tpa__product-category {
  &--total-stations,
  &--gnss,
  &--machine-control,
  &--field-controllers,
  &--software,
  &--lasers,
  &--3d-scanners,
  &--levels-theodolites,
  &--agriculture-equipments,
  &--raku,
  &--monitoring,
  &--survey-equipments,
  &--related-products,
  &--infrastructure-maintenance {
    color: $color-product-category-tag-text;
    background: $color-product-category-tag-background 0% 0% no-repeat
      padding-box;
  }
}

.cmp-common__tag-tpa__column-category {
  &--health,
  &--qualifications,
  &--sustainability,
  &--staff,
  &--disaster,
  &--goods,
  &--event-report {
    color: $color-foreground;
    border: 1px solid $color-news-tag-border;
    background-color: $color-background-lighter;
  }
}

.cmp-common__tag-tpa__column-theme {
  &--work,
  &--time,
  &--dx,
  &--anketos {
    color: $color-topcon;
  }
}

// Topics Tag
// 製品／サービス
// キャンペーン
// お知らせ
// 活用事例
// ニュースリリース
.cmp-common__tag-tpa__news {
  &--product-info {
    color: $color-product-category-tag-text;
    background: $color-product-category-tag-background 0% 0% no-repeat
      padding-box;
  }
  &--campaign,
  &--information,
  &--atwork {
    color: $color-topics-tag-text;
    background: $color-topics-tag-background 0% 0% no-repeat padding-box;
  }
  &--news-release {
    color: $color-industry-tag-text;
    background: $color-industry-tag-background 0% 0% no-repeat padding-box;
  }

  &--column {
    color: $color-foreground;
    border: 1px solid $color-news-tag-border;
    background-color: $color-background-lighter;
  }
}

// Event Tag
// 告知
// 建設ICT
// 測量・設計
// 3D計測
// 展示会
// オンライン
// 来場型
// 無料
// 有料
.cmp-common__tag-tpa__event {
  &--online,
  &--physical,
  &--free,
  &--paid {
    color: $color-event-tag-text;
    background: $color-event-tag-background 0% 0% no-repeat padding-box;
  }
}
