@mixin xl {
    @media screen and (min-width: ($screen-xl)) {
        @content;
    }
}

@mixin lg {
    @media screen and (min-width: ($screen-lg)) {
        @content;
    }
}

@mixin tb {
    @media screen and (min-width: ($screen-tb)) {
        @content;
    }
}

@mixin sm {
    @media screen and (max-width: ($screen-sm)) {
        @content;
    }
}
@mixin not_sm {
    @media screen and (min-width: ($screen-tb)) {
        @content;
    }
}
@mixin mid {
    @media screen and (max-width: ($screen-lg)) {
        @content;
    }
}