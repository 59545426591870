@use "sass:math";

@mixin buttonIconStyle($iconSize, $iconMargin) {
  $icon_content: $iconSize;
  width: $icon_content;
  height: $icon_content;
  @if $iconMargin != null {
    margin-right: rem($iconMargin);
  }
  border-radius: math.div($icon_content, 2);
}



@mixin responsiveButtonStyle(
  $type,
  $width,
  $height,
  $font,
  $iconSize,
  $iconMargin,
  $textMargin: 32
) {
  .cmp-button {
    $icon_content: $iconSize;
    $icon_margin: $iconMargin;

    @if ($width != null) {
      max-width: rem($width);
      width: 100%;
    }
    height: rem($height);
    .cmp-button__text {
      font: $font;
      width: 0;
      @if $type == sqaure {
        justify-content: initial;
        margin-left: rem($textMargin);
      } @else {
        justify-content: center;
        margin-left: math.div($icon_content + $icon_margin, 2);
      }
    }
    .cmp-button__icon {
      @include buttonIconStyle($icon_content, $iconMargin);
    }
    @if $type == round {
      border-radius: rem(100);
    }
  }
}

@mixin buttonColor($colorType: styleBlue, $shape: round, $shadowType: button) {
  .cmp-button {
    @if $colorType == styleBlue {
      background: transparent
        linear-gradient(
          270deg,
          $color-button-blue 0%,
          $color-button-blue-linear 100%
        )
        0%
        0%
        no-repeat
        padding-box;
    } @else {
      background: $color-button-white 0% 0% no-repeat padding-box;
    }
    @if $shadowType == button {
      box-shadow: rem(3) rem(4) rem(12) $color-button-box-shadow;
    } @else {
      box-shadow: initial;
      border: initial;
    }
    &:hover,
    &:focus {
      background: $color-button-white 0% 0% no-repeat padding-box;
      .cmp-button__text {
        color: $color-button-blue;
      }
      .cmp-button__icon {
        background-color: $color-button-blue;
      }

      @include hoverIconStyle($colorType);
      @if $shadowType == button {
        border: rem(1) solid $color-button-blue;
        box-shadow: rem(3) rem(4) rem(12) $color-button-box-shadow;
      }
    }

    .cmp-button__text {
      @if $colorType == styleBlue {
        color: $color-button-white;
      } @else {
        color: $color-button-blue;
      }
    }

    .cmp-button__icon {
      @if $colorType == styleBlue {
        background-color: $color-button-white;
      } @else {
        background-color: $color-button-blue;
      }
    }

    @include iconStyle($colorType);
  }
}

@mixin buttonTextStyle($colorType) {
  .cmp-button {
    background: initial;
    box-shadow: initial;
    &:hover,
    &:focus {
      background: initial;
      box-shadow: initial;
      border: initial;
      .cmp-button__text {
        @if $colorType == styleBlue {
          color: $color-button-blue;
        } @else {
          color: $color-button-white;
        }
      }
      .cmp-button__icon {
        @if $colorType == styleBlue {
          background-color: $color-button-blue;
        } @else {
          background-color: $color-button-white;
        }
        @include buttonIconStyle(21px, null);
      }
      @include iconStyle($colorType);
    }

    .cmp-button__text {
      @if $colorType == styleBlue {
        color: $color-button-blue;
      } @else {
        color: $color-button-white;
      }
      flex: none;
      font: $font-button-small;
    }
    .cmp-button__icon {
      margin-left: margin(1);
      @if $colorType == styleBlue {
        background-color: $color-button-blue;
      } @else {
        background-color: $color-button-white;
      }
      @include buttonIconStyle(21px, null);
    }
  }
}

// Style Create
.cmp-button {
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  position: relative;
  .cmp-button__text {
    display: flex;
    flex: auto;
    justify-content: center;
    align-items: center;
    letter-spacing: 0px;
    color: $color-text;
    opacity: 1;
  }
  .cmp-button__icon {
    display: flex;
  }
  &:hover,
  &:focus {
    .cmp-button__text {
      text-decoration: underline;
    }
  }
}

.ss-button--style-oval-large {
  @include responsiveButtonStyle(round, 448px, 56px, $font-button-large, 36px, 10px);
  @include sm {
    @include responsiveButtonStyle(round, 312px, 52px, $font-button-middle, 36px, 8px);
  }
}
.ss-button--style-oval-mid {
  @include responsiveButtonStyle(round, 312px, 52px, $font-button-large, 36px, 8px);
}
.ss-button--style-oval-small {
  @include responsiveButtonStyle(round, 188px, 40px, $font-button-small, 28px, 7px);
}

.ss-button--style-square-large {
  @include responsiveButtonStyle(square, 800px, 80px, $font-button-large, 40px, 20px);
  @include sm {
    @include responsiveButtonStyle(square, 312px, 69px, $font-button-middle, 40px, 20px);
  }
}
.ss-button--style-square-mid {
  @include responsiveButtonStyle(square, 384px, 80px, $font-button-large, 40px, 20px);
  @include sm {
    @include responsiveButtonStyle(square, 312px, 69px, $font-button-middle, 40px, 20px);
  }
}
.ss-button--style-square-small {
  @include responsiveButtonStyle(square, 188px, 80px, $font-button-small, 28px, 20px);
}

.ss-button--style-text-button {
  &.ss-button--style-white {
    @include buttonTextStyle(styleWhite);
  }
  &.ss-button--style-blue {
    @include buttonTextStyle(styleBlue);
  }
}

.ss-button--style-blue {
  @include buttonColor(styleBlue, round, button);
}
.ss-button--style-white {
  @include buttonColor(styleWhite, round, button);
}

.ss-button--style-alignment {
  &-center {
    .cmp-button {
      margin: 0 auto !important;
      justify-content: center;
    }
  }
  &-right {
    .cmp-button {
      margin-left: auto !important;
      justify-content: flex-start;
    }
  }
  &-left {
    .cmp-button {
      justify-content: flex-end;
    }
  }
}
