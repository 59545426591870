.ss-text--size-large{
    .cmp-text {
      @include paragraphLargeText();
      letter-spacing: 0.24px;
    }
  }

.ss-text--size-xlarge{
  .cmp-text {
    @include paragraphXLargeText();
    letter-spacing: 0.72px;
  }
}