@use "../../global"as *;

.bl_ui_module {

    /*===============================================
    Product Info List
    ===============================================*/
    .bl_productInfo_list {
        width: 50%;

        @include mq(lg){
            width: 100%;
            margin-bottom: 40px;
        }

        @include mq(md){
            margin-bottom: 28px;
        }

        &Wrapper {
            justify-content: center;
            gap: 32px;

            @include mq(lg){
                display: block;
            }
        }

        &__half {
            width: 25%;

            @include mq(lg){
                width: 100%;
            }
        }
    }
}