.ss--teaser-text-color-white {
  .cmp-teaser__pretitle,
  .cmp-teaser__title,
  .cmp-teaser__title-link,
  .cmp-teaser__description, a {
    color: $color-background-lighter !important;
  }
}


.ss--teaser-text-color-black {
  .cmp-teaser__pretitle,
  .cmp-teaser__title,
  .cmp-teaser__title-link,
  .cmp-teaser__description, a {
    color: $color-foreground !important;
  }
}
