@use "../../global"as *;

/*===============================================
Inquiry
===============================================*/

.bl_ui_module {

    .el_inquiry {

        &_cont {
            padding: $spacing-7 0 $spacing-6;

            &_heading {
                margin-bottom: $spacing-3;

                &_ttl {
                    @include clamp_fs(28px, $sm-design-width, $lg-design-width, 36px);
                    margin: 0;
                }
            }

            &_info {

                &_label {
                    @include clamp_fs($fontsize-2, $sm-design-width, $lg-design-width, 20px);
                    margin-bottom: $spacing-1;
                    display: block;
                }

                &_txt {
                    @include clamp_fs(13px, $sm-design-width, $lg-design-width, 14px);
                    font: {
                        weight: 600;
                    }
                    display: block;
                }

                &_tel {
                    max-width: 864px;
                    padding: $spacing-2 $spacing-2 $spacing-4;
                    margin: 0 auto;

                    @include mq(md){
                        padding: $spacing-2 $spacing-mini;
                    }
                }

                &_item {
                    position: relative;
                    width: 50%;
                    padding: 10px;

                    &:before {
                        content: '';
                        background-color: #DEDFE0;
                        @include absolute(50%, 0, auto, auto);
                        transform: translate(0, -50%);
                        width: 1px;
                        height: $spacing-9;

                        @include mq(md){
                            height: $spacing-7;
                        }
                    }

                    &:last-child {

                        @include mq(md){
                            margin-bottom: 0;
                        }

                        &:before {
                            content: none;
                        }
                    }

                    &Wrapper {
                        // gap: 190px;
                        margin-top: $spacing-3;

                        @include mq(md){
                            margin-top: $spacing-1;
                        }
                    }

                    &_img {

                        &Wrapper {
                            // max-width: 156px;
                            // max-height: 32px;
                            margin: 0 auto;

                            @include mq(md){
                                // max-width: 104px;
                                // max-height: 20px;
                            }

                            img {
                                width: auto;
                                height: 32px;

                                @include mq(md){
                                    width: auto;
                                    height: $spacing-2;
                                }
                            }

                            .xyeez-img {
                                width: auto;
                                height: 50px;

                                @include mq(md){
                                    width: auto;
                                    height: 30px;
                                }
                            }

                        }
                    }


                    .xyeez {
                            color: $color-key;
                            @include clamp_fs(20px, $sm-design-width, $lg-design-width, $fontsize-3);
                            font: {
                                weight: 600;
                            }
                            margin-top: 4px;

                            @include mq(md){
                                margin-top: 2px;
                            }

                            @include hover {
                                opacity: .7;
                            }
                    }
                    a {
                        color: $color-key;
                        @include clamp_fs(20px, $sm-design-width, $lg-design-width, $fontsize-3);
                        font: {
                            weight: 600;
                        }
                        margin-top: $spacing-2;

                        @include mq(md){
                            margin-top: 8px;
                        }

                        @include hover {
                            opacity: .7;
                        }
                    }
                }
            }
        }

        &_list {
            background: {
                image: url(resources/images/element/inquiry-pattern.png);
                position: center right;
                repeat: no-repeat;
                size: contain;
            }
            @include clamp_fs(18px, $sm-design-width, $lg-design-width, 20px);
            width: 33.3333%;
            padding: $spacing-6 $spacing-2;

            @include mq(lg){
                width: 100%;
                padding: $spacing-4 $spacing-2;
            }

            &Wrapper {
                padding: 0;

                @include mq(lg){
                    display: block;
                }
            }

            &__docs {
                background: {
                    color: #377DCF;
                }
            }

            &__quote {
                background: {
                    color: $color-key;
                }
            }

            &__session {
                background: {
                    color: #114B90;
                }
            }

            &_txt {
                position: relative;
                display: block;

                &:before {
                    content: '';
                    background: {
                        color: $color-bg;
                    }
                    @include absolute(auto, auto, -$spacing-1, 50%);
                    transform: translate(-50%, 0);
                    width: $spacing-mini;
                    height: 2px;
                }
            }

            .el_btn {
                margin: 0 auto;

                &Wrapper {
                    margin: $spacing-4 auto 0;
                }
            }
        }
    }
}
