@use "../global"as *;

/*===============================================
top
===============================================*/
.pg_top {
    &_mv {
        // background: {
        //     color: $color-key;
        // }
        position: relative;
        // min-height: 558px;
        // height: calc(80vh - var(--headerHeight));
        // height: 558px;
        // padding-right: 255px;
        margin-top: var(--headerHeight);

        @include mq(lg){
            margin-top: var(--headerHeight);
        }

        @include mq(md){
            // height: 480px;
        }
    }
}

/*===============================================
mv slider
===============================================*/

.bl_ui_module{
        
    .bl_top_mvSlider {
        position: relative;
        height: 100%;

        // &:before {
        //     content: '';
        //     @include webpBg(mv-bg-pattern, j, layout/top);
        //     background: {
        //         position: center center;
        //         repeat: no-repeat;
        //         size: cover;
        //     }
        //     @include absolute(0, -180px, auto, auto);
        //     width: 100%;
        //     height: 100%;
        //     z-index: 1;
        // }

        li {
            // @include webpBg(mv-bg-dot, p, layout/top);
            // background: {
            //     position: center center;
            //     repeat: repeat;
            //     size: 6px;
            // }
            height: 100%;

            &:before, &:after {
                content: '';
                width: 100%;
                height: 100%;
            }

            &:before {
                background: linear-gradient(to right, rgba($color-key, 0) 0%, rgba($color-key, 0) 81.28%, $color-key 100%);
                @include absolute(0, auto, auto, 0);
                z-index: -1;

                @include mq(lg){
                    background: linear-gradient(to bottom, rgba($color-key, 0) 0%, rgba($color-key, 0) 81.28%, $color-key 100%);
                }
            }

            &:after {
                @include webpBg(mv-bg-pattern, p, layout/top);
                background: {
                    position: center center;
                    repeat: no-repeat;
                    size: cover;
                }
                @include absolute(0, 0, auto, auto);
                width: 832px;
                z-index: -1;

                @include mq(lg){
                    content: none;
                }
            }

            &.slide_npPattern {

                &:before, &:after {
                    content: none;
                }

                .mv_imgWrapper {

                    &:before {
                        content: none;
                    }
                }
            }

            // &.slide_survey {

            //     .mv_img {
            //         background: {
            //             image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-survey.webp);
            //         }

            //         .no-webp & {
            //             background: {
            //                 image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-survey.jpg);
            //             }
            //         }

            //         @include mq(md){
            //             background: {
            //                 image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-survey-sp.webp);
            //             }

            //             .no-webp & {
            //                 background: {
            //                     image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-survey-sp.jpg);
            //                 }
            //             }
            //         }
            //     }

            //     &__en {
            //         .mv_img {
            //             background: {
            //                 image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-survey-en.webp);
            //             }

            //             .no-webp & {
            //                 background: {
            //                     image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-survey-en.jpg);
            //                 }
            //             }

            //             @include mq(md){
            //                 background: {
            //                     image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-survey-sp-en.webp);
            //                 }

            //                 .no-webp & {
            //                     background: {
            //                         image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-survey-sp-en.jpg);
            //                     }
            //                 }
            //             }
            //         }
            //     }
            // }

            // &.slide_const {

            //     .mv_img {
            //         background: {
            //             image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-const.webp);
            //         }

            //         .no-webp & {
            //             background: {
            //                 image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-const.jpg);
            //             }
            //         }

            //         @include mq(md){
            //             background: {
            //                 image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-const-sp.webp);
            //             }

            //             .no-webp & {
            //                 background: {
            //                     image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-const-sp.jpg);
            //                 }
            //             }
            //         }
            //     }

            //     &__en {
            //         .mv_img {
            //             background: {
            //                 image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-const-en.webp);
            //             }

            //             .no-webp & {
            //                 background: {
            //                     image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-const-en.jpg);
            //                 }
            //             }

            //             @include mq(md){
            //                 background: {
            //                     image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-const-sp-en.webp);
            //                 }

            //                 .no-webp & {
            //                     background: {
            //                         image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-const-sp-en.jpg);
            //                     }
            //                 }
            //             }
            //         }
            //     }
            // }

            // &.slide_bim {

            //     .mv_img {
            //         background: {
            //             image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-bim.webp);
            //         }

            //         .no-webp & {
            //             background: {
            //                 image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-bim.jpg);
            //             }
            //         }

            //         @include mq(md){
            //             background: {
            //                 image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-bim-sp.webp);
            //             }

            //             .no-webp & {
            //                 background: {
            //                     image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-bim-sp.jpg);
            //                 }
            //             }
            //         }
            //     }

            //     &__en {
            //         .mv_img {
            //             background: {
            //                 image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-bim-en.webp);
            //             }

            //             .no-webp & {
            //                 background: {
            //                     image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-bim-en.jpg);
            //                 }
            //             }

            //             @include mq(md){
            //                 background: {
            //                     image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-bim-sp-en.webp);
            //                 }

            //                 .no-webp & {
            //                     background: {
            //                         image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-bim-sp-en.jpg);
            //                     }
            //                 }
            //             }
            //         }
            //         }
            // }

            // &.slide_agriculture {

            //     .mv_img {
            //         background: {
            //             image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-agriculture.webp);
            //         }

            //         .no-webp & {
            //             background: {
            //                 image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-agriculture.jpg);
            //             }
            //         }

            //         @include mq(md){
            //             background: {
            //                 image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-agriculture-sp.webp);
            //             }

            //             .no-webp & {
            //                 background: {
            //                     image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-agriculture-sp.jpg);
            //                 }
            //             }
            //         }
            //     }

            //     &__en {
            //         .mv_img {
            //             background: {
            //                 image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-agriculture-en.webp);
            //             }

            //             .no-webp & {
            //                 background: {
            //                     image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-agriculture-en.jpg);
            //                 }
            //             }

            //             @include mq(md){
            //                 background: {
            //                     image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-agriculture-sp-en.webp);
            //                 }

            //                 .no-webp & {
            //                     background: {
            //                         image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-agriculture-sp-en.jpg);
            //                     }
            //                 }
            //             }
            //         }
            //         }
            // }

            // &.slide_info {

            //     .mv_img {
            //         background: {
            //             image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-info.webp);
            //         }

            //         .no-webp & {
            //             background: {
            //                 image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-info.jpg);
            //             }
            //         }

            //         @include mq(md){
            //             background: {
            //                 image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-info-sp.webp);
            //             }

            //             .no-webp & {
            //                 background: {
            //                     image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-info-sp.jpg);
            //                 }
            //             }
            //         }
            //     }

            //     &__en {
            //         .mv_img {
            //             background: {
            //                 image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-info-en.webp);
            //             }

            //             .no-webp & {
            //                 background: {
            //                     image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-info-en.jpg);
            //                 }
            //             }

            //             @include mq(md){
            //                 background: {
            //                     image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-info-sp-en.webp);
            //                 }

            //                 .no-webp & {
            //                     background: {
            //                         image: url(https://www.topconpositioning.asia/content/dam/topcon-pa/jp/common/images/layout/top/mv-bg-info-sp-en.jpg);
            //                     }
            //                 }
            //             }
            //         }
            //     }
            // }

            .mv_img {
                background: {
                    position: center center;
                    repeat: no-repeat;
                    size: cover;
                }
                width: 100%;
                height: 100%;

                &.lg_only {

                    @include mq(lg){
                        display: block;
                    }

                    @include mq(md){
                        display: none;
                    }
                }

                &.md_only {

                    @include mq(md){
                        display: block;
                    }
                }

                &Wrapper {
                    // @include absolute(0, auto, auto, 0);
                    position: relative;
                    width: 100%;
                    height: 100%;
                    z-index: -4;
                    @include transition;

                    &:before {
                        content: '';
                        @include webpBg(mv-bg-dot, p, layout/top);
                        background: {
                            position: center center;
                            repeat: repeat;
                            size: 6px;
                        }
                        @include absolute(0, auto, auto, 0);
                        width: 100%;
                        height: 100%;
                    }
                }

                picture {
                    width: 100%;
                    height: 100%;

                    img, source {
                        height: 100%;
                    }
                }
            }

            .ly_inner__mv {
                @include absolute(0, auto, auto, 50%);
                width: 100%;
                height: 100%;
                transform: translate(-50%, 0)
            }

            .mv_body {
                @include absolute(50%, auto, auto, 0);
                transform: translate(0, -50%);

                @include mq(lg){
                    // top: inherit;
                    // bottom: $spacing-10;
                    left: 24px;
                }

                .mv_num {
                    font: {
                        size: 16px;
                    }
                    padding: 2px 6px;

                    @include mq(md){
                        font: {
                            size: 15px;
                        }
                    }

                    span {
                        font: {
                            weight: 600;
                        }
                    }
                }

                .mv_ttl {
                    font: {
                        size: 44px;
                        weight: 700;
                    }
                    margin-top: $spacing-4;
                    text-shadow: 3px 3px 8px $color-base;

                    @include mq(md){
                        font: {
                            size: 28px;
                        }
                        margin-top: $spacing-2;
                    }

                    &_bg {
                        position: relative;
                        display: inline-block;

                        // &:before {
                        //     content: '';
                        //     background: {
                        //         color: $color-bg;
                        //     }
                        //     position: absolute;
                        //     bottom: -5px;
                        //     left: -5px;
                        //     top: -5px;
                        //     right: -5px;
                        //     z-index: -1;
                        // }
                    }
                }
            }
        }

        .splide__pagination {
            @include absolute(auto, auto, 20px, 240px);
            z-index: 999;
            
            @include mq(lg){
                right: 20px;
                justify-content: flex-end;
            }

            li {

                &:before, &:after {
                    content: none;
                }

                button {
                    background: {
                        color: $color-bg;
                    }
                    position: relative;
                    width: $spacing-mini;
                    height: $spacing-mini;
                    margin-right: 8px;
                    border-radius: 50%;
                    @include transition;

                    &:before {
                        content: '';
                        background: {
                            color: $color-key;
                        }
                        @include absolute(50%, auto, auto, 50%);
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        opacity: 0;
                        z-index: 1;
                        transform: translate(-50%, -50%);
                        @include transition;
                    }

                    &.is-active {
                        width: $spacing-1;
                        height: $spacing-1;

                        &:before {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }


    /*===============================================
    mv menu
    ===============================================*/
    .bl_top_mvMenu {
        @include absolute(50%, $spacing-4half, auto, auto);
        transform: translate(0, -50%);

        &_list {
            font: {
                size: 14px;
            }
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }

            &Wrapper {

            }

            a {
                color: $color-bg;
                background: {
                    color: #3B7FCF;
                }
                position: relative;
                width: 320px;
                padding: 8px;
                margin: 0 0 0 auto;

                @include hover {
                    color: $color-base;
                    background: {
                        color: $color-bg;
                    }
                    width: 340px;

                    .bl_top_mvMenu_list_num {
                        color: $color-key;
                    }

                    .el_arrow_icon {
                        background: $color-grad;
                        box-shadow: 3px 4px 12px rgba($color-key, .3);

                        svg {
        
                            .cls-1 {
                                fill: $color-bg;
                            }
                        }
                    }
                }
            }

            &_img {

                &Wrapper {
                    width: 108px;
                }
            }

            &_body {
                padding-left: $spacing-2;
            }

            &_num {
                color: rgba($color-bg, .7);
            }

            &_ttl {
                font: {
                    size: 20px;
                }
                line-height: $leading-x-tight;
                // margin-top: 4px;
            }

            .el_arrow_icon {
                @include absolute(50%, 20px, auto, auto);
                transform: translate(0, -50%);
            }
        }
    }

    /*===============================================
    mv scroll
    ===============================================*/
    .bl_top_mvScroll {
        @include absolute(auto, auto, 0, 2%);

        span {
            display: block;
        }

        &_txt {
            color: $color-bg;
            font: {
                size: 15px;
            }
            margin-left: 4px;
            writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            -webkit-writing-mode: vertical-rl;
        }

        &_bar {
            background: {
                color: $color-bg;
            }
            position: relative;
            width: 2px;
            height: 248px;
            margin: $spacing-mini auto 0;
            overflow: hidden;

            &:before {
                content: '';
                background: {
                    color: $color-key;
                }
                @include absolute(auto, auto, 0, 50%);
                width: 2px;
                height: 20px;
                animation: scrollBar 2s ease infinite;
            }
        }
    }
}