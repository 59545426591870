.ss-text--color-black{
    .cmp-text {
      color: $color-text-black;
      & p > a {
        color: $color-link;
        &:hover,
        &:focus {
          color: rgba($color-link,0.8);
        }
        &.anchor-link{
            &:hover,
            &:focus {
                color: $color-link;
            }
        }
      }
      & a.anchor-link {
        &:hover,
        &:focus {
          color: $color-link;
        }
      }
      ol {
        & > li {
            &::before {
                color:$color-list-mark;
            }
        }
      }
      table {
        th {
            color: $color-text-white;
        }
      }
      ul.anchor-link-list.anchor-link-row-nowrap {
        li {
            a.anchor-link {
                color: $color-link;
                &:hover,
                &:focus {
                    color: rgba($color-link,0.8);
                }
            }
        }
      }
    }
    &.ss-text--style-quote {
        .cmp-text {
            color: $color-text-black;
            background-color:$color-text-quote-normal-background;
            &::before{
                color: $color-text-quote-mark;
            }
        }
    }
}
  
.ss-text--color-white{
    .cmp-text {
        color: $color-text-white;
        & p > a {
            color: $color-text-white;
            &:hover,
            &:focus {
                color: rgba($color-text-white,0.8);
            }
            &.anchor-link{
                color: $color-text-white;
                &:hover,
                &:focus {
                    color: $color-text-white;
                }
            }
        }
        & a.anchor-link {
            &:hover,
            &:focus {
              color: $color-link;
            }
          }
        ol {
            & > li {
                &::before {
                    color:$color-text-white;
                }
            }
        }
        table {
            th {
                color: $color-text-white;
            }
            td {
                color: $color-text-white;
            }
        }
        ul.anchor-link-list.anchor-link-row-nowrap {
            li {
                a.anchor-link {
                    color: $color-text-white;
                    &:hover,
                    &:focus {
                        color: rgba($color-text-white,0.8);
                    }
                }
            }
          }
        ul.anchor-link-list.anchor-link-row-wrap {
            background-color: $color-text-white;
        }
    }
    &.ss-text--style-quote {
        .cmp-text {
            color: $color-text-white;
            background-color:$color-text-quote-white-background;
            &::before{
                color: $color-text-white;
            }
        }
    }
}
.cmp-text {
    .ss-text--color-blue{
        color: $color-text-blue;
    }
}
