.ss-text--style-list{
  .cmp-text {
    @include paragraphListText();
    ul {
      list-style-type: square;
    }
    ol {
      list-style-type: decimal;
    }
    ol, ul {
      padding-left: rem(20);
      & > li {
        line-height: 1.5;
        padding-top: rem(10);
        padding-bottom: rem(10);
        padding-right: rem(10);
        &::marker {
          color: $color-list-mark;
          font: $font-text-list-marker;
        }
      }
    }
  }
}