.cmp-text {
    a.anchor-link {
      @include paragraphAnchorLinkOfBrand();
      display: flex;
      color: $color-text-black;
      text-align: left;
      letter-spacing: rem(0.3);
      align-items: center;
      text-decoration:none; 
      &:hover,
      &:focus {
        // color: $color-link;
        text-decoration: underline; 
      }
      &::after{
        content:'';
        justify-content: center;
        
        background-color:  $color-button-blue;
        flex-shrink: 0;
        transform:rotate(90deg); 
        @include textIconStyle(21.26px, 8px);
        @include iconDefaultStyle($icon-arrow-white);
      }
    }
    ul.anchor-link-list.anchor-link-row-wrap {
      display: flex;
      flex-wrap: wrap;
      align-items:center;
      align-content: center;
      padding: margin(3);
      li {
        margin-top: margin(1);
        margin-bottom: margin(1);
        margin-left: margin(1);
        &:not(:last-of-type) {
          position: relative; /*--positionを追加--*/
          margin-right: 2em;
          &::after {
              content:'';
              position: absolute; /*--positionを追加--*/
              top: 0;
              left: 100%;
              margin: 0 margin(2);
              width: rem(1); /*--縦線の太さ(幅)--*/
              height: 100%;
              background-color: $color-separator-vertical; /*--縦線の色--*/
              vertical-align: middle;
          }
        }
      }
    }
    ul.anchor-link-list.anchor-link-row-equality {
      display: flex;
      flex-flow: row nowrap;
      justify-content:center;
      align-items: center;
      align-content: center;
      
      li {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: rem(253);
        height: rem(80);
        $borderWidth: margin(1) ;
        border: $borderWidth solid $color-text-white;
        background-color:$color-background-lighter;
        a.anchor-link {
          
          @include paragraphAnchorLinkOfProduct();
          text-decoration: none; 
          &:after {
            background-color:  $color-button-blue;
            transform:rotate(90deg); 
            @include textIconStyle(28px, 8px);
            @include iconDefaultStyle($icon-arrow-white);
          }
        }
        &:hover,
        &:focus {
          background-color:$color-link-hover;
          a.anchor-link {
            color: $color-text-white;
            &:after {
              background-color:$color-background-lighter;
              transform:rotate(90deg); 
              @include textIconStyle(28px, 8px);
              @include iconDefaultStyle($icon-arrow-blue);
  
            }
          }
        }
  
        &:not(:first-of-type) {
          position: relative; /*--positionを追加--*/
          &::before {
              content:'';
              position: absolute; /*--positionを追加--*/
              top: 10%;
              right: calc(100% + #{$borderWidth});
              width: rem(1); /*--縦線の太さ(幅)--*/
              height: 80%;
              background-color: $color-separator-vertical; /*--縦線の色--*/
              vertical-align: middle;
          }
        }
      }
      @include sm {
        $box-width: rem(156);
        $box-height: rem(56);
        $box-column-gap: rem(12);
        $box-row-gap: rem(8);
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax($box-width, 1fr));
        grid-column-gap: $box-column-gap;
        grid-row-gap: $box-row-gap;
        justify-items: center;
        li{
          width: $box-width;
          height: $box-height;
          &:not(:first-of-type) {
            &::before {
              width: rem(0); /*--縦線の太さ(幅)--*/
            }
          }
        }
      }
    }
  
    ul.anchor-link-list.anchor-link-row-nowrap {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        
        align-content: center;
        margin: margin(3);
        li {
            margin-top: margin(1);
            margin-bottom: margin(1);
            margin-left: margin(1);
            a.anchor-link {
                @include paragraphFaqAndAccordionText();
                display: flex;
                text-align: left;
                letter-spacing: rem(0.3);
                align-items: center;
                // color: $color-link;
                text-decoration: underline; 
                &:hover,
                &:focus {
                    // color: rgba($color-link,0.8);
                }
                &::after{
                    content:'';
                    justify-content: center;
                    background-color:  $color-button-blue;
                    transform:rotate(90deg); 
                    @include textIconStyle(28px, 8px);
                    @include iconDefaultStyle($icon-arrow-white);
                }
            }
        }
        &.anchor-link-row-center {
            align-items:center;
        }
        &.anchor-link-row-left {
            align-items:left;
        }
      }
  }