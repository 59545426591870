@mixin cardbanner($top, $right, $left) {
    .cmp-teaser {
        position: relative;

        @include sm {
            display: flex;
            justify-content: center;
            flex-direction: column-reverse;
            height: auto;
            align-items: center;
        }
    }

    .cmp-teaser__image {
        position: relative;
        top: rem(0);
        left: rem(0);

        @include sm {
            width: 100%;
        }

        & .cmp-image {
        }
        & img {
            @include sm {
            }
        }
    }

    $row-title: rem(48);
    $row-pretitle: rem(48);
    $row-description:  rem(104);
    $row-action: rem(52);
    $content-padding-top: rem(52);
    $content-padding-bottom: rem(40);
    $content-padding-left: rem(40);
    $content-padding-right: rem(44);

    $row-title-sm: rem(56);
    $row-pretitle-sm: rem(70);
    $row-description-sm:  rem(116);
    $row-action-sm: rem(52);
    $content-padding-top-sm: rem(40);
    $content-padding-bottom-sm: rem(60);
    $content-padding-left-sm: rem(26);
    $content-padding-right-sm: rem(26);

    .cmp-teaser__content {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: $row-title $row-pretitle $row-description $row-action;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        padding: $content-padding-top  $content-padding-right $content-padding-bottom $content-padding-left;
        position: absolute;
        z-index: 1;
        top: $top;
        right: $right;
        left: $left;
        width: rem(592);
        height: rem(369);
        background-color: $color-cardbanner-content-background-white;

        @include sm {
            grid-template-rows: $row-title-sm $row-pretitle-sm $row-description-sm $row-action-sm;
            padding: $content-padding-top-sm  $content-padding-right-sm $content-padding-bottom-sm $content-padding-left-sm;
            position: relative;
            top: initial;
            left: initial;
            right: initial;
            z-index: inherit;
            height: initial;
            width: 100%;
            justify-content: start;
        }    
    }

    .cmp-teaser__pretitle {
        grid-area: 2 / 1 / 3 / 2;
        position: absolute;
        font: $font-cardbanner-pretitle;
        color: $color-cardbanner-pretitle;
        text-align: left;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;

        @include sm {
            -webkit-line-clamp: 2;
            font: $font-cardbanner-pretitle-sm;
        }
    }

    .cmp-teaser__title {
        grid-area: 1 / 1 / 2 / 2;
        position: absolute;
        font: $font-cardbanner-title;
        color: $color-cardbanner-title;
        text-align: left;


        @include sm {
            font: $font-cardbanner-title-sm;
        }
    }
  
    .cmp-teaser__title-link {
        pointer-events: none;
        color:$color-cardbanner-title;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
    }

    .cmp-teaser__description {
        grid-area: 3 / 1 / 4 / 2;
        position: absolute;
        font: $font-cardbanner-description;
        color: $color-cardbanner-description;
        text-align: left;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;

        @include sm {
            -webkit-line-clamp: 4;
            font: $font-cardbanner-description-sm;
        }
    }  

    .cmp-teaser__action-container {
        grid-area: 4 / 1 / 5 / 2;
        position: absolute;
    
        @include sm {
            position: initial;
            top: initial;
            width: initial;
            height: initial;
            margin: initial;
        }
    }

    .cmp-teaser__action-link {
        display:flex;
        flex:1;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        position: relative;
        width: rem(312);
        height: rem(52);
        background: transparent linear-gradient(270deg, $color-button-blue 0%, $color-button-blue-linear 100%) 0% 0% no-repeat padding-box;
        border-radius: rem(100);
        box-shadow: rem(3) rem(4) rem(12) $color-button-box-shadow;
        font: $font-cardbanner-button-title;
        color: $color-cardbanner-button-white;
        align-items: center;
        @include sm {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &:hover,
        &:focus {
            padding-left: rem(115);
            padding-right: rem(115);    
            background: $color-button-white 0% 0% no-repeat padding-box;
            border-radius: rem(100);
            box-shadow: rem(3) rem(4) rem(12) $color-button-box-shadow;
            border: rem(1) solid $color-button-blue;
            color: $color-button-blue;
        }
        @include sm {
            &:hover,
            &:focus {  
                background: $color-button-white 0% 0% no-repeat padding-box;
                border-radius: rem(100);
                box-shadow: rem(3) rem(4) rem(12) $color-button-box-shadow;
                border: rem(1) solid $color-button-blue;
                color: $color-button-blue;
            }
        }

        .cmp-teaser__action-link-before {
            content: "";
            position: absolute;
            width: rem(36);
            height: rem(36);
            text-align: center;
            background: $color-cardbanner-content-background-white 0% 0% no-repeat padding-box;
            border-radius: rem(100);
            margin-left: 84%;
        }

        &:hover .cmp-teaser__action-link-before,
        &:focus .cmp-teaser__action-link-before {
            content: "";
            width: rem(36);
            height: rem(36);
            background: $color-cardbanner-content-background-blue 0% 0% no-repeat padding-box;
            border-radius: rem(100);
            margin-left: 84%;
        }

        .cmp-teaser__action-link-after {
            content: url(./resources/images/element/icon/arrow-r-blue.svg);
            // content: url($icon-arrow-blue);
            position: absolute;
            top: initial;
            width: rem(36);
            text-align: center;
            margin-left: 85%;
        }

        &:hover .cmp-teaser__action-link-after,
        &:focus .cmp-teaser__action-link-after {
            content: url(./resources/images/element/icon/arrow-r-wh.svg);
            // content: url($icon-arrow-white);
            width: rem(36);
            margin-left: 85%;
        }
    }

    .cmp-teaser__tag-container {
        display: none;
    }

}

.ss-cardbanner--style-card-on-left {
    $top: 15%;
    $right: initial;
    $left: 10%;
    @include cardbanner($top, $right, $left);
}

.ss-cardbanner--style-card-on-right {
    $top: 15%;
    $right: 10%;
    $left: initial;
    @include cardbanner($top, $right, $left);
}