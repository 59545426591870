.cmp-carousel {
}
.cmp-carousel__content {
}
.cmp-carousel__item {
}
.cmp-carousel__actions {
  position: absolute;
  bottom: rem(71);
  right: rem(20);
  margin: 0;

  @include sm {
    bottom: rem(66);
    right: rem(16);
  }
}
.cmp-carousel__action {
  cursor: pointer;
  background: {
    color: $color-topcon;
  }
  border: rem(1) solid $color-topcon;
  position: relative;
  width: rem(48);
  height: rem(48);
  border-radius: 50%;
  box-sizing: border-box;

  @include sm {
    width: rem(32);
    height: rem(32);
  }

  &:hover {
    background: {
      color: $color-background-lighter;
    }
  }

  & .cmp-carousel__action-icon {
    &:before {
    }
  }
  &:focus {
  }
}
.cmp-carousel__action--disabled {
}
.cmp-carousel__action--previous {
  & .cmp-carousel__action-icon {
    &:before {
      background: {
        image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2213.502%22%20height%3D%2211.379%22%20viewBox%3D%220%200%2013.502%2011.379%22%3E%0A%20%20%3Cpath%20id%3D%22%E5%90%88%E4%BD%93_30%22%20data-name%3D%22%E5%90%88%E4%BD%93%2030%22%20d%3D%22M7.028.336a1.18%2C1.18%2C0%2C0%2C0-.01%2C1.672l2.494%2C2.5H1.177a1.182%2C1.182%2C0%2C0%2C0%2C0%2C2.363h8.33l-2.494%2C2.5a1.2%2C1.2%2C0%2C0%2C0%2C.005%2C1.668%2C1.186%2C1.186%2C0%2C0%2C0%2C1.668-.006l4.492-4.527a1.215%2C1.215%2C0%2C0%2C0%2C.23-.352A1.124%2C1.124%2C0%2C0%2C0%2C13.5%2C5.7a1.175%2C1.175%2C0%2C0%2C0-.34-.826L8.686.361A1.15%2C1.15%2C0%2C0%2C0%2C7.849%2C0%2C1.172%2C1.172%2C0%2C0%2C0%2C7.028.336Z%22%20transform%3D%22translate(13.502%2011.379)%20rotate(180)%22%20fill%3D%22%23fff%22%2F%3E%0A%3C%2Fsvg%3E%0A');
      }
    }
    
    &:hover {
      &:before {
        background: {
          image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2213.502%22%20height%3D%2211.379%22%20viewBox%3D%220%200%2013.502%2011.379%22%3E%0A%20%20%3Cpath%20id%3D%22%E5%90%88%E4%BD%93_30%22%20data-name%3D%22%E5%90%88%E4%BD%93%2030%22%20d%3D%22M7.028.336a1.18%2C1.18%2C0%2C0%2C0-.01%2C1.672l2.494%2C2.5H1.177a1.182%2C1.182%2C0%2C0%2C0%2C0%2C2.363h8.33l-2.494%2C2.5a1.2%2C1.2%2C0%2C0%2C0%2C.005%2C1.668%2C1.186%2C1.186%2C0%2C0%2C0%2C1.668-.006l4.492-4.527a1.215%2C1.215%2C0%2C0%2C0%2C.23-.352A1.124%2C1.124%2C0%2C0%2C0%2C13.5%2C5.7a1.175%2C1.175%2C0%2C0%2C0-.34-.826L8.686.361A1.15%2C1.15%2C0%2C0%2C0%2C7.849%2C0%2C1.172%2C1.172%2C0%2C0%2C0%2C7.028.336Z%22%20transform%3D%22translate(13.502%2011.379)%20rotate(180)%22%20fill%3D%22%231466c6%22%2F%3E%0A%3C%2Fsvg%3E%0A');
        }
      }
    }
  }
}
.cmp-carousel__action--next {
  margin-left: rem(4);
  & .cmp-carousel__action-icon {
    &:before {
      background: {
        image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2213.502%22%20height%3D%2211.379%22%20viewBox%3D%220%200%2013.502%2011.379%22%3E%20%3Cpath%20id%3D%22%E5%90%88%E4%BD%93_30%22%20data-name%3D%22%E5%90%88%E4%BD%93%2030%22%20d%3D%22M7.028%2C11.043a1.18%2C1.18%2C0%2C0%2C1-.01-1.672l2.494-2.5H1.177a1.182%2C1.182%2C0%2C0%2C1%2C0-2.363h8.33l-2.494-2.5A1.2%2C1.2%2C0%2C0%2C1%2C7.018.34%2C1.186%2C1.186%2C0%2C0%2C1%2C8.686.346l4.492%2C4.527a1.215%2C1.215%2C0%2C0%2C1%2C.23.352%2C1.124%2C1.124%2C0%2C0%2C1%2C.094.459%2C1.175%2C1.175%2C0%2C0%2C1-.34.826L8.686%2C11.018a1.15%2C1.15%2C0%2C0%2C1-.837.361A1.172%2C1.172%2C0%2C0%2C1%2C7.028%2C11.043Z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E');
      }
    }

    &:hover {
      &:before {
        background: {
          image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2213.502%22%20height%3D%2211.379%22%20viewBox%3D%220%200%2013.502%2011.379%22%3E%20%3Cpath%20d%3D%22M7.028%2C11.043a1.18%2C1.18%2C0%2C0%2C1-.01-1.672l2.494-2.5H1.177a1.182%2C1.182%2C0%2C0%2C1%2C0-2.363h8.33l-2.494-2.5A1.2%2C1.2%2C0%2C0%2C1%2C7.018.34%2C1.186%2C1.186%2C0%2C0%2C1%2C8.686.346l4.492%2C4.527a1.215%2C1.215%2C0%2C0%2C1%2C.23.352%2C1.124%2C1.124%2C0%2C0%2C1%2C.094.459%2C1.175%2C1.175%2C0%2C0%2C1-.34.826L8.686%2C11.018a1.15%2C1.15%2C0%2C0%2C1-.837.361A1.172%2C1.172%2C0%2C0%2C1%2C7.028%2C11.043Z%22%20fill%3D%22%231466c6%22%2F%3E%3C%2Fsvg%3E');
        }
      }
    }
  }
}
.cmp-carousel__action--pause {
}
.cmp-carousel__action--play {
}
.cmp-carousel__action-icon {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;

  &:before {
    content: '';
    background: {
      position: center center;
      repeat: no-repeat;
      size: contain;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    width: 13.502px;
    height: 11.379px;
    transform: translate(-50%, -50%);

    @include sm {
      width: rem(9.001);
      width: rem(7.586);
    }
  }
}
.cmp-carousel__action-text {
  display: none;
}
.cmp-carousel__indicators {
  margin-top: rem(24);

  @include sm {
    margin-top: rem(16);
  }
}
.cmp-carousel__indicator {
  cursor: pointer;
  background: $color-shade-2;
  position: relative;
  width: rem(20);
  height: rem(20);
  margin: 0 rem(6);

  @include sm {
    width: rem(16);
    height: rem(16);
  }

  &:before {
    content: '';
    background: {
      color: $color-topcon;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    width: rem(12);
    height: rem(12);
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -50%);

    @include sm {
      width: rem(8px);
      height: rem(8px);
    }
  }

  &:hover{

    &:before {
      opacity: 1;
      visibility: visible;
    }
  }
}
.cmp-carousel__indicator--active {

  &:before {
    opacity: 1;
    visibility: visible;
  }
}
