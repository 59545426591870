@use "../../global"as *;

/*===============================================
Social Share Item
===============================================*/

.bl_ui_module {

    &.el_socialShare_itemWrapper {
        
        .ly_inner {
            gap: $spacing-3;

            @include mq(md){
                display: block;
            }
        }
    }

    .el_socialShare_item {
        @include clamp_fs(14px, $sm-design-width, $lg-design-width, 18px);
        width: 33.3333%;

        @include mq(md){
            width: 100%;
            margin-bottom: $spacing-1;
        }

        &:last-child {

            @include mq(md){
                margin-bottom: 0;
            }
        }

        a {
            padding: $spacing-3 $spacing-1;

            @include mq(md){
                padding: $spacing-2 $spacing-1;
            }
        }

        &_icon {
            width: $spacing-5;
            margin: 0 auto;

            @include mq(md){
                width: $spacing-4half;
            }
        }

        &_ttl {
            letter-spacing: .05em;
            margin-top: $spacing-mini;
            display: block;
        }
    }
}