.cmp-tabs {
}

.cmp-tabs__tablist {
}
.cmp-tabs__tab {
  color: $color-shade-3;
  border-bottom: rem(2) solid $color-shade-2;
  font: {
    weight: 600;
  }
  padding: rem(12) rem(24);
  margin-right: rem(8);

  &:hover, &:focus {
    color: $color-foreground;
    border-bottom: rem(2) solid $color-foreground;
  }

}
.cmp-tabs__tab--active {
  color: $color-topcon;
  border-bottom: rem(2) solid $color-topcon;
  &:hover, &:focus {
    color: $color-topcon;
    border-bottom: rem(2) solid $color-topcon;
  }
}

.cmp-tabs__tabpanel {
}
.cmp-tabs__tabpanel--active {
}
