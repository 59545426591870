@use "sass:math";

@mixin ListTeaserTagStyle() {
  margin-right: rem(4);
  margin-bottom: rem(4);
  padding-left: rem(12);
  padding-right: rem(12);
  padding-top: rem(5);
  padding-bottom: rem(5);
  font: $font-list-teaser-common-tag;
  border-radius: rem(100);
  text-align: center;
  @include limitOneLine();
  @include sm {
    font: $font-list-teaser-common-tag;
  }
}

@mixin ListTeaserTagsBrandShowStyle($brand: false) {
  .cmp-common__tag {
    &-tpa__brand {
      &--topcon,
      &--sokkia,
      &--clearedge3d,
      &--xyeez {
        @if ($brand == true) {
          font: $font-list-teaser-brand-tag;
          pointer-events: none;

          @include sm {
            font: $font-list-teaser-brand-tag;
          }
        } @else {
          display: none;
        }
      }
    }
  }
}
@mixin ListTeaserTagsProductCategoryShowStyle($productcategory: false) {
  .cmp-common__tag {
    &-tpa__product-category {
      &--total-stations,
      &--gnss,
      &--machine-control,
      &--field-controllers,
      &--software,
      &--lasers,
      &--3d-scanners,
      &--levels-theodolites,
      &--agriculture-equipments,
      &--raku,
      &--monitoring,
      &--survey-equipments,
      &--related-products,
      &--infrastructure-maintenance {
        @if ($productcategory == true) {
          @include ListTeaserTagStyle();
        } @else {
          display: none;
        }
      }
    }
  }
}
@mixin ListTeaserTagsIndustoryShowStyle($industry: false) {
  .cmp-common__tag {
    &-tpa__industry {
      &--survey,
      &--construction,
      &--bim,
      &--agriculture {
        @if ($industry == true) {
          @include ListTeaserTagStyle();
        } @else {
          display: none;
        }
      }
    }
  }
}

@mixin ListTeaserTagsColumnShowStyle($column: false) {
  .cmp-common__tag {
    &-tpa__news {
      &--column {
        @if ($column == false) {
          font: $font-list-teaser-brand-tag;
          pointer-events: none;
          @include ListTeaserTagStyle();


          @include sm {
            font: $font-list-teaser-brand-tag;
          }
        } @else {
          display: none;
        }
      }
    }

    &-tpa__column {
      &-theme {
        &--work,
        &--time,
        &--dx,
        &--anketos {
          @if ($column == true) {
            font: $font-list-teaser-brand-tag;
            pointer-events: none;

            @include sm {
              font: $font-list-teaser-brand-tag;
            }
          } @else {
            display: none;
          }
        }
      }
      &-category {
        &--health,
        &--qualifications,
        &--sustainability,
        &--staff,
        &--disaster,
        &--goods,
        &--event-report {
          @if ($column == true) {
            display: none;
          } @else {
            @include ListTeaserTagStyle();
          }
        }
      }
    }


  }
}
@mixin ListTeaserTagsNewsShowStyle($news: false) {
  .cmp-common__tag {
    &-tpa__news {
      &--product-info,
      &--campaign,
      &--information,
      &--atwork,
      &--news-release
      {
        @if ($news == true) {
          @include ListTeaserTagStyle();
        } @else {
          display: none;
        }
      }
    }
  }
}
@mixin ListTeaserTagsEventShowStyle($event: false) {
  .cmp-common__tag {
    &-tpa__event {
      &--online,
      &--physical,
      &--free,
      &--paid {
        @if ($event == true) {
          @include ListTeaserTagStyle();
        } @else {
          display: none;
        }
      }
    }
  }
}

@mixin ListTeaserTagsShowStyle(
  $brand: false,
  $productcategory: false,
  $industry: false,
  $news: false,
  $event: false,
  $column: false
) {
  @include ListTeaserTagsBrandShowStyle($brand);
  @include ListTeaserTagsProductCategoryShowStyle($productcategory);
  @include ListTeaserTagsIndustoryShowStyle($industry);
  @include ListTeaserTagsNewsShowStyle($news);
  @include ListTeaserTagsEventShowStyle($event);
  @include ListTeaserTagsColumnShowStyle($column);
}

@mixin ListTeaserIconStyle($iconSize, $iconMargin) {
  $icon_content: $iconSize;
  width: $icon_content;
  height: $icon_content;
  @if $iconMargin != null {
    margin-right: rem($iconMargin);
  }
  // border-radius: math.div($icon_content, 2);
}

@mixin ListTeaserButtonStyle(
  $type,
  $width,
  $height,
  $font,
  $iconSize,
  $iconMargin
) {
  .cmp-teaser__detail {
    $icon_content: $iconSize;
    $icon_margin: $iconMargin;
    @if ($width != null) {
      width: rem($width);
    }
    height: rem($height);
    .cmp-teaser__detail-text {
      font: $font;
      justify-content: center;
      margin-left: ($icon_margin * 2);
    }
    .cmp-teaser__detail-icon {
      @include ListTeaserIconStyle($icon_content, $iconMargin);
    }
    @if $type == round {
      border-radius: rem(100);
    }
  }
}

@mixin ListTeaserButtonColor(
  $colorType: styleBlue,
  $shape: round,
  $shadowType: button
) {
  .cmp-teaser__detail {
    @if $colorType == styleBlue {
      background: transparent
        linear-gradient(
          270deg,
          $color-button-blue 0%,
          $color-button-blue-linear 100%
        )
        0%
        0%
        no-repeat
        padding-box;
      // background: $color-button-blue 0% 0% no-repeat padding-box;
    } @else {
      background: $color-button-white 0% 0% no-repeat padding-box;
    }
    @if $shadowType == button {
      // box-shadow: rem(3) rem(4) rem(12) $color-button-box-shadow;
    } @else {
      box-shadow: initial;
      border: initial;
    }
    &:hover,
    &:focus {
      background: $color-button-white 0% 0% no-repeat padding-box;
      .cmp-teaser__detail-text {
        color: $color-button-blue;
      }
      .cmp-teaser__detail-icon {
        background-color: $color-button-white;
        @include iconDefaultStyle($icon-arrow-l-blue);
      }
      // @include hoverIconStyle();
      @if $shadowType == button {
        border: rem(1) solid $color-button-blue;
        box-shadow: rem(3) rem(4) rem(12) $color-button-box-shadow;
      }
    }

    .cmp-teaser__detail-text {
      @if $colorType == styleBlue {
        color: $color-button-white;
      } @else {
        color: $color-button-blue;
      }
    }
    .cmp-teaser__detail-icon {
      // @if $colorType == styleBlue {
      //     background-color: $color-button-white;
      // } @else {
      //     background-color:  $color-button-blue;
      // }
      @include iconDefaultStyle($icon-arrow-l-white);
      @if $shadowType == icon {
        box-shadow: rem(3) rem(4) rem(12) $color-button-blue;
      } @else {
        box-shadow: initial;
      }
    }
    // @include iconStyle();
  }
}
