@import "_list_mixins.scss";

.cmp-list {
  .cmp-teaser__image {
    height: auto !important;
  }
}

.cmp-list__pagination {
  .paginationjs-pages {
    ul {
      margin-top: margin(2);
    }
  }
}

.ss-list--style-brandtag-undisplayed {
  .cmp-list__item {
    .cmp-teaser {
      .cmp-teaser__content {
        .cmp-teaser__categorytag-container {
          display: none;
        }
      }
    }
  }
}

.ss-list--style-otherthanbrand-undisplayed {
  .cmp-list__item {
    .cmp-teaser {
      .cmp-teaser__content {
        .cmp-teaser__tag-container {
          display: none;
        }
      }
    }
  }
}

.ss-list--style-productcategory-undisplayed {
  .cmp-list__item {
    .cmp-teaser {
      .cmp-teaser__content {
        .cmp-teaser__tag-container {
          @include ListTeaserTagsProductCategoryShowStyle(false);
        }
      }
    }
  }
}

.ss-list--style-industory-undisplayed {
  .cmp-list__item {
    .cmp-teaser {
      .cmp-teaser__content {
        .cmp-teaser__tag-container {
          @include ListTeaserTagsIndustoryShowStyle(false);
        }
      }
    }
  }
}

.ss-list--style-news-undisplayed {
  .cmp-list__item {
    .cmp-teaser {
      .cmp-teaser__content {
        .cmp-teaser__tag-container {
          @include ListTeaserTagsNewsShowStyle(false);
        }
      }
    }
  }
}

.ss-list--style-event-undisplayed {
  .cmp-list__item {
    .cmp-teaser {
      .cmp-teaser__content {
        .cmp-teaser__tag-container {
          @include ListTeaserTagsEventShowStyle(false);
        }
      }
    }
  }
}
