@mixin teaserHero {
    .cmp-teaser {
        position: relative;
        font: $font-teaser;
        display:flex;
        flex-direction: column;
        align-items: center;
        width:auto;
        height:auto;
    }

    .cmp-teaser__image {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .cmp-teaser__content {
        display:inline-flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: rem(24);
        width: 100%;
        @include sm {
            align-items: flex-start;
        }
        .cmp-teaser__title {
            margin-bottom: rem(18);
            font: $font-teaser-hero-title;

            @include sm {
                top: rem(0);
                height: rem(38);
                margin-top: rem(0);
                margin-bottom: rem(14);
                margin-left: rem(0);
                font: $font-teaser-sm-hero-title;
            }

            .cmp-teaser__title-link {
                pointer-events: none;
                color:$color-teaser-title;
            }
        }

        .cmp-teaser__tag-container {
            display: inline-block;

            @include sm {
                width: 100%;
                // width: rem(324);
                // text-align: center;
            }

            .cmp-common__tag-tpa__brand{
                &--topcon,
                &--sokkia,
                &--clearedge3d,
                &--xyeez {
                    height: rem(21);
                    width: 100%;
                    text-align: center;
                    margin-bottom: margin(1);
                    font: $font-teaser-hero-brand-tag;

                    @include sm {
                        // top: rem(-24);
                        display: inline-flex;
                        width:unset;
                        height: rem(18);
                        margin-right: rem(8);
                        margin-left: rem(0);
                        font: $font-teaser-sm-hero-brand-tag;
                    }
                }
            }

            .cmp-common__tag-tpa__industry {
                &--survey,
                &--construction,
                &--bim,
                &--agriculture {
                    height: rem(23);
                    margin-right: rem(4);
                    padding-left: rem(12);
                    padding-right: rem(12);
                    font: $font-teaser-hero-industry-tag;
                    border-radius: rem(100);
                    text-align: center;
                    display: inline-flex;
                    align-items: center;
                    margin-bottom: margin(1);

                    @include sm {
                        height: rem(23);
                        font: $font-teaser-hero-industry-tag;
                    }
                }
            }

            .cmp-common__tag-tpa__product-category{
                &--total-stations,
                &--gnss,
                &--machine-control,
                &--field-controllers,
                &--software,
                &--lasers,
                &--3d-scanners,
                &--levels-theodolites,
                &--agriculture-equipments,
                &--raku,
                &--monitoring,
                &--survey-equipments,
                &--related-products,
                &--infrastructure-maintenance {
                    height: rem(23);
                    margin-right: rem(4);
                    padding-left: rem(12);
                    padding-right: rem(12);
                    font: $font-teaser-hero-category-tag;
                    border-radius: rem(100);
                    text-align: center;
                    display: inline-flex;
                    align-items: center;
                    margin-bottom: margin(1);

                    @include sm {
                        height: rem(23);
                        font: $font-teaser-hero-category-tag;
                    }
                }
            }
        }

    }
}
