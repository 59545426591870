@use "sass:math";
@function rem($pixels, $context: $font-size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return math.div($pixels, $context) * 1rem;
}

@function margin($m) {
  @return rem(8px * $m);
}

@mixin indexDate {
  color: $color-index-topic-date;
  text-align: left;
  font: $font-index-date;
  letter-spacing: rem(0.28);
}

@mixin limitOneLine() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin limitLine($line) {
  @if ($line == 1) {
    @include limitOneLine();
  } @else {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line;
    text-overflow: ellipsis;
  }
}