.cmp-faq {

    .is-active + .cmp-contentfragment__element--answer {
        height: auto;
    }

    .cmp-contentfragment {
        margin-bottom: rem(16);

        &__element {

            &-title {
                font: {
                    size: 0;
                }
                position: relative;
                display: inline-block;
                vertical-align: top;
                // display: none;

                &:before {
                    color: $color-background-lighter;
                    font: {
                        size: rem(20);
                        weight: 600;
                    }
                    width: rem(40);
                    height: rem(40);
                    line-height: rem(40);
                    border-radius: 50%;
                    display: inline-block;
                    text-align: center;

                    @include sm {
                        font: {
                            size: rem(16);
                        }
                        width: rem(36);
                        height: rem(36);
                        line-height: rem(36);
                    }
                }
            }

            &-value {
                width: calc(100% - rem(68));
                padding-left: rem(20);
                margin-top: rem(8);
                display: inline-block;
                vertical-align: top;
                flex-shrink: 0;

                @include sm {
                    width: calc(100% - rem(62));
                    padding-left: rem(12);
                }

                p {
                    font: {
                        size: rem(16);
                    }

                    @include sm {
                        font: {
                            size: rem(14);
                        }
                    }
                }
            }

            &--question {
                background: {
                    color: $color-background-lighter;
                }
                border: rem(1) solid $color-border;
                position: relative;
                padding: rem(16) rem(72) rem(16) rem(20);
                display: block;

                @include sm {
                    padding-right: rem(48);
                }

                &:hover, &:focus {
                    color: $color-topcon;
                    border: rem(1) solid $color-topcon;

                    .cmp-contentfragment__element-value {

                        p {
                            text-decoration: underline;
                        }
                    }
                }

                &:before, &:after {
                    content: '';
                    position: absolute;
                }

                &:before {
                    content: '';
                    border: rem(1) solid $color-topcon;
                    top: rem(36);
                    right: rem(20);
                    width: rem(28);
                    height: rem(28);
                    border-radius: 50%;
                    display: inline-block;
                    transform: translate(0, -50%);
                }

                &:after {
                    background: {
                        image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212.48%22%20height%3D%227%22%20viewBox%3D%220%200%2012.48%207%22%3E%0A%20%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_3101%22%20data-name%3D%22%E3%83%91%E3%82%B9%203101%22%20d%3D%22M-1080.119-20239.021a1.008%2C1.008%2C0%2C0%2C1-.77-.375%2C1.267%2C1.267%2C0%2C0%2C1%2C.055-1.654l4.071-4.211-4.071-4.215a1.257%2C1.257%2C0%2C0%2C1-.055-1.646.97.97%2C0%2C0%2C1%2C1.483-.066l4.9%2C5.068a1.239%2C1.239%2C0%2C0%2C1%2C.335.859%2C1.245%2C1.245%2C0%2C0%2C1-.335.857l-4.9%2C5.07A1%2C1%2C0%2C0%2C1-1080.119-20239.021Z%22%20transform%3D%22translate(-20239.021%201081.169)%20rotate(90)%22%20fill%3D%22%231466c6%22%2F%3E%0A%3C%2Fsvg%3E%0A');
                        position: center;
                        repeat: no-repeat;
                    }
                    top: rem(34);
                    right: rem(27.5);
                    width: rem(12.48);;
                    height: rem(7);
                }

                &.is-active {

                    &:after {
                        top: rem(32);
                        transform: rotate(180deg);
                    }
                }

                .cmp-contentfragment__element-title {

                    &:before {
                        content: 'Q';
                        background: {
                            color: $color-topcon;
                        }
                    }
                }

                .cmp-contentfragment__element-value {

                    p {
                        font: {
                            weight: 700;
                        }
                    }
                }
            }

            &--answer {
                // padding: rem(16) rem(72) rem(16) rem(20);
                display: block;

                .cmp-contentfragment__element-title {
                    padding-left: rem(20);
                    padding-top: rem(24);

                    // @include sm {
                    //     padding-left: rem(12);
                    // }

                    &:before {
                        content: 'A';
                        background: {
                            color: #DE3086;
                        }
                    }
                }

                .cmp-contentfragment__element-value {
                    padding-top: rem(24);
                    padding-bottom: rem(24);
                    padding-right: rem(24);

                    @include sm {
                        padding-top: rem(16);
                        padding-bottom: rem(16);
                        padding-right: rem(16);
                    }
                }
            }

            &--order {
                display: none;
            }
        }
    }
}