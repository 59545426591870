// Event Category
.cmp-eventindex__categories {
  @include not_sm {
    display: flex;
    flex-wrap:wrap;
    
  }
  @include sm {
    flex-wrap:nowrap;
    // 横スクロール用
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
  gap: rem(8) rem(8);

  .cmp-eventindex__category-item {
    @include sm {
      // 横スクロール用
      display: inline-block;
    }

    font: $font-index-category;
    padding: rem(12) rem(24);
    letter-spacing: 0px;
    text-align: left;
    border-radius: 100px;

    &[data-cmp-selected="true"] {
      color: $color-index-category-button-selected-text;
      background: $color-selected 0% 0% no-repeat padding-box;
    }

    &[data-cmp-selected="false"] {
      color: $color-index-category-button-text;
      background: $color-index-category-button 0% 0% no-repeat padding-box;
      border: rem(1) solid $color-index-category-button-border;

      &:hover,
      &:focus {
        color: $color-index-category-button-hover;
        text-decoration: underline;
        text-decoration-color: $color-index-category-button-hover;
        border-color: $color-index-category-button-hover;
      }
    }
  }
}

// Topics Category Separator
.cmp-eventindex__categories-separator {
  height: rem(25);
}

// Topics Years
.cmp-eventindex__years {
  margin-bottom: margin(8);
  @include sm {
    // 横スクロール用
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  .cmp-eventindex__year-item {
    @include sm {
      // 横スクロール用
      display: inline-block;
    }

    width: rem(80);
    height: rem(30);
    text-align: center;
    vertical-align: top;
    font: $font-index-year;
    letter-spacing: rem(0);
    padding-bottom: rem(15);

    &[data-cmp-selected="true"] {
      color: $color-index-year-button-selected-text;
      background-color: inherit;
      border-bottom: rem(2) solid $color-index-year-button-selected-border;
    }

    &[data-cmp-selected="false"] {
      color: $color-index-year-button-text;
      border-bottom: rem(2) solid $color-index-year-button-border;

      &:hover,
      &:focus {
        color: $color-index-year-button-hover;
        border-bottom: rem(2) solid $color-index-year-button-hover;
      }
    }

    &:not(:first-of-type) {
      margin-left: rem(8);
    }
  }
}

// Topics
.cmp-eventindex {
  background-color: initial;
}
.ss-eventindex--style-normal {
  .cmp-eventindex {
    margin-bottom: margin(2);
    background-color: initial;

    .cmp-eventindex__elements{
      // Border-bottom
      position:relative;
      margin-bottom: margin(2);
      padding-bottom:rem(20);

      &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: rem(1) solid $color-index-topic-border;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: rem(12);
        border-bottom: rem(2) solid $color-topcon;
      }

      .cmp-eventindex__element{
        $parent: ".cmp-eventindex__element";
        &#{$parent}--startDate{
          @include indexDate;
          margin-bottom: rem(12);
        }
        &#{$parent}--endDate {
          margin-bottom: rem(12);
          #{$parent}-value {
            @include indexDate;
            &::before {
              content:"-";
              margin-right: rem(4);
              margin-left: rem(4);
            }
          }
        }
        &#{$parent}--tags {
          // margin-bottom: rem(12);
          ul > li {
            padding: rem(6) rem(12);
            border-radius: rem(100);
            text-align: left;
            border: rem(1) solid $color-news-tag-border;
            font: $font-eventindex-tags;
            letter-spacing: 0px;
          }
        }
        &#{$parent}--description {
          margin-bottom: rem(8);
          #{$parent}-value-link {
            text-align: left;
            text-decoration: underline;
            text-decoration-color: $color-index-topic-link;
            font: $font-eventindex-link;
            letter-spacing: rem(0.6);
            color: $color-index-topic-link;
            &:hover,
            &:focus {
              color: $color-index-topic-link-hover;
              text-decoration-color: $color-index-topic-link-hover;
            }
          }
          
          #{$parent}-value-external-link > p {
            &:after {
              @include iconExternalLinkStyle();
            }
          }

        }
        &#{$parent}--location {
          margin-bottom: rem(8);
          color: $color-index-topic-location;
          text-align: left;
          font: $font-eventindex-location;
          letter-spacing: 0.26px;

        }
      }
    }
  }
}