.ss-eventindex--style-fixed {
    .cmp-eventindex {
        $parent: ".cmp-eventindex__element";
        .cmp-eventindex__elements{
            display: grid;
            grid-template-columns: auto 1fr;
            grid-template-rows: 32px 64px 32px;
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            .cmp-eventindex__element{
                &#{$parent}--startDate{
                    grid-area: 1 / 1 / 2 / 2;
                }
                &#{$parent}--endDate {
                    grid-area: 1 / 2 / 2 / 3;
                }
                &#{$parent}--description {
                    grid-area: 2 / 1 / 3 / 3; 
                }
                &#{$parent}--tags {
                    grid-area: 3 / 1 / 4 / 3;
                }

            }
        }
        .cmp-eventindex__elements{
            position:relative;
            margin-bottom: margin(2);
            padding-bottom:rem(20);
            &:before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                border-bottom: rem(1) solid $color-index-topic-border;
            }
            &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: rem(12);
                border-bottom: rem(2) solid $color-topcon;
            }
            .cmp-eventindex__element{
                
                &#{$parent}--startDate{
                  @include indexDate;
                  text-align: left;
                  display:inline-flex;
                  align-items: center;
                }
                &#{$parent}--endDate {
                    text-align: left;
                    display:inline-flex;
                    align-items: center;
                  #{$parent}-value {
                    @include indexDate;
                    &::before {
                      content:"-";
                      margin-right: rem(4);
                      margin-left: rem(4);
                    }
                  }
                }
                &#{$parent}--tags {
                    display:inline-flex;
                    align-items: center;
                    ul > li {
                      padding: rem(5) rem(12);
                      border-radius: rem(100);
                      margin-right: rem(4);
                      text-align: left;
                      border: rem(1) solid $color-news-tag-border;
                      font: $font-eventindex-tags;
                      letter-spacing: 0px;
                    }
                }
                &#{$parent}--description {
                    #{$parent}-value-link {
                        p {
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                        }
                        text-align: left;
                        text-decoration: underline;
                        text-decoration-color: $color-index-topic-link;
                        font: $font-eventindex-link;
                        letter-spacing: rem(0.6);
                        color: $color-index-topic-link;
                        &:hover,
                        &:focus {
                            color: $color-index-topic-link-hover;
                            text-decoration-color: $color-index-topic-link-hover;
                        }
                    }
                    #{$parent}-value-external-link > p{
                        &:after {
                          @include iconExternalLinkStyle();
                        }
                    }
                }
                &#{$parent}--location {
                    display: none;
                }
            }
        }
    }
}