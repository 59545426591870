.cmp-accordion {
}
.cmp-accordion__item {
  margin-bottom: rem(16);
}
.cmp-accordion__header {
  cursor: pointer;
  background: {
    color: $color-background-lighter;
  }
  border: rem(1) solid $color-border;
  position: relative;
  padding: rem(26) rem(20);
  display: block;

  &:hover,
  &:focus {
    color: $color-topcon;
    border: rem(1) solid $color-topcon;

    .cmp-accordion__title {
      text-decoration: underline;
    }
  }

  .cmp-accordion__title {
    cursor: pointer;
    font: {
      size: rem(16);
      weight: 700;
    }
    line-height: 1.4;
    padding-right: rem(52);
    display: block;

    @include sm {
      font: {
        size: rem(14);
      }
    }
  }
}

.cmp-accordion__button {
  position: relative;

  .cmp-accordion__icon {
    border: rem(1) solid $color-topcon;
    position: absolute;
    top: 50%;
    right: 0;
    width: rem(28);
    height: rem(28);
    border-radius: 50%;
    display: inline-block;
    transform: translate(0, -50%);

    &:after {
      content: "";
      position: absolute;
      background: {
        image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212.48%22%20height%3D%227%22%20viewBox%3D%220%200%2012.48%207%22%3E%0A%20%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_3101%22%20data-name%3D%22%E3%83%91%E3%82%B9%203101%22%20d%3D%22M-1080.119-20239.021a1.008%2C1.008%2C0%2C0%2C1-.77-.375%2C1.267%2C1.267%2C0%2C0%2C1%2C.055-1.654l4.071-4.211-4.071-4.215a1.257%2C1.257%2C0%2C0%2C1-.055-1.646.97.97%2C0%2C0%2C1%2C1.483-.066l4.9%2C5.068a1.239%2C1.239%2C0%2C0%2C1%2C.335.859%2C1.245%2C1.245%2C0%2C0%2C1-.335.857l-4.9%2C5.07A1%2C1%2C0%2C0%2C1-1080.119-20239.021Z%22%20transform%3D%22translate(-20239.021%201081.169)%20rotate(90)%22%20fill%3D%22%231466c6%22%2F%3E%0A%3C%2Fsvg%3E%0A");
        position: center;
        repeat: no-repeat;
      }
      top: 54%;
      left: 50%;
      width: 12.48px;
      height: 7px;
      transform: translate(-50%, -50%);
    }
  }
  &:hover {
    .cmp-accordion__title {
    }
    .cmp-accordion__icon {
      &:after {
      }
    }
  }
  &:focus {
    .cmp-accordion__title {
    }
  }
}

.cmp-accordion__button--expanded {
  .cmp-accordion__icon {
    &:after {
      top: 52%;
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
  &:focus {
    .cmp-accordion__title {
    }
  }
}

.cmp-accordion__title {
}

.cmp-accordion__icon {
}

.cmp-accordion__panel {
  padding: rem(16) rem(20);
}

.cmp-accordion__panel--expanded {
}

.cmp-accordion__panel--hidden {
}


// .cmp-accordion__panel:has(> .container > .cmp-container > div > .dealersearchresult ) {
//   padding: 0;
// }
