// Teaser Background Color
.ss-teaser--style-background-color {
    &-unset {
        .cmp-teaser {
            background-color: unset;
        }
    }
    &-white {
        .cmp-teaser {
            background-color: $color-teaser-background-color-white;
        }
    }
}
